import React, { useState } from 'react';
import Popup from './Popup';
import { HorizontalEvenly, VerticalCenter } from './SimpleLayouts';
import { PopupTitle } from './WaffleText';
import { Spacer } from './SimpleComponents';
import { WaffleButtonAsync } from './WaffleButtonAsync';
import format from 'date-fns/format';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { DateRangePickerProps } from './DateRangePicker';

const DateRangePicker = ({
  title,
  picker,
  setPicker,
  selectedStartDate,
  selectedEndDate,
  onChange,
  minimumDate,
}: DateRangePickerProps) => {
  const [startDate, setStartDate] = useState<Date>(selectedStartDate);
  const [endDate, setEndDate] = useState<Date>(selectedEndDate);

  const onChangeDate = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const onConfirm = async () => {
    setPicker(false);
    const start = format(startDate, 'yyyy-LL-dd');
    const end = format(endDate, 'yyyy-LL-dd');
    onChange(`${start} - ${end}`, JSON.stringify({ start, end }));
  };

  return (
    <>
      <Popup
        visible={picker}
        onDismiss={() => setPicker(false)}
        viewStyle={{ maxWidth: '100%' }}>
        <VerticalCenter>
          <Spacer y={3} />

          <PopupTitle>{title}</PopupTitle>

          <Spacer y={3} />

          <DatePicker
            selected={startDate}
            onChange={onChangeDate}
            startDate={startDate}
            endDate={endDate}
            dateFormat={'yyyy-MM-dd'}
            dateFormatCalendar="MMMM" // Only show month in header
            minDate={minimumDate}
            selectsRange
            showYearDropdown
            scrollableYearDropdown
            inline
          />

          <Spacer y={2} />

          <HorizontalEvenly style={{ width: '100%' }}>
            <WaffleButtonAsync
              style={{ width: '45%' }}
              name={'Confirm'}
              disabled={!startDate || !endDate}
              onPress={onConfirm}
            />
            <WaffleButtonAsync
              style={{ width: '45%' }}
              name={'Cancel'}
              onPress={async () => setPicker(false)}
            />
          </HorizontalEvenly>
        </VerticalCenter>
      </Popup>
    </>
  );
};

export { DateRangePicker };
