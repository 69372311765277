import { DidYouKnowBox, Footer } from '../Shared/DidYouKnowItems';
import { Image } from '../../SimpleComponents';
import { isFullWeb } from '../../../Helper/DeviceHelper';
import WaffleText from '../../WaffleText';
import React from 'react';

export const PhishingMoney = () => {
  return (
    <DidYouKnowBox>
      <Image
        source={require('../../../../assets/images/products/cyber/17700_person.webp')}
        resizeMode={'contain'}
        style={{
          width: isFullWeb() ? 448 : 300,
          height: 350,
          position: 'absolute',
          bottom: isFullWeb() ? 0 : 20,
        }}
      />
      <Footer>
        <WaffleText
          style={{
            fontSize: 18,
            textAlign: 'center',
          }}>
          $17,700 is lost every minute due to a phishing attack.
        </WaffleText>
      </Footer>
    </DidYouKnowBox>
  );
};
