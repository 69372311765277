import React from 'react';
import WaffleHalfTextInput from '../WaffleHalfTextInput';
import { TextIconInput } from '../TextIconInput';
import * as yup from 'yup';
import { Image } from 'react-native';

export const ZipValidator = yup
  .string()
  .length(5)
  .matches(/[0-9]{5}/)
  .required();
const zipRegexp = /[^0-9]/g;

const ensureFormat = (text) => {
  if (text) {
    // squelch non-digits
    text = text.replace(zipRegexp, '');

    // truncate at 5 characters
    if (text.length > 5) {
      text = text.substring(0, 5);
    }
  }

  return text;
};

export const PostalInput = ({ otherProps, ...rest }: any) => (
  <WaffleHalfTextInput
    placeholder={'Zip Code'}
    otherProps={{
      ...{
        autoCompleteType: 'postal-code',
        keyboardType: 'number-pad',
        textContentType: 'postalCode',
      },
      ...otherProps,
    }}
    {...rest}
  />
);

export const PostalIconInput = ({ otherProps, onChangeText, ...rest }: any) => (
  <TextIconInput
    placeholder={'Zip Code'}
    leftIcon={
      <Image
        source={require('../../../assets/elements/zipcode.webp')}
        style={{ width: 23, height: 23 }}
        resizeMode={'contain'}
      />
    }
    onChangeText={(text) => onChangeText(ensureFormat(text))}
    otherProps={{
      ...{
        autoCompleteType: 'postal-code',
        keyboardType: 'number-pad',
        textContentType: 'postalCode',
      },
      ...otherProps,
    }}
    {...rest}
  />
);
