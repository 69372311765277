import React from 'react';
import { CheckBox } from 'react-native-elements';
import { WaffleOrange } from '../Constants/Style';

export const CheckSquare = ({
  disabled,
  value,
  onValueChanged,
  ...rest
}: any) => (
  <CheckBox
    {...rest}
    checkedColor={WaffleOrange}
    disabled={disabled}
    checked={value}
    onPress={onValueChanged}
  />
);
