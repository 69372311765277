import { Fade, Placeholder, PlaceholderLine } from 'rn-placeholder';
import React from 'react';

type LinePlaceholderProps = {
  width: number;
  height: number;
  textHeight: number;
};

export const LinePlaceholder = ({
  width,
  height,
  textHeight,
}: LinePlaceholderProps) => (
  <Placeholder
    Animation={Fade}
    style={{
      width,
      height,
    }}>
    <PlaceholderLine width={width / 2} height={textHeight} />
  </Placeholder>
);
