import React from 'react';
import { isFullWeb } from '../../../Helper/DeviceHelper';
import { Spacer } from '../../SimpleComponents';
import ResponsiveRow from '../../ResponsiveRow';
import { Title } from '../Shared/Title';
import BackToHomeButton from '../Shared/BackToHomeButton';
import DidYouKnowItems from '../Shared/DidYouKnowItems';
import { Disclosures } from './Disclosures';
import { Ranking } from './Ranking';
import { CrimeFrequency } from './CrimeFrequency';
import { PhishingMoney } from './PhishingMoney';
import { Ransomware } from './Ransomware';
import { Protection } from './Protection';
import { Policies, Products } from '../../../../component-config';
import { HasCyberPolicy, IsCyber } from '../../../Helper/PolicyHelper';
import { Props } from '../Shared/types';

const Cards: React.ReactElement[] = [
  <Ranking key={1} />,
  <CrimeFrequency key={2} />,
  <PhishingMoney key={3} />,
  <Ransomware key={4} />,
];

const PromoMobile = ({ onPress, onPromo }: Props) => {
  return (
    <>
      <Spacer y={5} />

      <Title style={{ width: 335 }}>Protect Your Digital Life!</Title>

      <Spacer y={7} />

      <DidYouKnowItems Cards={Cards} />

      <Spacer y={7.5} />

      <Protection onPress={onPress} onPromo={onPromo} />

      <Spacer y={3} />

      <BackToHomeButton onPress={onPress} />

      <Spacer y={3} />

      <Disclosures />

      <Spacer y={5} />
    </>
  );
};

const PromoWeb = ({ onPress, onPromo }: Props) => {
  return (
    <>
      <Title style={{ width: isFullWeb() ? 888 : 350 }}>
        Protect Your Digital Life!
      </Title>

      <Spacer y={10} />

      <ResponsiveRow
        gap={40}
        style={{ marginHorizontal: 20, flexWrap: 'wrap' }}>
        <DidYouKnowItems Cards={Cards} />

        <Protection onPress={onPress} onPromo={onPromo} />
      </ResponsiveRow>

      <Spacer y={5} />

      <BackToHomeButton onPress={onPress} />

      <Spacer y={5} />

      <Disclosures />

      <Spacer y={5} />
    </>
  );
};

function PromoCyber({ onPress, onPromo }: Props) {
  if (isFullWeb()) {
    return <PromoWeb onPress={onPress} onPromo={onPromo} />;
  } else {
    return <PromoMobile onPress={onPress} onPromo={onPromo} />;
  }
}

function isCyberPromoEligible(
  policies: Policies | undefined,
  products: Products
) {
  return (
    policies &&
    !HasCyberPolicy(policies) &&
    (products?.find(IsCyber)?.eligibility?.isEligible ?? false)
  );
}

export { PromoCyber, isCyberPromoEligible };
