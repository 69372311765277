import ProtectionBox, { Header } from '../Shared/ProtectionBox';
import WaffleText from '../../WaffleText';
import { Spacer } from '../../SimpleComponents';
import LearnMoreButton from '../Shared/LearnMoreButton';
import React from 'react';
import { Props } from '../Shared/types';

export const Protection = ({ onPromo }: Props) => {
  return (
    <ProtectionBox>
      <Header>
        <WaffleText
          style={{ fontSize: 30, fontWeight: '600', textAlign: 'center' }}>
          Personal Cyber Insurance
        </WaffleText>
      </Header>

      <Spacer y={12} />

      <WaffleText
        style={{
          fontSize: 16,
          fontWeight: '700',
        }}>
        For You &amp; Your Family for Just About:
      </WaffleText>

      <Spacer y={2} />

      <WaffleText
        style={{
          fontSize: 48,
          fontWeight: 'bold',
        }}>
        $5/Month
      </WaffleText>

      <Spacer y={5} />

      <WaffleText style={{ textAlign: 'center' }}>
        Identify Theft, Ransomware, Cyberbullying, and More!
      </WaffleText>

      <Spacer y={5} />

      <LearnMoreButton onPromo={onPromo} />
    </ProtectionBox>
  );
};
