import React, { useEffect, useState } from 'react';
import styled from 'styled-components/native';

import WaffleText from '../WaffleText';
import { PlainView } from '../SimpleComponents';
import { HorizontalPacked } from '../SimpleLayouts';
import { WaffleButtonAsync } from '../WaffleButtonAsync';
import { CheckSquare } from '../CheckSquare';

export const TotalMonthly = styled(WaffleText)`
  height: 26px;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
`;

export const Label = styled(WaffleText)`
  height: 20px;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  margin-top: 20px;
`;

export const TouchableRectangularButton = styled.TouchableOpacity`
  height: 56px;
  width: 316px;
  border-radius: 7px;
  border: 1px solid #c0c0c0;
  justify-content: center;
  align-items: center;
  margin-top: 7px;
  margin-bottom: 7px;
  overflow: hidden;
`;

export const PaperlessChooser = ({ paperless, setPaperless }: any) => (
  <PlainView
    style={{
      marginTop: 20,
      width: 300,
    }}>
    <HorizontalPacked style={{ alignItems: 'flex-start' }}>
      <CheckSquare
        value={paperless}
        onValueChanged={() => setPaperless(!paperless)}
      />
      <PlainView style={{ marginLeft: 10 }}>
        <WaffleText
          style={{
            fontSize: 18,
            fontWeight: '700',
          }}>
          Go Paperless with Pet
        </WaffleText>
        <WaffleText style={{ marginTop: 10, width: 200 }}>
          Get your policy online in the ASPCA® Member Center and communications
          by email rather than mail.
        </WaffleText>
      </PlainView>
    </HorizontalPacked>
  </PlainView>
);

export const ConfirmPaymentButton = ({ onNext, disabled }: any) => {
  const [pressedName, setPressedName] = useState('Processing payment...');

  return (
    <WaffleButtonAsync
      style={{ width: 269 }}
      onPress={onNext}
      disabled={disabled}
      name={'Confirm Payment'}
      pressedName={pressedName}
    />
  );
};

export const Center = styled.View`
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Vertical = styled.View`
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
`;
