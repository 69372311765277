import React, { useState } from 'react';
import WaffleTextInput from './WaffleTextInput';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { WaffleDarkGrey } from '../Constants/Style';
import { PlainView } from './SimpleComponents';
import { WaffleTextInputProps } from '../../component-config';

// adapted from react-native-hide-show-password

const PasswordInput = ({
  otherProps,
  containerStyle,
  ...others
}: WaffleTextInputProps) => {
  const [isPassword, setIsPassword] = useState(true);
  const [eyeIcon, setEyeIcon] = useState('visibility-off');

  const changePwdType = () => {
    setEyeIcon(isPassword ? 'visibility' : 'visibility-off');
    setIsPassword((prevState) => !prevState);
  };

  // const passReference = (ref) => {
  //   if (getRef) getRef(ref);
  // };

  return (
    <PlainView>
      <Icon
        style={{
          position: 'absolute',
          top: 29,
          right: 8,
          zIndex: 99,
        }}
        name={eyeIcon}
        size={25}
        color={WaffleDarkGrey}
        onPress={changePwdType}
      />
      <WaffleTextInput
        containerStyle={containerStyle}
        placeholder={'Password'}
        otherProps={{
          ...otherProps,
          ...{
            autoCapitalize: 'none',
            autoCorrect: false,
            secureTextEntry: isPassword,
          },
        }}
        {...others}
      />
    </PlainView>
  );
};

export default PasswordInput;
