import React from 'react';
import Markdown from 'react-native-markdown-display';
import merge from 'deepmerge';

import { ShowWebLink } from './WebViewPopupLink';
import nunjucks from 'nunjucks';
import { commify } from '../Helper/MiscFunctions';
import { WaffleTextFamily } from './WaffleText';
import { StyleProp } from 'react-native';
import { useNavigation } from '@react-navigation/native';

export const OnDefaultLinkPress = async (
  url: any,
  navigation: any
): Promise<boolean> => {
  if (url) {
    await ShowWebLink(navigation, url);
    return false;
  }

  // return true to open with `Linking.openURL
  // return false to handle it yourself
  return true;
};

// todo : support variable substitution!

export const markupTemplate = (template: string, context: any) => {
  const env = new nunjucks.Environment();
  env.addFilter('commify', commify);

  return env.renderString(template, context);
};

export const safeMarkupTemplate = (template: string, context: any) => {
  try {
    return markupTemplate(template, context);
  } catch (err) {
    // for now, eat errors
    return null;
  }
};

export interface MarkupProps {
  children: JSX.Element | JSX.Element[];
  style: StyleProp<any>;
  linkPress: (url: string, navigation: any) => boolean;
  beforeLinkPress: (url: string) => void;
}

export const Markup = ({
  children,
  style = {},
  linkPress = OnDefaultLinkPress,
}: any) => {
  const navigation = useNavigation();

  return (
    <Markdown
      onLinkPress={(url) => linkPress(url, navigation)}
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'object' is not assignable to type 'NamedStyl... Remove this comment to see the full error message
      style={merge.all([
        {
          body: {
            fontFamily: WaffleTextFamily,
            color: '#000000',
            fontSize: 16,
            lineHeight: 19,
          },
        },
        style,
      ])}>
      {children}
    </Markdown>
  );
};

export const TemplatedMarkup = ({
  template,
  context = {},
  style = {},
  linkPress = OnDefaultLinkPress,
}: any) => {
  const navigation = useNavigation();

  return (
    <Markup linkPress={(url) => linkPress(url, navigation)} style={style}>
      {safeMarkupTemplate(template, context) ?? ''}
    </Markup>
  );
};
