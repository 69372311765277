import styled from 'styled-components/native';
import WaffleText from '../../WaffleText';
import { DarkText } from '../../../Constants/Style';

export const Title = styled(WaffleText)`
  color: ${DarkText};
  font-size: 36px;
  font-weight: 800;
  line-height: 46px;
  letter-spacing: -0.5px;
  text-align: center;
`;
