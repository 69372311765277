import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import Icon from 'react-native-vector-icons/EvilIcons';
import styled from 'styled-components/native';

import {
  PlainView,
  ScrollView,
  Spacer,
  TouchableOpacity,
} from '../SimpleComponents';
import Popup from '../Popup';
import { OnDefaultLinkPress, TemplatedMarkup } from '../Markup';
import { VerticalCenter } from '../SimpleLayouts';
import { WaffleButtonAsync } from '../WaffleButtonAsync';
import WaffleText from '../WaffleText';
import { WaffleOrange } from '../../Constants/Style';
import { GET_LOCAL_USER } from '../../GraphQL/Waffle/Queries';
import { useNavigation } from '@react-navigation/native';

// todo : need to consolidate popup title styles!
export const InfoTitle = styled(WaffleText)`
  font-size: 24px;
  font-weight: 500;
  line-height: 39px;
  text-align: center;
`;

const ScrollMaybeView = ({ style, scrollBody, children }: any) => {
  const Comp = scrollBody ? ScrollView : PlainView;

  return <Comp style={style}>{children}</Comp>;
};

export const TextInfo = ({
  title,
  text,
  containerStyle,
  scrollBody = false,
}: any) => {
  const {
    data: { user },
  } = useQuery(GET_LOCAL_USER);
  const [popup, setPopup] = useState(false);
  const navigation = useNavigation();

  const linkPress = async (url) => {
    await setPopup(false);
    await OnDefaultLinkPress(url, navigation);
  };

  return (
    <TouchableOpacity onPress={() => setPopup(true)}>
      <Icon name={'question'} color={WaffleOrange} size={22} />
      <Popup
        visible={popup}
        onDismiss={() => setPopup(false)}
        viewStyle={containerStyle}>
        <InfoTitle>{title}</InfoTitle>

        <ScrollMaybeView style={containerStyle} scrollBody={scrollBody}>
          <TemplatedMarkup
            linkPress={linkPress}
            template={text}
            context={{ user }}
          />

          <Spacer y={2} />

          <VerticalCenter>
            <WaffleButtonAsync
              onPress={async () => await setPopup(false)}
              name={'Ok'}
            />
          </VerticalCenter>
        </ScrollMaybeView>
      </Popup>
    </TouchableOpacity>
  );
};
