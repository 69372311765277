import { Props } from '../../Shared/types';
import { isFullWeb } from '../../../../Helper/DeviceHelper';
import { PromoWebPet } from './PromoWebPet';
import { PromoMobilePet } from './PromoMobilePet';
import React from 'react';

export default function PromoPet({
  onPress,
  onPromo,
}: Props): React.ReactElement {
  if (isFullWeb()) {
    return <PromoWebPet onPromo={onPromo} onPress={onPress} />;
  } else {
    return <PromoMobilePet onPress={onPress} onPromo={onPromo} />;
  }
}
