import React from 'react';
import { Horizontal } from '../SimpleLayouts';
import { MessageText } from './MessageText';
import { ActionButtonImpl } from './ActionButtonImpl';
import { HomeScreenName } from '../../../screen-config';
import { useNavigation } from '@react-navigation/native';

type Props = {
  doComplete: () => Promise<void>;
  disabled: boolean;
};

export const ClaimsComplete = ({ doComplete, disabled }: Props) => {
  const navigation = useNavigation();

  return (
    <>
      <Horizontal>
        <MessageText style={{ width: 269 }}>
          Press below when you are done with your claim to close out this screen
          and return to Home.
        </MessageText>
      </Horizontal>

      <ActionButtonImpl
        name={'Complete chat'}
        onPress={async () => {
          navigation.navigate(HomeScreenName);
          doComplete && (await doComplete());
        }}
        disabled={disabled}
      />
    </>
  );
};
