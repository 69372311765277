import React, { useEffect, useState } from 'react';
import { Platform } from 'react-native';

import { Spacer } from '../SimpleComponents';
import { VerticalCenter } from '../SimpleLayouts';
import { CountMapTrue, MapTrue } from '../../Helper/PolicyHelper';
import { WaffleButtonAsync } from '../WaffleButtonAsync';
import { ProductChooserProps, ProductType } from './ProductChooserShared';
import {
  ProductChooserHeader,
  ProductSelector,
} from './ProductChooserComponents';
import { GetProducts_products } from '../../../../operation-result-types';
import { Markup } from '../Markup';
import { ThinBottomSpacer } from '../ContentShared';
import { isFullWeb } from '../../Helper/DeviceHelper';
import { useUserTracking } from '../../../lib/user-tracking/UserTrackingProvider';

const disclosuresMarkup = (disclosures: GetProducts_products[]) => {
  return disclosures
    .map(
      (disclosure) =>
        `### ${disclosure.id} Disclosure

${disclosure.disclosures}`
    )
    .join('\n\n');
};

const SelectedDisclosures = ({
  products,
  toggles,
}: {
  products: GetProducts_products[];
  toggles: Record<string, boolean>;
}) => {
  const selectedProducts = new Set([...MapTrue(toggles)]); // Object.entries(toggles).filter(e => e[1]).map(e => e[0]);
  const disclosures = products?.filter(
    (p) =>
      selectedProducts.size > 0 && selectedProducts.has(p.id) && p.disclosures
  );

  if (disclosures && disclosures?.length > 0) {
    return (
      <VerticalCenter style={{ maxWidth: isFullWeb() ? 900 : 300 }}>
        <Markup
          style={{
            heading1: { lineHeight: 36 },
            heading2: { lineHeight: 28 },
            heading3: { lineHeight: 22 },
          }}>
          {disclosuresMarkup(disclosures)}
        </Markup>
        <ThinBottomSpacer />
      </VerticalCenter>
    );
  }

  return null;
};

const ProductChooser = ({ products, onNext }: ProductChooserProps) => {
  const [toggles, setToggles] = useState<Record<string, boolean>>({});
  const userTracking = useUserTracking();
  const isPressed = (product: ProductType) => toggles?.[product.id] ?? false;
  const toggleProduct = (product: ProductType) => {
    setToggles({ ...toggles, [product.id]: !isPressed(product) });
  };

  useEffect(() => {
    userTracking?.publishProductListViewed(products);
  }, []);

  const selectedCount = CountMapTrue(toggles);
  const webProducts = new Set([
    'Phone',
    'Life',
    'Pet',
    'Cyber',
    'Travel',
    'Renters',
    'Home',
    'Car',
  ]);

  useEffect(() => {
    setToggles({});
  }, [products]);

  // wrapper used to be:VerticalSpread style={{ paddingTop: 25, paddingBottom: 25 }}
  return (
    <>
      <ProductChooserHeader selectedCount={selectedCount} />

      <ProductSelector
        products={products}
        isPressed={isPressed}
        toggleProduct={toggleProduct}
        toggles={toggles}
        productFilter={(item) =>
          Platform.OS !== 'web' || webProducts.has(item.id)
        }
      />

      <Spacer y={3} />

      <VerticalCenter>
        <WaffleButtonAsync
          testID={'product-chooser-next-btn'}
          disabled={selectedCount === 0}
          onPress={async () => await onNext(toggles)}
          name={'Next'}
        />

        <Spacer y={1.5} />
      </VerticalCenter>

      <SelectedDisclosures products={products} toggles={toggles} />
    </>
  );
};

export { ProductChooser };
