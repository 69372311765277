import React from 'react';
import styled from 'styled-components/native';

import WaffleText from '../WaffleText';
import { WaffleDarkGrey, WaffleLightBlue, White } from '../../Constants/Style';
import { Horizontal } from '../SimpleLayouts';
import { ChatBubbleWidth } from './ChatHelpers';

export const QuickReviewView = styled.View`
  width: ${ChatBubbleWidth}px;
  margin-left: 8px;
`;

const QRView = styled.View`
  justify-content: center;
  height: 39px;
  width: 100%;
  border-width: 1px;
  border-top-width: 0;
  border-color: ${WaffleDarkGrey};
`;

const QRLastView = styled(QRView)`
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
`;

const QuickReplyText = styled(WaffleText)`
  color: #000;
  background-color: ${White};
  font-size: 14px;
  letter-spacing: -0.22px;
  line-height: 17px;
  text-align: center;
`;

export const QuickReplyItem = (props: any) => {
  // yes, this seems wrong, but not bothered to make it right at this moment
  const V = props.cnt + 1 === props.maxCnt ? QRLastView : QRView;

  return (
    <V>
      <Horizontal>
        <QuickReplyText>{props.qr.title}</QuickReplyText>
      </Horizontal>
    </V>
  );
};
