import WaffleHalfTextInput from '../WaffleHalfTextInput';
import React from 'react';
import { WaffleTextInputProps } from '../../../component-config';
import * as yup from 'yup';
import { TextIconInput } from '../TextIconInput';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { WaffleOrange } from '../../Constants/Style';

export const lastNameValidator = yup.string().required();

// deprecated
export const LastNameInput = (props: WaffleTextInputProps) => (
  <WaffleHalfTextInput
    label={'Last Name'}
    placeholder={'Last Name'}
    containerProps={{ style: { marginLeft: 8 } }}
    otherProps={{
      autoCompleteType: 'family-name',
      keyboardType: 'name-phone-pad',
      textContentType: 'familyName',
    }}
    {...props}
  />
);

export const LastNameIconInput = (props: WaffleTextInputProps) => (
  <TextIconInput
    placeholder={'Last Name'}
    // containerProps={{ style: { marginLeft: 8 } }}
    otherProps={{
      autoCompleteType: 'family-name',
      keyboardType: 'name-phone-pad',
      textContentType: 'familyName',
    }}
    leftIcon={<Icon name={'person-outline'} color={WaffleOrange} size={23} />}
    {...props}
  />
);
