import React from 'react';

import { ClaimsComplete } from './ClaimsComplete';
import { ClaimsEmailButton } from './ClaimsEmailButton';

type Props = {
  doComplete: () => Promise<void>;
  disabled: boolean;
};

// For Life / Ladder
export const ContactLadder = ({ doComplete, disabled }: Props) => (
  <>
    <ClaimsEmailButton email={'help@ladderlife.com'} disabled={disabled} />

    <ClaimsComplete doComplete={doComplete} disabled={disabled} />
  </>
);
