import React, { useRef } from 'react';
import styled from 'styled-components/native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import ViewMoreText from 'react-native-view-more-text';

import WaffleText from '../WaffleText';
import {
  DarkText,
  WaffleGreen,
  WaffleGrey,
  WaffleLightBlue,
  WaffleLightGrey,
  WaffleMediumGreen,
  WaffleMediumRed,
  WaffleRed,
  WindowWidth,
} from '../../Constants/Style';
import { Image, Spacer, TouchableOpacity } from '../SimpleComponents';
import {
  HorizontalPacked,
  HorizontalSpread,
  VerticalCenter,
} from '../SimpleLayouts';
import Carousel from 'react-native-snap-carousel';
import {
  GetDraftUserPolicies_draftUserPolicies_product_suggestedCoverageExampleRestrictions,
  GetDraftUserPolicies_draftUserPolicies_product_suggestedCoveragePlanHighlights,
} from '../../../../operation-result-types';
import { RowImageProps } from './SuggestedCoverageShared';

const BoxWidth = 347;

const Box = styled.View`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  width: ${BoxWidth}px;
  min-height: 220px;
  background-color: ${WaffleLightGrey};
  border-radius: 20px;
`;
//   min-height: 195px;

const BoxTitle = styled(WaffleText)`
  color: ${DarkText};
  font-weight: 500;
  font-size: 24px;
  line-height: 27.6px;
`;

const PlanTypeText = styled(WaffleText)`
  color: ${WaffleGreen};
  font-size: 18px;
  line-height: 26px;
  max-width: 250px;
`;

const ExampleTypeText = styled(WaffleText)`
  color: ${WaffleRed};
  font-size: 18px;
  line-height: 26px;
  max-width: 250px;
`;

const InfoText = styled(WaffleText)`
  color: ${DarkText};
  font-size: 14px;
  line-height: 18.8px;
`;

const HighlightsTitle = styled(WaffleText)`
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  color: ${WaffleMediumGreen};
`;

const RestrictionsTitle = styled(WaffleText)`
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  color: ${WaffleMediumRed};
`;

const HighlightsText = styled(WaffleText)`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
`;

const BaseButton = ({ color, disabled, onPress, iconName }) => (
  <TouchableOpacity disabled={disabled} activeOpacity={0.5} onPress={onPress}>
    <Icon
      name={iconName}
      size={24}
      color={color}
      style={{ height: 23, width: 23, opacity: disabled ? 0.75 : 1.0 }}
    />
  </TouchableOpacity>
);

const LeftButton = ({ color, disabled, onPress }) => (
  <BaseButton
    color={color}
    disabled={disabled}
    onPress={onPress}
    iconName={'arrow-left-circle'}
  />
);

const RightButton = ({ color, disabled, onPress }) => (
  <BaseButton
    color={color}
    disabled={disabled}
    onPress={onPress}
    iconName={'arrow-right-circle'}
  />
);

const renderViewMore = (onPress) => (
  <WaffleText style={{ color: WaffleLightBlue }} onPress={onPress}>
    View more
  </WaffleText>
);
const renderViewLess = (onPress) => (
  <WaffleText style={{ color: WaffleLightBlue }} onPress={onPress}>
    View less
  </WaffleText>
);

// Attempt at collapsible markdown.  It doesn't work.  Will probably have to do something custom.
//
//   <Markup style={{ width: BoxWidth - 40, body: { color: 'white', fontSize: 14, lineHeight: 18.8 } }}>
//
const CollapsibleText = ({ text }) => (
  <ViewMoreText
    key={text.length} // This forces re-renter and re-calc of footer
    renderViewMore={renderViewMore}
    renderViewLess={renderViewLess}
    textStyle={[{ width: BoxWidth - 40 }]}
    numberOfLines={3}>
    <InfoText style={{ width: BoxWidth - 40, color: DarkText }}>
      {text}
    </InfoText>
  </ViewMoreText>
);
// xxx : Markdown above?

const Card = ({
  title,
  buttonColor,
  slide,
  hasNext,
  hasPrev,
  onNext,
  onPrev,
  Title,
}) => (
  <Box>
    <BoxTitle>{title}</BoxTitle>

    <Spacer y={1.5} />

    <HorizontalSpread
      style={{
        width: '100%',
        alignItems: 'flex-start',
      }}>
      <Title>{slide.title}</Title>

      <HorizontalPacked>
        <LeftButton color={buttonColor} disabled={!hasPrev} onPress={onPrev} />

        <Spacer x={2} />

        <RightButton color={buttonColor} disabled={!hasNext} onPress={onNext} />
      </HorizontalPacked>
    </HorizontalSpread>

    <Spacer y={1.5} />

    <CollapsibleText text={slide.description} />
  </Box>
);

type HighlightsBoxProps = {
  productId: string;
  highlights: GetDraftUserPolicies_draftUserPolicies_product_suggestedCoveragePlanHighlights[];
};

// todo : move this to something dynamically retrieved from server
export const HighlightAndRestrictionImages: Record<string, RowImageProps[]> = {
  Cyber: [
    {
      label: 'Cyber Extortion',
      source: require('../../../assets/images/products/design-coverage/cyber_extorion.webp'),
    },
    {
      label: 'Cyber Financial Fraud',
      source: require('../../../assets/images/products/design-coverage/cyber_financial_fraud.webp'),
    },
    {
      label: 'Deceptive Transfer Fraud',
      source: require('../../../assets/images/products/design-coverage/cyber_transfer_fraud.webp'),
    },
    {
      label: 'Cyber Breach of Privacy',
      source: require('../../../assets/images/products/design-coverage/cyber_breach_of_privacy.webp'),
    },
    {
      label: 'Cyber Bullying',
      source: require('../../../assets/images/products/design-coverage/cyber_bullying.webp'),
    },
    {
      label: 'Identity Theft',
      source: require('../../../assets/images/products/design-coverage/identity-theft.png'),
    },
    {
      label: 'Prior Knowledge',
      source: require('../../../assets/images/products/design-coverage/cyber_prior_knowledge.webp'),
    },
    {
      label: 'Acts of Certain People',
      source: require('../../../assets/images/products/design-coverage/cyber_acts.webp'),
    },
    {
      label: 'Business Activities',
      source: require('../../../assets/images/products/design-coverage/cyber_business_activities.webp'),
    },
    {
      label: 'Confiscation',
      source: require('../../../assets/images/products/design-coverage/cyber_confiscation.webp'),
    },
    {
      label: 'Digital Currencies',
      source: require('../../../assets/images/products/design-coverage/cyber_digital_currencies.webp'),
    },
    // "May Provide" section
    {
      label: 'Money Back',
      source: require('../../../assets/images/products/cyber/provide-money-back.png'),
    },
    {
      label: 'Expenses for Hired Professionals',
      source: require('../../../assets/images/products/cyber/provide-hiring-professionals.png'),
    },
    {
      label: 'Mental Health Expenses',
      source: require('../../../assets/images/products/cyber/provide-psych-services.png'),
    },
    {
      label: 'Relocation Expenses',
      source: require('../../../assets/images/products/cyber/provide-relocation.png'),
    },
    {
      label: 'Electronic Data Restoration',
      source: require('../../../assets/images/products/cyber/provide-electronic-data-restoration.png'),
    },
    {
      label: 'Lost Wages',
      source: require('../../../assets/images/products/cyber/provide-lost-wages.png'),
    },
    {
      label: 'Device Replacement',
      source: require('../../../assets/images/products/cyber/provide-device-replacement.png'),
    },
  ],
  Home: [
    {
      label: 'Computers + Home Office',
      source: require('../../../assets/images/products/design-coverage/home_computer_and_home_office.png'),
    },
    {
      label: 'Appliances + Electronics',
      source: require('../../../assets/images/products/design-coverage/home_appliance.png'),
    },
    {
      label: 'Smart Home Upgrades',
      source: require('../../../assets/images/products/design-coverage/home_smart_home.png'),
    },
    {
      label: 'House Cleaners + Sitters',
      source: require('../../../assets/images/products/design-coverage/home_cleaners.png'),
    },
    {
      label: 'Full Replacement Costs',
      source: require('../../../assets/images/products/design-coverage/home_full_replacement.png'),
    },
    {
      label: 'Home Wellness',
      source: require('../../../assets/images/products/design-coverage/home_wellness.png'),
    },
  ],
  Life: [
    {
      label: 'Term Life Insurance',
      source: require('../../../assets/images/products/design-coverage/life_term.webp'),
    },
    {
      label: 'No Medical Exam',
      source: require('../../../assets/images/products/design-coverage/life_no_exam.webp'),
    },
    {
      label: '10-day Free Look',
      source: require('../../../assets/images/products/design-coverage/life_10day.webp'),
    },
    {
      label: 'Accelerated Death Benefit',
      source: require('../../../assets/images/products/design-coverage/life_accelerated.webp'),
    },
    {
      label: 'Accidental Death',
      source: require('../../../assets/images/products/design-coverage/life_accidental.webp'),
    },
    {
      label: 'Level Premiums',
      source: require('../../../assets/images/products/design-coverage/life_level_premiums.webp'),
    },
  ],
  Pet: [
    {
      label: 'Accidents',
      source: require('../../../assets/images/products/design-coverage/pet_accidents.webp'),
    },
    {
      label: 'Illnesses',
      source: require('../../../assets/images/products/design-coverage/pet_illness.webp'),
    },
    {
      label: 'Hereditary and Congenital Conditions',
      source: require('../../../assets/images/products/design-coverage/pet_hereditary.webp'),
    },
    {
      label: 'Alternative Therapies',
      source: require('../../../assets/images/products/design-coverage/pet_alternate_therapies.webp'),
    },
    {
      label: 'Behavioral Issues',
      source: require('../../../assets/images/products/design-coverage/pet_behavioral.webp'),
    },
    {
      label: 'Pre-existing Conditions',
      source: require('../../../assets/images/products/design-coverage/pet_preexisting.webp'),
    },
    {
      label: 'Cosmetic Procedures',
      source: require('../../../assets/images/products/design-coverage/pet_cosmetic.webp'),
    },
    {
      label: 'Breeding Costs',
      source: require('../../../assets/images/products/design-coverage/pet_breeding_costs.webp'),
    },
    {
      label: 'Dental Cleanings',
      source: require('../../../assets/images/products/design-coverage/pet_dental_costs.webp'),
    },
    {
      label: 'Experimental or Investigative Treatment',
      source: require('../../../assets/images/products/design-coverage/pet_experimental.webp'),
    },
  ],
  Renters: [
    {
      label: 'Stolen or Damaged Belongings',
      source: require('../../../assets/images/products/design-coverage/renters_stolen.webp'),
    },
    {
      label: 'Your Life Expenses',
      source: require('../../../assets/images/products/design-coverage/renters_life.webp'),
    },
    {
      label: "Damages You're Legally Required to Pay For",
      source: require('../../../assets/images/products/design-coverage/renters_damages.webp'),
    },
    {
      label: 'Medical Payments and Property Damage Payments to Others',
      source: require('../../../assets/images/products/design-coverage/renters_medical.webp'),
    },
    {
      label: 'Contents Coverage Over $40K',
      source: require('../../../assets/images/products/design-coverage/renters_over_40k.webp'),
    },
    {
      label: 'Liability Costs Above $300K',
      source: require('../../../assets/images/products/design-coverage/renters_over_300k.webp'),
    },
    {
      label: "Specific Dwellings That Aren't Apartments",
      source: require('../../../assets/images/products/design-coverage/renters_non_apartments.webp'),
    },
    {
      label: 'Animals or pets',
      source: require('../../../assets/images/products/design-coverage/renters_pets.webp'),
    },
    {
      label: 'Trees, Shrubs, and Lawns',
      source: require('../../../assets/images/products/design-coverage/renters_plants.webp'),
    },
  ],
  Travel: [
    {
      label: 'Cancellation for a Covered Reason',
      source: require('../../../assets/images/products/design-coverage/travel_cancellation_covered.webp'),
    },
    {
      label: 'Cancellation for Work Reasons',
      source: require('../../../assets/images/products/design-coverage/travel_cancellation_work.webp'),
    },
    {
      label: 'Trip Interruption',
      source: require('../../../assets/images/products/design-coverage/travel_trip_interruption.webp'),
    },
    {
      label: 'Trip Delay',
      source: require('../../../assets/images/products/design-coverage/travel_trip_delay.webp'),
    },
    {
      label: 'Missed Connections',
      source: require('../../../assets/images/products/design-coverage/travel_missed_connections.webp'),
    },
    {
      label: 'Pre-Existing Conditions',
      source: require('../../../assets/images/products/design-coverage/travel_pre_existing.webp'),
    },
    {
      label: 'High-Risk Behaviors',
      source: require('../../../assets/images/products/design-coverage/travel_high_risk.webp'),
    },
    {
      label: 'Committing a Criminal Act',
      source: require('../../../assets/images/products/design-coverage/travel_criminal_act.webp'),
    },
    {
      label: 'Acting Under the Influence',
      source: require('../../../assets/images/products/design-coverage/travel_under_influence.webp'),
    },
    {
      label: 'War or Invasion',
      source: require('../../../assets/images/products/design-coverage/travel_war_invasion.webp'),
    },
  ],
};

type ImageMaybeProps = {
  productId: string;
  title: string;
};

export const ImageMaybe = ({ productId, title }: ImageMaybeProps) => {
  const img = HighlightAndRestrictionImages[productId]?.filter(
    (i) => i.label === title
  )?.[0];

  if (img) {
    return <Image source={img.source} style={{ width: 70, height: 70 }} />;
  } else {
    return <WaffleText>img</WaffleText>;
  }
};

export const HighlightsBox = ({
  productId,
  highlights,
}: HighlightsBoxProps) => {
  const lastIdx = highlights.length - 1;
  const radius = 20;

  return (
    !!highlights && (
      <>
        {highlights.map((highlight, idx) => (
          <VerticalCenter
            key={highlight.title}
            style={{
              borderWidth: 1,
              borderColor: WaffleGrey,
              width: 347,
              borderBottomWidth: idx === lastIdx ? 1 : 0,
              borderTopLeftRadius: idx == 0 ? radius : 0,
              borderTopRightRadius: idx == 0 ? radius : 0,
              borderBottomLeftRadius: idx === lastIdx ? radius : 0,
              borderBottomRightRadius: idx === lastIdx ? radius : 0,
            }}>
            <Spacer y={3.75} />

            <ImageMaybe productId={productId} title={highlight.title} />

            <Spacer y={1.25} />

            <HighlightsTitle style={{ width: 300, textAlign: 'center' }}>
              {highlight.title}
            </HighlightsTitle>

            <Spacer y={1.25} />

            <HighlightsText style={{ width: 300, textAlign: 'center' }}>
              {highlight.description}
            </HighlightsText>

            <Spacer y={2.5} />
          </VerticalCenter>
        ))}
      </>
    )
  );
};

type RestrictionsBoxProps = {
  productId: string;
  restrictions: GetDraftUserPolicies_draftUserPolicies_product_suggestedCoverageExampleRestrictions[];
};

export const RestrictionsBox = ({
  productId,
  restrictions,
}: RestrictionsBoxProps) => {
  const lastIdx = restrictions.length - 1;
  const radius = 20;

  return (
    !!restrictions && (
      <>
        {restrictions.map((restriction, idx) => (
          <VerticalCenter
            key={restriction.title}
            style={{
              borderWidth: 1,
              borderColor: WaffleGrey,
              width: 347,
              borderBottomWidth: idx === lastIdx ? 1 : 0,
              borderTopLeftRadius: idx == 0 ? radius : 0,
              borderTopRightRadius: idx == 0 ? radius : 0,
              borderBottomLeftRadius: idx === lastIdx ? radius : 0,
              borderBottomRightRadius: idx === lastIdx ? radius : 0,
            }}>
            <Spacer y={2.75} />

            <ImageMaybe productId={productId} title={restriction.title} />

            <Spacer y={1.25} />

            <RestrictionsTitle style={{ width: 300, textAlign: 'center' }}>
              {restriction.title}
            </RestrictionsTitle>

            <Spacer y={1.25} />

            <HighlightsText style={{ width: 300, textAlign: 'center' }}>
              {restriction.description}
            </HighlightsText>

            <Spacer y={2.5} />
          </VerticalCenter>
        ))}
      </>
    )
  );
};
