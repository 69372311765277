import React from 'react';
import styled from 'styled-components/native';

import WaffleText from './WaffleText';
import { Image } from './SimpleComponents';
import { VerticalCenter } from './SimpleLayouts';
import { ImageSourcePropType, ScaledSize, StyleProp } from 'react-native';
import { IsFullSize } from '../Constants/Style';

// todo : review the below size, weight.  Seems off...
const HelloText = styled(WaffleText)`
  font-size: 28px;
  font-weight: 400;
  line-height: 32px;
  color: #ffffff;
`;

// TODO: Investigate a better way to import images
// eslint-disable-next-line @typescript-eslint/no-var-requires
const OrangeHeaderImage = require('../../assets/images/RectangleOrangeWeb.png');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const BlueHeaderImage = require('../../assets/images/RectangleBlueWeb.png');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const GreenHeaderImage = require('../../assets/images/RectangleGreenWeb.png');

const leftBase = (layout: ScaledSize) => (IsFullSize(layout) ? 150 : -30);

interface Props {
  title: string;
  titleStyle?: StyleProp<any>;
  image: ImageSourcePropType;
  layout?: ScaledSize;
}

const HeaderNavTitle: React.FC<Props> = ({
  title,
  titleStyle,
  image,
  layout,
}) => (
  <>
    <Image
      source={image}
      resizeMode={'contain'}
      style={{
        width: 500,
        height: 500,
        position: 'absolute',
        left: leftBase(layout),
        top: -180,
      }}
    />

    <VerticalCenter
      style={[
        titleStyle,
        {
          position: 'absolute',
          left: 100 + leftBase(layout),
          top: 36,
          width: 370,
        },
      ]}>
      <HelloText
        style={[
          titleStyle,
          {
            zIndex: 99,
            textAlign: 'center',
          },
        ]}>
        {title}
      </HelloText>
    </VerticalCenter>
  </>
);

export {
  HelloText,
  HeaderNavTitle,
  OrangeHeaderImage,
  BlueHeaderImage,
  GreenHeaderImage,
};
