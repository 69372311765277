import React from 'react';
import { WaffleButtonAsync } from '../WaffleButtonAsync';

export const AnswerTableButton = ({ name, width, onPress }: any) => (
  <WaffleButtonAsync
    name={name}
    style={{
      backgroundColor: '#ffffff',
      width,
      height: 35,
      borderWidth: 1,
      borderRadius: 5,
    }}
    textStyle={{
      color: '#000000',
      textTransform: 'none',
    }}
    onPress={onPress}
  />
);
