/*
 * Custom scrollview with scroll bar styling and some usability enhancements
 */

import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Animated, Platform, ScrollView, View, ViewStyle } from 'react-native';
import { PanGestureHandler } from 'react-native-gesture-handler';

type Props = {
  style?: ViewStyle;
  scrollBarStyle?: ViewStyle;
  height: number;
  width: number;
  onBottom: () => void;
  children: ReactNode;
};

function ScrollBox({
  height,
  width,
  onBottom,
  style,
  scrollBarStyle,
  children,
}: Props) {
  const scrollViewRef = useRef<ScrollView>();
  const startPanOffset = useRef(0);
  const currentScrollIndicatorPosition = useRef(0);
  const [completeScrollBarHeight, setCompleteScrollBarHeight] = useState(1);
  const [visibleScrollBarHeight, setVisibleScrollBarHeight] = useState(0);

  const scrollIndicator = useRef(new Animated.Value(0)).current;

  const scrollIndicatorSize =
    completeScrollBarHeight > visibleScrollBarHeight
      ? (visibleScrollBarHeight * visibleScrollBarHeight) /
        completeScrollBarHeight
      : visibleScrollBarHeight;

  const difference =
    visibleScrollBarHeight > scrollIndicatorSize
      ? visibleScrollBarHeight - scrollIndicatorSize
      : 1;

  const scrollIndicatorPosition = Animated.multiply(
    scrollIndicator,
    visibleScrollBarHeight / completeScrollBarHeight
  ).interpolate({
    inputRange: [0, difference],
    outputRange: [0, difference],
    extrapolate: 'clamp',
  });

  useEffect(() => {
    const id = scrollIndicator.addListener((current) => {
      if (
        completeScrollBarHeight <=
        visibleScrollBarHeight + current.value + 10 // Enable continue button when almost to end of scroll
      ) {
        onBottom();
      }
    });
    const idPos = scrollIndicatorPosition.addListener(
      ({ value }) => (currentScrollIndicatorPosition.current = value)
    );

    return function cleanup() {
      scrollIndicatorPosition.removeListener(idPos);
      scrollIndicator.removeListener(id);
    };
  });

  return (
    <View
      style={[
        {
          flex: 1,
          flexDirection: 'row',
          width,
          height,
        },
        style,
      ]}>
      <ScrollView
        ref={scrollViewRef}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        scrollEventThrottle={16}
        onContentSizeChange={(_, height) => setCompleteScrollBarHeight(height)}
        onLayout={({
          nativeEvent: {
            layout: { height },
          },
        }) => setVisibleScrollBarHeight(height)}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollIndicator } } }],
          { useNativeDriver: false }
        )}
        style={{ height: Platform.OS === 'ios' ? '100%' : height }}>
        {children}
      </ScrollView>

      <PanGestureHandler
        enabled={true}
        minDist={1}
        onGestureEvent={({ nativeEvent: { y, state } }) => {
          if (state === 2) {
            startPanOffset.current = y - currentScrollIndicatorPosition.current;
            return;
          }

          const offset = startPanOffset.current;
          const clampedGestureY = Math.max(0, Math.min(y - offset, difference));
          const newPosition =
            (clampedGestureY * completeScrollBarHeight) /
            visibleScrollBarHeight;
          // Logger(
          //   `state=${state} rawY=${y} translationY=${translationY} clampedGestureY=${clampedGestureY} offset=${offset} scrollSize=${scrollIndicatorSize}`
          // );

          scrollViewRef.current.scrollTo({
            x: 0,
            y: newPosition,
            animated: false,
          });
        }}>
        <View
          style={{
            height: '100%',
            width: 15,
            backgroundColor: 'transparent',
            borderRadius: 3,
            position: 'relative',
            right: -10,
          }}>
          <Animated.View
            style={[
              {
                height: scrollIndicatorSize,
                transform: [{ translateY: scrollIndicatorPosition }],
              },
              scrollBarStyle,
            ]}
          />
        </View>
      </PanGestureHandler>
    </View>
  );
}

export { ScrollBox };
