import { Policies, Products } from '../../../../component-config';
import { HasPetPolicy, IsPet } from '../../../Helper/PolicyHelper';
import PromoPet from './PromoPet';

type PromotionTypes = 'Pet' | 'Cyber';

function isPetPromoEligible(
  policies: Policies | undefined,
  products: Products
): boolean {
  return (
    policies &&
    !HasPetPolicy(policies) &&
    (products?.find(IsPet)?.eligibility?.isEligible ?? false)
  );
}

export { PromoPet, PromotionTypes, isPetPromoEligible };
