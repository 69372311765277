import { Image, ImageStyle } from 'react-native';
import React from 'react';

type Props = {
  style?: ImageStyle;
};

export function WaffleLogo({ style = {} }: Props): React.ReactElement {
  return (
    <Image
      source={require('../../../../assets/images/waffleLogo.webp')}
      resizeMode={'contain'}
      style={{
        width: 155,
        height: 40,
        ...style,
      }}
    />
  );
}
