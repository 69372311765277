import { useQuery } from '@apollo/client';
import React from 'react';
import styled from 'styled-components/native';
import { Avatar } from 'react-native-elements';
import md5 from 'md5';
import { HorizontalPacked, HorizontalSpread } from './SimpleLayouts';
import { Spacer, TouchableOpacity } from './SimpleComponents';
import WaffleText from './WaffleText';
import { GET_LOCAL_USER } from '../GraphQL/Waffle/Queries';
import { UserName } from '../Helper/UserHelpers';
import { ScaledSize } from 'react-native';
import { LocalUser } from '../../../operation-result-types';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList, SettingsScreenName } from '../../screen-config';
import { ResetToStart } from './ResetToStart';

const NameText = styled(WaffleText)`
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  color: black;
`;

export interface HeaderNavProps {
  name: string;
  email: string;
}

// { name, email }: HeaderNavProps

interface Props {
  layout?: ScaledSize;
}

const HeaderNav: React.FC<Props> = ({ layout }) => {
  const {
    data: { user },
  } = useQuery<LocalUser>(GET_LOCAL_USER);
  const isCompleted = user?.userState === 'COMPLETE';
  const { email } = user;
  const name = UserName(user);
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  return (
    <HorizontalSpread style={{ marginTop: 40, width: '100%' }}>
      <Spacer x={1} style={{ flexGrow: 1 }} />

      <HorizontalPacked>
        <Avatar
          source={{ uri: `https://www.gravatar.com/avatar/${md5(email)}?d=mp` }}
          size={40}
          rounded
          activeOpacity={0.7}
          onPress={() => isCompleted && navigation.navigate(SettingsScreenName)}
        />
        <ResetToStart />

        <Spacer x={2} />

        <TouchableOpacity
          onPress={() => navigation.navigate(SettingsScreenName)}
          disabled={!isCompleted}>
          <NameText>{name}</NameText>
        </TouchableOpacity>

        <Spacer x={5} />
      </HorizontalPacked>
    </HorizontalSpread>
  );
};

export { HeaderNav };
