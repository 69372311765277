import React, { ReactNode } from 'react';
import {
  Modal,
  Platform,
  TouchableWithoutFeedback,
  ViewStyle,
} from 'react-native';
import styled from 'styled-components/native';

import { CloseButton } from './CloseButton';
import { ModalBackground } from './SimpleComponents';
import { isFullWeb, IsSmallScreen } from '../Helper/DeviceHelper';
import { VerticalCenter } from './SimpleLayouts';
import { Resizable } from 'react-native-web-hooks';
import { MenuWidth } from '../Constants/Style';

const ModalView = styled.View`
  position: relative;
  padding: 18px;
  border-radius: 9px;
  background-color: #fff;
`;

type PopupProps = {
  visible: boolean;
  noClose?: boolean;
  onDismiss?: () => void;
  hasSideMenu?: boolean;
  children: ReactNode;
  viewStyle?: ViewStyle;
  backgroundStyle?: ViewStyle;
};

const Popup = ({
  visible,
  noClose = false,
  hasSideMenu = true,
  onDismiss,
  children,
  viewStyle,
  backgroundStyle,
}: PopupProps) => {
  const getMenuOffset = (layout: any, sideMenuExists: boolean) => {
    if (sideMenuExists && Platform.OS !== 'ios') {
      return MenuWidth(layout);
    }

    return 0;
  };

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={visible}
      hardwareAccelerated={false}>
      <TouchableWithoutFeedback onPress={onDismiss} style={backgroundStyle}>
        <ModalBackground style={backgroundStyle}>
          <Resizable>
            {(layout) => (
              <VerticalCenter>
                <TouchableWithoutFeedback onPress={(e) => e.stopPropagation()}>
                  <ModalView
                    style={{
                      ...{
                        marginLeft:
                          (IsSmallScreen() ? 10 : 30) +
                          getMenuOffset(layout, hasSideMenu),
                        marginRight: IsSmallScreen() ? 10 : 30,
                        maxWidth: isFullWeb() ? 340 : 320,
                      },
                      ...viewStyle,
                    }}>
                    {noClose || (
                      <CloseButton onPress={() => onDismiss && onDismiss()} />
                    )}
                    {children}
                  </ModalView>
                </TouchableWithoutFeedback>
              </VerticalCenter>
            )}
          </Resizable>
        </ModalBackground>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

export default Popup;
