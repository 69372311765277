import React from 'react';

import { WaffleChatButton } from './WaffleChatButton';
import { PlainView } from './SimpleComponents';
import { StackHeaderLeftButtonProps } from '@react-navigation/stack';

export const ChatHeaderButton = (props: StackHeaderLeftButtonProps) => (
  <PlainView style={{ marginRight: 11, marginLeft: 11, marginBottom: 3 }}>
    <WaffleChatButton disabled={false} {...props} />
  </PlainView>
);
