import React from 'react';
import { Logger } from '../Helper/Logger';
import { PlainView } from './SimpleComponents';
import Spinner from './Spinner';
import { ContentUnified } from './ContentShared';
import styled from 'styled-components/native';
import WaffleText from './WaffleText';
import { ImageSourcePropType, StyleProp } from 'react-native';
import SpinnerBlack from './SpinnerBlack';
import { LogoLargeImage } from '../Constants/Style';

const Text = styled(WaffleText)`
  font-size: 26px;
  line-height: 30px;
  margin-top: 13px;
  margin-bottom: 13px;
  text-align: center;
`;

const LoadingView = styled.View`
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LogoImage = styled.Image`
  width: 219px;
  height: 54px;
  margin-bottom: 36px;
`;

interface LoadingProps {
  title: string;
  errors?: string;
  message?: string;
  image?: ImageSourcePropType;
  titleStyle?: StyleProp<any>;
  spinner?: boolean;
  addingProduct?: boolean;
}

const LoadingUnified: React.FC<LoadingProps> = ({
  title,
  image,
  titleStyle,
  message,
  errors,
  addingProduct,
  spinner = true,
}) => {
  if (errors) {
    Logger(`ERROR LoadingScreen: ${JSON.stringify(errors)}`);
  }

  return (
    <ContentUnified
      title={title}
      titleStyle={titleStyle}
      image={image}
      addingProduct={addingProduct}>
      <LoadingView>
        <LogoImage source={LogoLargeImage} />

        {errors && <Text>{errors}</Text>}

        {!errors && (
          <PlainView>
            {message && <Text>{message}</Text>}
            {spinner && <SpinnerBlack />}
          </PlainView>
        )}
      </LoadingView>
    </ContentUnified>
  );
};
// default message: Loading Amazing!

export { LoadingUnified, LoadingProps };
