import React from 'react';
import styled from 'styled-components/native';

import { ModalSearchPopup } from './ModalSearchPopup';
import WaffleText from './WaffleText';
import { ScrollView } from './SimpleComponents';

const ItemView = styled.TouchableOpacity`
  background-color: #fff;
  border: 1px solid #dedede;
  padding-left: 5px;
  padding-right: 5px;
  justify-content: center;
`;
//   border-radius: 8px;

const ItemImpl = ({ item, onChoose }: any) => {
  const { label, value } = item;

  return (
    <ItemView style={{ height: 35 }} onPress={() => onChoose(item)}>
      <WaffleText key={'name'}>{label}</WaffleText>
    </ItemView>
  );
};

export const ChooserPopup = ({
  popup,
  setPopup,
  items,
  onChoose,
  style = {},
  Item = ItemImpl,
}: any) => (
  <ModalSearchPopup popup={popup} setPopup={setPopup}>
    <ScrollView
      style={{
        ...{ height: 300, marginTop: 10 },
        ...style,
      }}>
      {items.length > 0 &&
        items.map((r: any, i: any) => (
          <Item key={i} onChoose={onChoose} item={r} />
        ))}
    </ScrollView>
  </ModalSearchPopup>
);
