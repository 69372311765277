import React, { useState } from 'react';
import styled from 'styled-components/native';
import {
  GET_LOCAL_POSITION,
  SUGGEST_LOCATION,
} from '../../GraphQL/Waffle/Queries';
import { Logger } from '../../Helper/Logger';
import WaffleText from '../WaffleText';
import WaffleTextInput from '../WaffleTextInput';
import { States } from '../../Constants/States';
import { ModalSearchPopup } from '../ModalSearchPopup';
import { ScrollView } from '../SimpleComponents';
import { IsSmallScreen } from '../../Helper/DeviceHelper';
import { ApolloClient, useApolloClient, useQuery } from '@apollo/client';
import {
  LocalPosition,
  SuggestLocation,
} from '../../../../operation-result-types';
import {
  SuggestedAddress,
  SuggestedAddressSuggestion,
} from '../../../component-config';
import { TouchableOpacityProps, ViewStyle } from 'react-native';

const AddressView = styled.TouchableOpacity`
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #dedede;
  padding: 5px;
`;

export const onAddressSearch = async (
  client: ApolloClient<object>,
  value: string,
  setResults: (results: SuggestedAddressSuggestion[]) => void,
  latitude: number,
  longitude: number,
  state?: string
) => {
  if (value?.length > 0) {
    try {
      const {
        data: { suggestLocation },
      } = await client.query<SuggestLocation>({
        query: SUGGEST_LOCATION,
        variables: {
          query: value,
          latitude,
          longitude,
          state,
        },
      });

      if (suggestLocation) {
        const { suggestions } = suggestLocation;
        // Logger(`Response: ${JSON.stringify(suggestions.length)}`);
        if (suggestions?.length > 0) {
          const results = suggestions.map((item) => {
            if (item?.address?.state) {
              item.address.state =
                States[item.address.state] ?? item.address.state;
            }
            return item;
          });
          // Logger(`Suggestions: ${JSON.stringify(results.length)}`);
          setResults(results);
        }
      }
    } catch (e) {
      Logger(
        `error calling onAddressSearch: q=${value} error=${JSON.stringify(e)}`
      );
    }
  }
};

type AddressItemProps = {
  address: SuggestedAddress;
  onChoose: (item: SuggestedAddress) => void;
  containerStyle?: ViewStyle;
};

export const AddressItem = ({
  address,
  onChoose,
  containerStyle,
}: AddressItemProps) => {
  const { houseNumber, street, city, state, postalCode } = address;

  return (
    <AddressView
      style={{
        marginTop: 10,
        ...containerStyle,
      }}
      onPress={() => onChoose(address)}>
      <WaffleText key={'street'}>
        {houseNumber} {street}
      </WaffleText>
      <WaffleText key={'cityState'}>
        {city}, {state} {postalCode}
      </WaffleText>
    </AddressView>
  );
};

type AddressSearchPopupProps = {
  popup: boolean;
  setPopup: (boolean) => void;
  onChoose: (item: SuggestedAddress) => void;
  state?: string | undefined;
};

export const AddressSearchPopup = ({
  popup,
  setPopup,
  onChoose,
  state,
}: AddressSearchPopupProps) => {
  const client = useApolloClient();
  const {
    data: { position },
  } = useQuery<LocalPosition>(GET_LOCAL_POSITION);
  const [results, setResults] = useState<SuggestedAddressSuggestion[]>([]);

  return (
    <ModalSearchPopup
      popup={popup}
      setPopup={setPopup}
      containerStyle={{ maxHeight: 360 }}>
      <WaffleTextInput
        placeholder={'Start typing your address'}
        placeholderTextColor={'#555555'}
        onChangeText={async (text) =>
          await onAddressSearch(
            client,
            text,
            setResults,
            position.latitude,
            position.longitude,
            state
          )
        }
        otherProps={{
          blurOnSubmit: false,
          autoFocus: true,
        }}
      />

      <ScrollView
        keyboardShouldPersistTaps={'handled'}
        style={{ height: IsSmallScreen() ? 150 : 200 }}>
        {results.length > 0 &&
          results.map((r, i) => (
            <AddressItem key={i} onChoose={onChoose} address={r.address} />
          ))}
      </ScrollView>
    </ModalSearchPopup>
  );
};
