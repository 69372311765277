import { isFullWeb } from '../../../Helper/DeviceHelper';
import React, { ReactNode } from 'react';
import { HorizontalEvenly, VerticalCenter } from '../../SimpleLayouts';
import { Platform } from 'react-native';
import { WaffleDarkGrey } from '../../../Constants/Style';

type Props = {
  children: ReactNode;
};

export function Header({ children }: Props) {
  return (
    <VerticalCenter
      style={{
        width: isFullWeb() ? 450 : 335,
        minHeight: 70,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(173, 164, 165, 0.38)',
        padding: 12,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
      }}>
      {Platform.OS === 'web' ? (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <HorizontalEvenly style={{ width: '100%', flexWrap: 'wrap', gap: 12 }}>
          {children}
        </HorizontalEvenly>
      ) : (
        <HorizontalEvenly style={{ width: '100%', flexWrap: 'wrap' }}>
          {children}
        </HorizontalEvenly>
      )}
    </VerticalCenter>
  );
}

export default function ProtectionBox({ children }: Props) {
  // @ts-ignore
  return (
    <VerticalCenter
      style={{
        width: isFullWeb() ? 450 : 335, // Should be 495 for Pet
        // height: isFullWeb() ? 362 : undefined,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 20,
        paddingBottom: 30,
        marginBottom: 30,
        marginRight: 10,
        marginLeft: 10,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        boxShadow: '0px 4px 28px -9px rgba(0, 0, 0, 0.33)',
        ...(Platform.OS === 'ios' && {
          borderWidth: 1,
          borderColor: WaffleDarkGrey,
        }),
      }}>
      {children}
    </VerticalCenter>
  );
}
