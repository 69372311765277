import React from 'react';
import WaffleText from './WaffleText';

type Props = {
  children: React.ReactNode;
};

export default function Disclosure({ children }: Props): React.ReactElement {
  return (
    <WaffleText
      style={{
        fontSize: 10,
        textAlign: 'center',
        lineHeight: 12,
      }}>
      {children}
    </WaffleText>
  );
}
