import { useApolloClient } from '@apollo/client';
import {
  Box,
  coverageItem,
  Description,
  ItemLabelOrange,
  updateCoverageValue,
} from './SuggestedCoverageShared';
import { useContext } from 'react';
import { SuggestedCoverageContext } from '../../Screens/SuggestedCoverageScreen';
import React from 'react';
import { HorizontalSpread, VerticalCenter } from '../SimpleLayouts';
import { Image, Spacer } from '../SimpleComponents';
import { WaffleSwitch } from '../WaffleSwitch';
import { PolicyCoverage, PolicyProps } from '../../../component-config';
import { COVERAGE_TRAVEL_CFAR } from '../../Helper/PolicyHelper';
import { Platform, View } from 'react-native';

const cancelForAnyReasonText = `Select the CancelFlex plan for all the same coverage and benefits of Pro Plus with the added feature of Cancel for Any Reason coverage which reimburses up to 60% if your prepaid non-refundable trip cost when you cancel your trip for other than a covered reason. Benefits only apply for trip payment made with cash, credit card or check. Limitations apply including benefits are not payable if your travel supplier cancels your trip for any reason.`;

type Props = {
  hasCfar: boolean;
  toggleCfar: () => Promise<void>;
  cfar: PolicyCoverage;
};

const TripCancelForAnyReasonEditorNative = ({
  hasCfar,
  toggleCfar,
  cfar,
}: Props) => (
  <Box>
    <VerticalCenter>
      <Image
        source={require('../../../assets/images/products/design-coverage/travel_cfar.webp')}
        style={{ width: 90, height: 90 }}
      />

      <Spacer y={2} />

      <HorizontalSpread style={{ width: 335 }}>
        <ItemLabelOrange>Cancel for Any Reason</ItemLabelOrange>

        <WaffleSwitch value={hasCfar} setValue={toggleCfar} />
      </HorizontalSpread>

      <Spacer y={2.5} />

      <Description>
        {cfar.coverage.explanation.text || cancelForAnyReasonText}
      </Description>
    </VerticalCenter>
  </Box>
);

const TripCancelForAnyReasonEditorWeb = ({
  hasCfar,
  toggleCfar,
  cfar,
}: Props) => (
  <View
    style={[
      {
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        justifyContent: 'center',
      },
    ]}>
    <View
      style={{
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
        rowGap: 16,
        width: 440,
      }}>
      <Image
        source={require('../../../assets/images/products/design-coverage/travel_cfar.webp')}
        style={{ width: 90, height: 90 }}
      />

      <ItemLabelOrange>Cancel for Any Reason</ItemLabelOrange>

      <Description>
        {cfar.coverage.explanation.text || cancelForAnyReasonText}
      </Description>
    </View>

    <View
      style={{
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
        width: 440,
      }}>
      <WaffleSwitch value={hasCfar} setValue={toggleCfar} />
    </View>
  </View>
);

export const TripCancelForAnyReasonEditor = ({ policy }: PolicyProps) => {
  const client = useApolloClient();
  const { setState } = useContext(SuggestedCoverageContext);
  const cfarEligible =
    policy.answerInputs.__typename === 'PolicyTripAnswer' &&
    policy.answerInputs.CancelForAnyReasonEligible;
  const cfar = coverageItem(policy.coverage, COVERAGE_TRAVEL_CFAR);
  const hasCfar = cfar.coverageLimit === 'true';

  const toggleCfar = async () => {
    await updateCoverageValue(client, cfar.id, (!hasCfar).toString(), setState);
  };

  if (cfarEligible) {
    const Editor =
      Platform.OS === 'web'
        ? TripCancelForAnyReasonEditorWeb
        : TripCancelForAnyReasonEditorNative;

    return <Editor hasCfar={hasCfar} toggleCfar={toggleCfar} cfar={cfar} />;
  }

  return null;
};
