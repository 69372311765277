import React from 'react';

import { ClaimsCallButton } from './ClaimsCallButton';
import { ClaimsComplete } from './ClaimsComplete';
import { Horizontal } from '../SimpleLayouts';
import { MessageText } from './MessageText';
import { ClaimsEmailButton } from './ClaimsEmailButton';

type Props = {
  doComplete: () => Promise<void>;
  disabled: boolean;
};

// For Cyber / Chubb
export const ContactChubb = ({ doComplete, disabled }: Props) => (
  <>
    <ClaimsCallButton phone={'+1-833-550-9664'} disabled={disabled} />

    <Horizontal>
      <MessageText style={{ textAlign: 'center' }}>or</MessageText>
    </Horizontal>

    <ClaimsEmailButton email={'hello@blinkinsured.com'} disabled={disabled} />

    <ClaimsComplete doComplete={doComplete} disabled={disabled} />
  </>
);
