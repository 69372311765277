import React from 'react';
import styled from 'styled-components/native';

import { PlainView } from './SimpleComponents';
import Spinner from './Spinner';
import WaffleText from './WaffleText';
import { LogoLargeImage } from '../Constants/Style';

const LogoImage = styled.Image`
  width: 219px;
  height: 54px;
  margin-bottom: 36px;
`;

const Text = styled(WaffleText)`
  font-size: 26px;
  line-height: 30px;
  margin-top: 13px;
  margin-bottom: 13px;
  text-align: center;
`;

type LoadingProps = {
  loadingText: string;
};

export const Loading = ({ loadingText }: LoadingProps) => (
  <PlainView
    style={{
      position: 'absolute',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      backgroundColor: 'white',
    }}>
    <LogoImage source={LogoLargeImage} />
    {!!loadingText && <Text>{loadingText}</Text>}
    <Spinner />
  </PlainView>
);
