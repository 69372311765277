import React from 'react';
import { ViewStyle } from 'react-native';
import { Divider } from 'react-native-elements';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import styled from 'styled-components/native';
import { WaffleChatButton } from '../WaffleChatButton';
import styles, { WaffleDarkBlue } from '../../Constants/Style';
import { HorizontalSpread } from '../SimpleLayouts';
import { Spacer } from '../SimpleComponents';
import { AdaptiveContentWidth } from '../../Helper/DeviceHelper';
import { hasBottomSwitcher } from '../../Helper/FeatureFlags';
import { HomeScreenName, SettingsScreenName } from '../../../screen-config';
import { useNavigation } from '@react-navigation/native';

interface Props {
  style?: ViewStyle;
  disabled?: boolean;
}

export const FooterHeight = () => (hasBottomSwitcher() ? 83 : 60);

const FooterRow = styled.View`
  width: 100%;
  height: ${FooterHeight()}px;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
`;

const Footer: React.FC<Props> = ({ style, disabled }) => {
  const navigation = useNavigation();

  return (
    <FooterRow style={[styles.fullWidth, style]}>
      <Divider style={styles.fullWidth} />

      <Spacer y={2} />

      <HorizontalSpread style={{ width: AdaptiveContentWidth() }}>
        <Icon
          name={'cog-outline'}
          size={24}
          color={WaffleDarkBlue}
          onPress={() => navigation.navigate(SettingsScreenName)}
        />

        <Icon
          name={'home-outline'}
          size={24}
          color={WaffleDarkBlue}
          onPress={() => navigation.navigate(HomeScreenName)}
        />

        <WaffleChatButton
          size={24}
          disabled={disabled}
          iconColor={WaffleDarkBlue}
        />
      </HorizontalSpread>
    </FooterRow>
  );
};

export default Footer;
