import styled from 'styled-components/native';
import WaffleText from './WaffleText';

const ButtonText = styled(WaffleText)`
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.6px;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;
`;

export default ButtonText;
