import { DidYouKnowBox, Footer } from '../Shared/DidYouKnowItems';
import { Image } from '../../SimpleComponents';
import { isFullWeb } from '../../../Helper/DeviceHelper';
import WaffleText from '../../WaffleText';
import React from 'react';

export const Ransomware = () => {
  return (
    <DidYouKnowBox>
      <Image
        source={require('../../../../assets/images/products/cyber/cyber_man_shocked.webp')}
        resizeMode={'contain'}
        style={{
          width: isFullWeb() ? 448 : 350,
          height: 419,
          position: 'absolute',
          bottom: isFullWeb() ? 0 : 0,
        }}
      />
      <Footer>
        <WaffleText
          style={{
            fontSize: 18,
            textAlign: 'center',
          }}>
          1 out of 5 Americans has dealt with a ransomware attack.
        </WaffleText>
      </Footer>
    </DidYouKnowBox>
  );
};
