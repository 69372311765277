import ContentLoader from 'react-content-loader';
import React from 'react';

type LoadingRectangleProps = {
  width: number | string;
  height: number | string;
};

const LoadingRectangle = ({ width, height }: LoadingRectangleProps) => {
  return (
    <ContentLoader
      speed={2}
      width={width}
      height={typeof height === 'string' ? height : height + 5}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb">
      <rect x="0" y="5" rx="5" ry="5" width={width} height={height} />
    </ContentLoader>
  );
};

export default LoadingRectangle;
