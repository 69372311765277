import React, { useState } from 'react';
import {
  Animated,
  StyleProp,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import {
  WaffleOrange,
  WaffleOrangeDesaturated,
  White,
} from '../../Constants/Style';
import { Horizontal, VerticalAround } from '../SimpleLayouts';
import { priceFmt } from '../../Helper/MiscFunctions';
import {
  PolicyFrequency,
  PolicyShortFrequencyLabelRaw,
} from '../../Helper/PolicyHelper';
import { PlainView } from '../SimpleComponents';
import SpinnerBlack from '../SpinnerBlack';
import WaffleText, { WaffleTextFamily } from '../WaffleText';
import { NormalButtonWidth } from '../../Helper/DeviceHelper';
import styled from 'styled-components/native';

const AnimatedText = ({
  style,
  children,
}: {
  style?: StyleProp<TextStyle>;
  children: React.ReactNode;
}) => (
  <Animated.Text
    style={[
      {
        fontFamily: WaffleTextFamily,
        color: White,
        fontSize: 15,
        fontWeight: '500',
        textAlign: 'center',
      },
      style,
    ]}>
    {children}
  </Animated.Text>
);

type Props = {
  animatedTextStyle?: StyleProp<TextStyle>;
  isUpdating: boolean;
  onPress: () => Promise<void>;
  policyFrequency: PolicyFrequency;
  positionStyle?: Animated.AnimatedProps<StyleProp<ViewStyle>>;
  prefixPrice?: string;
  basePrice?: string;
  percentOff?: number;
  price: number;
  size: 'small' | 'large';
  testID?: string;
};

const largeButtonStyle: ViewStyle = {
  width: NormalButtonWidth(),
  borderRadius: 32,
  height: 152,
};

const smallButtonStyle: ViewStyle = {
  width: 312,
  height: 44,
  borderRadius: 5,
};

const PriceTag = styled.ImageBackground`
  z-index: 9999;
  width: 100px;
  transform: rotate(20.5deg);
  height: 50px;
  display: flex;
  right: -105px;
  bottom: -40px;
  position: relative;
`;

const PriceButtonExperiment = ({
  animatedTextStyle,
  basePrice,
  isUpdating,
  onPress,
  policyFrequency,
  positionStyle,
  percentOff,
  prefixPrice = '',
  price,
  size,
  testID,
}: Props) => {
  const [pressed, setPressed] = useState(false);
  const formattedPrice = `${prefixPrice}$${priceFmt(price)} `;

  const _onPress = async () => {
    if (!pressed) {
      setPressed(true);

      try {
        await onPress();
      } finally {
        setPressed(false);
      }
    }
  };

  const buttonSizeStyle =
    size === 'small' ? smallButtonStyle : largeButtonStyle;

  return (
    <>
      {basePrice ? (
        <PriceTag
          source={require('../../../assets/images/products/renters/price-tag.png')}>
          <WaffleText
            style={{
              fontSize: 14,
              textAlign: 'center',
              fontWeight: 'bold',
              left: 8,
              position: 'relative',
              top: 16,
            }}>
            {`${percentOff}% OFF*`}
          </WaffleText>
        </PriceTag>
      ) : null}
      <Animated.View
        testID="priceButton"
        style={[
          {
            shadowRadius: 32,
            borderRadius: 32,
            shadowOffset: { width: 0, height: 4 },
            shadowColor: 'rgba(108,62,12,0.5)',
            shadowOpacity: 1,
            elevation: 5,
            zIndex: 99,
            backgroundColor: White,
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // https://stackoverflow.com/questions/51521809/typescript-definitions-for-animated-views-style-prop
          positionStyle,
        ]}>
        <TouchableOpacity
          testID={testID}
          activeOpacity={0.5}
          onPress={_onPress}>
          <Animated.View
            style={[
              {
                justifyContent: 'center',
                backgroundColor: pressed
                  ? WaffleOrangeDesaturated
                  : WaffleOrange,
                opacity: isUpdating ? 0.5 : 1.0,
              },
              buttonSizeStyle,
            ]}>
            {basePrice ? (
              <AnimatedText
                style={[
                  {
                    fontSize: 22,
                    position: 'relative',
                    textAlign: 'center',
                    fontWeight: 'normal',
                    letterSpacing: 0.02,
                    color: White,
                    textDecorationLine: 'line-through',
                    top: -10,
                  },
                  animatedTextStyle,
                ]}>
                {`$${basePrice}`}
              </AnimatedText>
            ) : null}
            <Horizontal>
              <VerticalAround>
                <AnimatedText
                  style={[
                    { fontSize: 48, textAlign: 'center', color: White },
                    animatedTextStyle,
                  ]}>
                  {formattedPrice}
                  <AnimatedText style={[{ fontSize: 24 }, animatedTextStyle]}>
                    {PolicyShortFrequencyLabelRaw(policyFrequency)}
                  </AnimatedText>
                </AnimatedText>
              </VerticalAround>
              {(pressed || isUpdating) && (
                <PlainView style={{ position: 'absolute' }}>
                  <SpinnerBlack size={'small'} />
                </PlainView>
              )}
            </Horizontal>
          </Animated.View>
        </TouchableOpacity>
      </Animated.View>
    </>
  );
};

export default PriceButtonExperiment;
