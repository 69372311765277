import { useQuery, useApolloClient } from '@apollo/client';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import WaffleText from '../WaffleText';
import { Image, PlainView, Spacer } from '../SimpleComponents';
import styled from 'styled-components/native';
import {
  HorizontalPacked,
  HorizontalSpread,
  VerticalCenter,
  VerticalLeft,
} from '../SimpleLayouts';
import { WaffleDarkBlue, WaffleLightGrey, White } from '../../Constants/Style';
import { GET_LOCAL_USER } from '../../GraphQL/Waffle/Queries';
import { LocalUser } from '../../../../operation-result-types';
import { SuggestedCoverageContext } from '../../Screens/SuggestedCoverageScreen';
import { Policy, PolicyProps } from '../../../component-config';
import {
  COVERAGE_TRAVEL_CFAR,
  PolicyMidFrequencyLabel,
} from '../../Helper/PolicyHelper';
import { priceFmt } from '../../Helper/MiscFunctions';
import {
  NavigationHelpers,
  ParamListBase,
  useScrollToTop,
} from '@react-navigation/native';
import {
  Platform,
  ScrollView,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import { SelectedCoveragesAndExclusions } from './SelectedCoveragesAndExclusions';
import {
  addScrollTabToTopHook,
  Box,
  coverageItem,
  coverageValue,
  HeaderSpread,
  ItemLabel,
  ItemLabelOrangeLink,
  NormalizeAddOnName,
  PoweredByBlurb,
  SummarySectionWidth,
  Title,
  toggleAddOn,
  updateAnswerInputs,
  updateCoverageValue,
} from './SuggestedCoverageShared';
import { WaffleSwitch } from '../WaffleSwitch';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs';
import {
  TabNavigationProvider,
  useTabNavigation,
} from '../../TabNavigationProvider';
import moment from 'moment';
import { TripAnswersEditorPopup } from './TripAnswersEditorPopup';
import { isFullWeb, isNativeOrMobileWeb } from '../../Helper/DeviceHelper';
import { LinePlaceholder } from '../LinePlaceholder';
import { MaterialTopTabNavigationEventMap } from '@react-navigation/material-top-tabs/lib/typescript/src/types';

const SummaryBoxNative = styled(PlainView)`
  background-color: ${WaffleLightGrey};
  width: 320px;
  padding: 20px;
  border-radius: 10px;
  margin-top: ${isFullWeb() ? 0 : 20}px;
`;

const SummaryBoxWeb = styled(PlainView)`
  background-color: ${WaffleDarkBlue};
  padding: 20px;
  border-radius: 10px;
`;

const SummaryLabel = styled(WaffleText)`
  font-size: ${isFullWeb() ? 12 : 10}px;
  line-height: ${isFullWeb() ? 15 : 13}px;
  font-weight: ${isFullWeb() ? 400 : 500};
  text-transform: uppercase;
  color: ${isFullWeb() ? 'white' : 'rgba(5, 26, 58, 0.5)'};
`;

const Name = styled(WaffleText)`
  color: ${WaffleDarkBlue};
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
`;

const PriceText = styled(WaffleText)`
  font-size: ${isFullWeb() ? 24 : 18}px;
  font-weight: ${isFullWeb() ? 400 : 500};
  color: ${isFullWeb() ? White : WaffleDarkBlue};
`;

const FreqText = styled(WaffleText)`
  font-size: ${isFullWeb() ? 14 : 10}px;
  font-weight: ${isFullWeb() ? 400 : 500};
  color: ${isFullWeb() ? White : WaffleDarkBlue};
`;

const SummaryValue = styled(WaffleText)`
  font-size: ${isFullWeb() ? 24 : 16}px;
  font-weight: ${isFullWeb() ? 400 : 500};
  text-align: center;
  color: ${isFullWeb() ? White : WaffleDarkBlue};
`;

const ProductImage = {
  Travel: require('../../../assets/images/products/design-coverage/travel_product.webp'),
};

type HeaderProps = {
  productId: string;
  policy: Policy;
};

const EditMaybe = ({ policy }: HeaderProps) => {
  const client = useApolloClient();
  const [popup, setPopup] = useState(false);
  const { setState } = useContext(SuggestedCoverageContext);
  const answers = policy?.answerInputs;

  if (answers?.__typename === 'PolicyTripAnswer') {
    return (
      <>
        <Spacer x={1} />
        <TouchableOpacity onPress={() => setPopup(true)}>
          <Image
            source={require('../../../assets/images/products/design-coverage/policy_edit.webp')}
            style={{ width: 24, height: 24 }}
          />
          <TripAnswersEditorPopup
            initialState={answers}
            popup={popup}
            onDismiss={() => setPopup(false)}
            onSave={async (trip) =>
              await updateAnswerInputs(client, policy.id, trip, setState)
            }
          />
        </TouchableOpacity>
      </>
    );
  }

  return null;
};

const Header = ({ productId, policy }: HeaderProps) => {
  const {
    data: { user },
  } = useQuery<LocalUser>(GET_LOCAL_USER);
  const planName = user?.firstName ? `${user.firstName}'s ` : ``;

  return (
    <HorizontalPacked style={{ width: 335 }}>
      <Image
        source={ProductImage[productId]}
        style={{ width: 90, height: 90 }}
      />

      <Spacer x={2.5} />

      <VerticalLeft>
        <Name style={{ width: 240 }}>
          {`${planName}${productId} Plan`}
          <EditMaybe productId={productId} policy={policy} />
        </Name>

        <Spacer y={2} />

        <PoweredByBlurb productId={productId} />
      </VerticalLeft>
    </HorizontalPacked>
  );
};

type SummaryBoxProps = {
  children: ReactNode;
  style?: ViewStyle;
};

const SummaryBox = ({ children, style }: SummaryBoxProps) => {
  const isWeb = isFullWeb();
  const Box = isWeb ? SummaryBoxWeb : SummaryBoxNative;

  return (
    <Box style={[isWeb && { marginHorizontal: 40 }, style]}>{children}</Box>
  );
};

const RowItem = ({ children }: { children: ReactNode }) => (
  <View
    style={{
      width: SummarySectionWidth,
    }}>
    {children}
  </View>
);

type Props = {
  label: string;
  subLabel?: string;
  style?: ViewStyle;
  children: ReactNode;
};

const SummaryBoxItem = ({ label, subLabel, children, style }: Props) => (
  <VerticalCenter style={[{ width: '33%' }, style]}>
    <SummaryLabel>{label}</SummaryLabel>
    {subLabel && (
      <SummaryLabel style={{ position: 'absolute', top: 14 }}>
        {subLabel}
      </SummaryLabel>
    )}

    <Spacer y={isFullWeb() ? 4 : 2} />

    {children}
  </VerticalCenter>
);

const SummaryBoxPrice = ({ policy }: PolicyProps) => (
  <SummaryBoxItem label={'Price'} style={{ width: '33%' }}>
    {isFullWeb() ? (
      <PriceText>
        ${priceFmt(policy?.price)}
        <FreqText>{PolicyMidFrequencyLabel(policy)}</FreqText>
      </PriceText>
    ) : (
      <>
        <PriceText>${priceFmt(policy?.price)}</PriceText>
        <FreqText>{PolicyMidFrequencyLabel(policy)}</FreqText>
      </>
    )}
  </SummaryBoxItem>
);

const compactDisplayDateRange = (from: string, to: string): string => {
  const fromDate = moment(from);
  const toDate = moment(to);

  // Most compact date
  if (
    fromDate.month() === toDate.month() &&
    fromDate.year() === toDate.year()
  ) {
    return `${fromDate.format('MMM D')} - ${toDate.format('D, YYYY')}`;
  }

  // Slightly less compact
  if (fromDate.year() === toDate.year()) {
    return `${fromDate.format('MMM D')} - ${toDate.format('MMM D, YYYY')}`;
  }

  // Most chatty
  return `${fromDate.format('MMM D, YYYY')} - ${toDate.format('MMM D, YYYY')}`;
};

const SummaryValueTravelEditor = ({
  policy,
  children,
}: {
  policy: Policy;
  children: ReactNode;
}) => {
  const client = useApolloClient();
  const [popup, setPopup] = useState(false);
  const { setState } = useContext(SuggestedCoverageContext);
  const answers = policy?.answerInputs;

  if (answers?.__typename === 'PolicyTripAnswer') {
    return (
      <>
        <TouchableOpacity onPress={() => setPopup(true)}>
          <SummaryValue
            style={{
              textDecorationLine: 'underline',
              ...(Platform.OS === 'web' && { cursor: 'pointer' }),
            }}>
            {children}
          </SummaryValue>

          <TripAnswersEditorPopup
            initialState={answers}
            popup={popup}
            onDismiss={() => setPopup(false)}
            onSave={async (trip) =>
              await updateAnswerInputs(client, policy.id, trip, setState)
            }
          />
        </TouchableOpacity>
      </>
    );
  }

  return null;
};

const SummaryTravel = ({ policy }: PolicyProps) => {
  if (policy.answerInputs.__typename !== 'PolicyTripAnswer') {
    return null;
  }

  const { TripDate, ExpireDate } = policy.answerInputs;
  const cfarEligible =
    policy.answerInputs.__typename === 'PolicyTripAnswer' &&
    policy.answerInputs.CancelForAnyReasonEligible;
  const hasCfar =
    coverageValue(policy.coverage, COVERAGE_TRAVEL_CFAR) === 'true';
  const protectionPlan = cfarEligible && hasCfar ? 'CancelFlex' : 'Pro Plus';

  return (
    <SummaryBox>
      <HorizontalSpread style={{ alignItems: 'flex-start' }}>
        <SummaryBoxPrice policy={policy} />

        <SummaryBoxItem label={'Trip Date'}>
          <SummaryValueTravelEditor policy={policy}>
            {compactDisplayDateRange(TripDate, ExpireDate)}
          </SummaryValueTravelEditor>
        </SummaryBoxItem>

        <SummaryBoxItem label={'Protection Plan'}>
          <SummaryValue>{protectionPlan}</SummaryValue>
        </SummaryBoxItem>
      </HorizontalSpread>
    </SummaryBox>
  );
};

const Summary = ({ policy }: PolicyProps) => {
  switch (policy.productId) {
    case 'Travel':
      return <SummaryTravel policy={policy} />;

    default:
      return null;
  }
};

const UpgradeOptionsTravel = ({ policy }: PolicyProps) => {
  const client = useApolloClient();
  const { setState } = useContext(SuggestedCoverageContext);

  if (policy.productId !== 'Travel') {
    return null;
  }

  const cfarEligible =
    policy.answerInputs.__typename === 'PolicyTripAnswer' &&
    policy.answerInputs.CancelForAnyReasonEligible;
  const cfar = coverageItem(policy.coverage, COVERAGE_TRAVEL_CFAR);
  const hasCfar = cfar.coverageLimit === 'true';

  const toggleCfar = async () => {
    await updateCoverageValue(client, cfar.id, (!hasCfar).toString(), setState);
  };

  if (cfarEligible) {
    return (
      <>
        <HeaderSpread>
          <ItemLabel>Cancel for Any Reason</ItemLabel>

          <WaffleSwitch value={hasCfar} setValue={toggleCfar} />
        </HeaderSpread>

        <Spacer y={1.5} />
      </>
    );
  }

  return null;
};

const UpgradeOptionPlaceholders = (
  { width }: { width: number } // todo : have different number of placeholders based on product?
) => (
  <>
    <LinePlaceholder width={width} height={22} textHeight={12} />
    <Spacer y={1.5} />
    <LinePlaceholder width={width} height={22} textHeight={12} />
    <Spacer y={1.5} />
    <LinePlaceholder width={width} height={22} textHeight={12} />
  </>
);

const UpgradeOptions = ({ policy }: PolicyProps) => {
  const navigation = useTabNavigation();
  const client = useApolloClient();
  const { state, setState } = useContext(SuggestedCoverageContext);
  const Container = isFullWeb() ? RowItem : Box;

  return (
    <Container>
      <HeaderSpread>
        <Title>Upgrades</Title>

        <TouchableWithoutFeedback
          onPress={() => navigation.navigate('Customize')}>
          <ItemLabelOrangeLink>View more</ItemLabelOrangeLink>
        </TouchableWithoutFeedback>
      </HeaderSpread>

      <Spacer y={2.5} />

      <UpgradeOptionsTravel policy={policy} />

      {(!policy?.addOns || policy?.addOns?.length === 0) && (
        <UpgradeOptionPlaceholders width={250} />
      )}

      {policy?.addOns?.map((addOn, idx) => (
        <React.Fragment key={idx}>
          <HeaderSpread>
            <ItemLabel style={{ width: 250 }}>
              {NormalizeAddOnName[addOn.name] ?? addOn.name}
            </ItemLabel>

            <WaffleSwitch
              disabled={state.updating}
              value={addOn.selected}
              setValue={async (v) => {
                await toggleAddOn(client, policy.id, addOn.id, setState);
              }}
            />
          </HeaderSpread>

          <Spacer y={1.5} />
        </React.Fragment>
      ))}

      {isFullWeb() && <Spacer y={2.5} />}
    </Container>
  );
};

const SelectedPolicyExclusionsBox = ({ policy }: PolicyProps) => (
  <Box>
    <SelectedCoveragesAndExclusions policy={policy} />
  </Box>
);

const PolicySpecificItems = ({
  policy,
  navigation,
}: PolicyProps & {
  navigation: NavigationHelpers<
    ParamListBase,
    MaterialTopTabNavigationEventMap
  >;
}) => {
  if (policy.productId === 'Travel') {
    if (isFullWeb()) {
      return (
        <>
          <SelectedPolicyExclusionsBox policy={policy} />

          <VerticalCenter>
            <Spacer y={2.5} />
            <UpgradeOptions policy={policy} />
          </VerticalCenter>
        </>
      );
    } else {
      return (
        <>
          <SelectedPolicyExclusionsBox policy={policy} />

          <UpgradeOptions policy={policy} />
        </>
      );
    }
  }

  return null;
};

const SuggestedCoverageSummaryTab = ({
  navigation,
}: MaterialTopTabBarProps) => {
  const ref = React.useRef<ScrollView>(null);
  const { state } = useContext(SuggestedCoverageContext);
  const policy = state.currentPolicy;

  useScrollToTop(ref);

  useEffect(() => {
    navigation.navigate('Summary');
    ref?.current?.scrollTo({ x: 0, y: 0, animated: false });
  }, [state.tab]);
  useEffect(() => {
    if (ref?.current) {
      addScrollTabToTopHook(navigation, ref);
    }
  }, []);

  if (!policy) {
    return null;
  }

  return (
    <TabNavigationProvider navigation={navigation}>
      <ScrollView
        ref={ref}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}>
        <Spacer y={isFullWeb() ? 3.5 : 2} x={2} />
        <View
          style={{
            flexDirection: 'row',
            flex: 1,
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}>
          {isNativeOrMobileWeb() && (
            <View style={{ width: 320 }}>
              <Header productId={policy?.productId} policy={policy} />
            </View>
          )}

          <View style={{ width: isFullWeb() ? '100%' : 320 }}>
            <Summary policy={policy} />
          </View>
        </View>

        <Spacer y={2} x={1} />

        <PolicySpecificItems policy={policy} navigation={navigation} />
      </ScrollView>
    </TabNavigationProvider>
  );
};

export default SuggestedCoverageSummaryTab;
