import React from 'react';
import styled from 'styled-components/native';
import WaffleText from './WaffleText';
import { WaffleLightGrey, WaffleOrange } from '../Constants/Style';
import { ViewStyle } from 'react-native';

const View = styled.View`
  height: 15px;
  width: 142px;
  border-radius: 4px;
  background-color: ${WaffleLightGrey};
`;
//   box-shadow: inset 0 3px 6px 0 rgba(0,0,0,0.08);

const Progress = styled.View`
  height: 15px;
  width: 35px;
  border-radius: 4px;
  background-color: #85df83;
  justify-content: center;
  align-items: flex-end;
`;

const Text = styled(WaffleText)`
  height: 15px;
  color: #fff;
  font-size: 10px;
  line-height: 13px;
  /* */
  margin-left: 4px;
  margin-top: 2px;
  margin-right: 5px;
`;

type ProgressBarProps = {
  percent: number;
  style?: ViewStyle;
  containerStyle?: ViewStyle;
};

const ProgressBar = ({ percent, style, containerStyle }: ProgressBarProps) => {
  const width = `${percent}%`;

  return (
    <View style={containerStyle}>
      <Progress style={[{ width }, style]}>
        <Text>{percent}%</Text>
      </Progress>
    </View>
  );
};

export default ProgressBar;
