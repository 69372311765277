import Popup from '../Popup';
import { HorizontalPacked, VerticalCenter } from '../SimpleLayouts';
import WaffleText from '../WaffleText';
import { WaffleButtonAsync } from '../WaffleButtonAsync';
import React from 'react';
import {
  GetProducts_products,
  GetProducts_products_eligibility,
} from '../../../../operation-result-types';
import { Spacer } from '../SimpleComponents';

const notAvailableText = (
  product: GetProducts_products,
  exclusiveSelectedProduct: string | undefined
) => {
  if (exclusiveSelectedProduct && product.id !== exclusiveSelectedProduct) {
    return `${product.id} can be purchased at any time, but not selected at the same time as other products.`;
  }

  return "Unfortunately, the product isn't available in your state yet. Check back soon.";
};

type Props = {
  eligibility: GetProducts_products_eligibility;
  disabled: boolean;
  visible: boolean;
  onContinue: () => Promise<void>;
  onDismiss: () => Promise<void>;
  exclusiveSelectedProduct: string | undefined;
  product: GetProducts_products;
};

const ProductUnavailablePopup = ({
  eligibility,
  disabled,
  visible,
  onContinue,
  onDismiss,
  exclusiveSelectedProduct,
  product,
}: Props) => {
  if (disabled) {
    return (
      <Popup visible={visible} onDismiss={onDismiss}>
        <VerticalCenter style={{ marginTop: 20 }}>
          <WaffleText style={{ maxWidth: 225, fontSize: 18 }}>
            {eligibility?.message ||
              notAvailableText(product, exclusiveSelectedProduct)}
          </WaffleText>
        </VerticalCenter>
        <VerticalCenter>
          <WaffleButtonAsync
            onPress={onDismiss}
            name={'Ok'}
            style={{ marginTop: 15, width: 100 }}
          />
        </VerticalCenter>
      </Popup>
    );
  }

  if (eligibility?.warning) {
    return (
      <Popup visible={visible} onDismiss={onDismiss}>
        <VerticalCenter style={{ marginTop: 20 }}>
          <WaffleText
            style={{ maxWidth: 225, fontSize: 18, textAlign: 'center' }}>
            {eligibility.warning}
          </WaffleText>
        </VerticalCenter>
        <VerticalCenter>
          <HorizontalPacked style={{ marginTop: 15 }}>
            <WaffleButtonAsync
              onPress={onContinue}
              name={'Ok'}
              style={{ width: 100 }}
            />
            <Spacer x={1} />
            <WaffleButtonAsync
              onPress={onDismiss}
              name={'Cancel'}
              style={{ width: 100 }}
            />
          </HorizontalPacked>
        </VerticalCenter>
      </Popup>
    );
  }

  return null;
};

export { ProductUnavailablePopup };
