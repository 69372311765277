import React from 'react';
import styled from 'styled-components/native';
import Disclosure from './Disclosure';
import { Spacer } from './SimpleComponents';

const Container = styled.View`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

const WaffleDisclosureContent =
  'Waffle, with offices located at 43 West 23rd Street, New York, New York 10010, USA is a licensed insurance producer and not an insurer. Waffle operates through Waffle Labs Inc (National Producer Number: 19499260). Waffle does business in California as Waffle Labs Insurance Agency.';
const DisclosureContent =
  'Ladder Insurance Services, LLC (CA license # 0K22568; AR license # 3000140372) offers term life insurance policies: (i) in New York, on behalf of Allianz Life Insurance Company of New York, New York, NY (policy form # MN-26); and (ii) in all other states and the District of Columbia on behalf of Allianz Life Insurance Company of North America, Minneapolis, MN (policy form # ICC20P-AZ100 and # P-AZ100). Only Allianz Life Insurance Company of New York is authorized to issue life insurance in the state of New York. Insurance policy prices, coverages, features, terms, benefits, exclusions, limitations and available discounts vary between these insurers and are subject to qualifications. Each insurer is solely responsible for any claims and has financial responsibility for its own products.';

export default function LifeDisclosure(): React.ReactElement {
  return (
    <Container>
      <Disclosure>{WaffleDisclosureContent}</Disclosure>
      <Spacer y={2} />
      <Disclosure>{DisclosureContent}</Disclosure>
    </Container>
  );
}
