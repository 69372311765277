import React from 'react';
import styled from 'styled-components/native';
import WaffleText, { WaffleTextFamily } from '../WaffleText';
import { Image } from '../SimpleComponents';
import { PaymentMethodCard } from '../../Helper/PaymentHelper.generic';

const CardNumberText = styled(WaffleText)`
  height: 26px;
  color: #4c4c4c;
  font-family: ${WaffleTextFamily};
  font-size: 17px;
  line-height: 26px;
`;

const CardImage = styled.Image`
  width: 64px;
  height: 40px;
  border-radius: 5px;
`;

const Horizontal = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ButtonText = styled(WaffleText)`
  color: #4297e7;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  text-align: center;
`;

const CardLogo = ({ brand }: any) => {
  if (brand === 'visa') {
    return (
      <CardImage
        source={require('../../../assets/elements/card-visa-dark.png')}
      />
    );
  }

  if (brand === 'mastercard') {
    return (
      <CardImage
        source={require('../../../assets/elements/card-mastercard-dark.png')}
      />
    );
  }

  if (brand === 'amex') {
    return (
      <CardImage
        source={require('../../../assets/elements/card-amex-dark.png')}
      />
    );
  }

  if (brand === 'discover') {
    return (
      <CardImage
        source={require('../../../assets/elements/card-discover-dark.png')}
      />
    );
  }

  if (brand === 'jcb') {
    return (
      <CardImage
        source={require('../../../assets/elements/card-jcb-dark.png')}
      />
    );
  }

  if (brand === 'diners') {
    return (
      <CardImage
        source={require('../../../assets/elements/card-diners-dark.png')}
      />
    );
  }

  return <WaffleText>{brand}</WaffleText>;
};

export const CardButton = ({ card }: { card: PaymentMethodCard }) => {
  const last4 = card?.last4;
  const brand = card?.brand;

  if (last4) {
    return (
      <Horizontal
        style={{
          width: '100%',
          paddingLeft: 12,
          paddingRight: 16,
          backgroundColor: '#4297E7',
          borderRadius: 7,
        }}>
        <CardLogo brand={brand} />

        <CardNumberText>•••• •••• •••• {last4}</CardNumberText>

        <Image
          style={{ width: 8, height: 4 }}
          source={require('../../../assets/elements/card-checked.png')}
        />
      </Horizontal>
    );
  } else {
    return <ButtonText>+ Add a new Card</ButtonText>;
  }
};
