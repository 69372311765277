import React from 'react';
import { ActionButtonImpl } from './ActionButtonImpl';
import { ContactCrumForster } from './ContactCrumForster';
import { ContactArch } from './ContactArch';
import { PickDate } from './PickDate';
import { PickDateRange } from './PickDateRange';
import { PetAgeChooser } from './PetAgeChooser';
import { StateChooser } from './StateChooser';
import { CountryChooser } from './CountryChooser';
import { AddressChooser } from './AddressChooser';
import { PetBreedChooser } from './PetBreedChooser';
import { HealthCategoryChooser } from './HealthCategoryChooser';
import { HeightPicker } from './HeightPicker';
import { Tomorrow } from '../../Helper/DateHelper';
import { ContactMarkel } from './ContactMarkel';
import { ContactChubb } from './ContactChubb';
import { useNavigation } from '@react-navigation/native';
import { HomeScreenName } from '../../../screen-config';
import moment from 'moment';
import { ContactLadder } from './ContactLadder';

export type ActionButtonCallback = () => Promise<void>;

export type ActionButtonProps = {
  action: string;
  doComplete: ActionButtonCallback;
  doUploadPhoto?: ActionButtonCallback;
  doUploadVideo?: ActionButtonCallback;
  doSign?: ActionButtonCallback;
  disabled: boolean;
  onPicker: ActionButtonCallback;
};

export const ActionButton = ({
  // todo : simplify the below!
  action,
  doComplete,
  disabled,
  onPicker,
}: ActionButtonProps) => {
  const completable = { doComplete, disabled };
  const choosable = { onChange: onPicker, disabled };
  const navigation = useNavigation();

  if (!action) {
    return null;
  }

  switch (action) {
    case 'CONTACT_CRUMFORSTER':
      return <ContactCrumForster {...completable} />;
    case 'CONTACT_ARCH':
      return <ContactArch {...completable} />;
    case 'CONTACT_MARKEL':
      return <ContactMarkel {...completable} />;
    case 'CONTACT_LADDER':
      return <ContactLadder {...completable} />;
    case 'CONTACT_CHUBB':
      return <ContactChubb {...completable} />;

    case 'PET_BREED_CHOOSER':
      return <PetBreedChooser {...choosable} />;
    case 'STATE_CHOOSER':
      return <StateChooser {...choosable} />;
    case 'COUNTRY_CHOOSER':
      return <CountryChooser {...choosable} />;
    case 'ADDRESS_PICKER':
      return <AddressChooser {...choosable} />;
    case 'PET_AGE_CHOOSER':
      return <PetAgeChooser {...choosable} />;

    case 'HEIGHT_PICKER':
      return <HeightPicker {...choosable} />;
    case 'HEALTH_CATEGORY_PICKER':
      return <HealthCategoryChooser {...choosable} />;
  }

  if (action === 'COMPLETE') {
    return (
      <ActionButtonImpl
        name={'Complete chat'}
        onPress={async () => {
          await navigation.navigate(HomeScreenName);
          doComplete && (await doComplete());
        }}
        disabled={disabled}
      />
    );
  }

  if (action === 'FUTURE_DATE_PICKER') {
    return (
      <PickDate
        onChange={onPicker}
        disabled={disabled}
        minimumDate={Tomorrow()}
      />
    );
  }

  if (action === 'FUTURE_DATE_RANGE_PICKER') {
    return (
      <PickDateRange
        title={'Enter start and end date'}
        onChange={onPicker}
        disabled={disabled}
        minimumDate={Tomorrow()}
        minDate={Tomorrow()}
      />
    );
  }

  if (action === 'PAST_DATE_PICKER') {
    return (
      <PickDate
        onChange={onPicker}
        disabled={disabled}
        maximumDate={new Date()}
      />
    );
  }

  // Functions
  if (action.startsWith('datePicker(')) {
    const result = action.match(/datePicker\((.*),(.*)\)/);

    if (result) {
      return (
        <PickDate
          onChange={onPicker}
          disabled={disabled}
          minimumDate={moment(result[1]).toDate()}
          maximumDate={moment(result[2]).toDate()}
        />
      );
    }
  }

  if (action.startsWith('dateRangePicker(')) {
    // We ignore future date for now, since widget doesn't support it
    const result = action.match(/dateRangePicker\((.*),(.*)\)/);

    if (result) {
      return (
        <PickDateRange
          title={'Enter start and end date'}
          onChange={onPicker}
          disabled={disabled}
          minimumDate={moment(result[1]).toDate()}
          minDate={moment(result[1]).toDate()}
          //maximumDate={moment(result[2]).toDate()}
        />
      );
    }
  }

  if (action.startsWith('addressPicker(')) {
    const result = action.match(/addressPicker\((.*)\)/);

    if (result) {
      return (
        <AddressChooser
          onChange={onPicker}
          disabled={disabled}
          state={result[1]}
        />
      );
    }
  }

  return null;
};
