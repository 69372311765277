import { useQuery } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { SuggestedCoverageContext } from '../../Screens/SuggestedCoverageScreen';
import { HighlightsBox, RestrictionsBox } from './HighlightsAndRestrictions';
import WaffleText from '../WaffleText';
import styled from 'styled-components/native';
import {
  WaffleDarkBlue,
  WaffleMediumGreen,
  WaffleMediumRed,
} from '../../Constants/Style';
import { VerticalCenter } from '../SimpleLayouts';
import { Spacer } from '../SimpleComponents';
import {
  NavigationHelpers,
  ParamListBase,
  useScrollToTop,
} from '@react-navigation/native';
import { ScrollView, TouchableWithoutFeedback, View } from 'react-native';
import { TabNavigationProvider } from '../../TabNavigationProvider';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs';
import {
  addScrollTabToTopHook,
  GetFullPolicy,
  ItemLabel,
  ItemLabelOrangeLink,
} from './SuggestedCoverageShared';
import { ShowWebLink } from '../WebViewPopupLink';
import { GET_LOCAL_USER } from '../../GraphQL/Waffle/Queries';
import { LocalUser } from '../../../../operation-result-types';
import { MaterialTopTabNavigationEventMap } from '@react-navigation/material-top-tabs/lib/typescript/src/types';

const Header = styled(WaffleText)`
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: ${WaffleDarkBlue};
`;

const HeaderGreen = styled(Header)`
  color: ${WaffleMediumGreen};
`;

const HeaderRed = styled(Header)`
  color: ${WaffleMediumRed};
`;

export const SuggestedCoverageCoverageInfo = ({
  navigation,
}: {
  navigation: NavigationHelpers<
    ParamListBase,
    MaterialTopTabNavigationEventMap
  >;
}) => {
  const { state } = useContext(SuggestedCoverageContext);
  const {
    data: { user },
  } = useQuery<LocalUser>(GET_LOCAL_USER);
  const policy = state.currentPolicy;
  const highlights = policy?.product?.suggestedCoveragePlanHighlights;
  const restrictions = policy?.product?.suggestedCoverageExampleRestrictions;
  const fullPolicy = GetFullPolicy(user, policy?.productId);

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}>
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            width: 380,
          }}>
          {highlights?.length > 0 && (
            <>
              <Spacer y={5} />
              <Header>
                Coverage <HeaderGreen>Highlights</HeaderGreen>
              </Header>

              <Spacer y={5} />

              <HighlightsBox
                productId={policy.productId}
                highlights={highlights}
              />
            </>
          )}
        </View>

        {restrictions?.length > 0 && (
          <View
            style={{
              flexDirection: 'column',
              alignItems: 'center',
              width: 380,
            }}>
            <Spacer y={5} />
            <Header>
              Coverage <HeaderRed>Restrictions</HeaderRed>
            </Header>

            <Spacer y={5} />

            <RestrictionsBox
              productId={policy.productId}
              restrictions={restrictions}
            />
          </View>
        )}
      </View>

      <Spacer y={5} />

      {fullPolicy && (
        <>
          <TouchableWithoutFeedback
            onPress={() => ShowWebLink(navigation, fullPolicy)}>
            <ItemLabel>
              Read details about the{' '}
              <ItemLabelOrangeLink>full policy</ItemLabelOrangeLink>
            </ItemLabel>
          </TouchableWithoutFeedback>

          <Spacer y={5} />
        </>
      )}
    </>
  );
};

const SuggestedCoverageCoverageTab = ({
  navigation,
}: MaterialTopTabBarProps) => {
  const ref = React.useRef<ScrollView>(null);
  const { state } = useContext(SuggestedCoverageContext);

  useScrollToTop(ref);

  useEffect(
    () => ref?.current?.scrollTo({ x: 0, y: 0, animated: false }),
    [state.tab]
  );
  useEffect(() => {
    if (ref?.current) {
      addScrollTabToTopHook(navigation, ref);
    }
  }, []);

  return (
    <TabNavigationProvider navigation={navigation}>
      <ScrollView
        ref={ref}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}>
        <VerticalCenter>
          <SuggestedCoverageCoverageInfo navigation={navigation} />
        </VerticalCenter>
      </ScrollView>
    </TabNavigationProvider>
  );
};

export default SuggestedCoverageCoverageTab;
