import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {
  WaffleDarkBlue,
  WaffleLightGrey,
  WaffleOrange,
  WaffleOrangeDesaturated,
} from '../../Constants/Style';
import React from 'react';
import styled from 'styled-components/native';
import WaffleText from '../WaffleText';
import { Spacer } from '../SimpleComponents';
import { ViewStyle } from 'react-native';

type CounterProps = {
  label: string;
  description?: string;
  onNext: () => Promise<void>;
  onPrev: () => Promise<void>;
  updating?: boolean;
  color?: string;
  updatingColor?: string;
  containerStyle?: ViewStyle;
  invert?: boolean;
};

const CounterView = styled.View`
  width: 307px;
  height: 60px;
  border-radius: 100px;
  padding: 10px;
  border-width: 1px;
  border-color: ${WaffleLightGrey};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CounterText = styled(WaffleText)`
  font-size: 24px;
  font-weight: 500;
  color: ${WaffleDarkBlue};
`;

const Description = styled(WaffleText)`
  font-size: 12px;
  line-height: 17px;
  font-weight: 400;
  text-align: center;
  color: #88847e;
`;

export const Counter = ({
  label,
  description,
  onNext,
  onPrev,
  updating,
  containerStyle,
  color = WaffleOrange,
  updatingColor = WaffleOrangeDesaturated,
  invert = false,
}: CounterProps) => (
  <>
    <CounterView style={containerStyle}>
      <Icon
        name={invert ? 'minus-circle-outline' : 'minus-circle'}
        size={38}
        color={updating ? updatingColor : color}
        onPress={onPrev}
      />

      <CounterText style={{ opacity: updating ? 0.5 : 1.0 }}>
        {label}
      </CounterText>

      <Icon
        name={invert ? 'plus-circle-outline' : 'plus-circle'}
        size={38}
        color={updating ? updatingColor : color}
        onPress={onNext}
      />
    </CounterView>

    {description && (
      <>
        <Spacer y={2.5} />

        <Description>{description}</Description>
      </>
    )}
  </>
);
