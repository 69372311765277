import WaffleText from '../WaffleText';
import React from 'react';

type Props = {
  children: string;
};

export default function CoverageCardTitle({ children }: Props) {
  return (
    <WaffleText
      style={{
        fontSize: 28,
        fontWeight: '500',
        textAlign: 'center',
      }}>
      {children}
    </WaffleText>
  );
}
