import React, { useState } from 'react';
import Carousel, { Pagination } from 'react-native-snap-carousel';
import { HorizontalEvenly, VerticalCenter } from '../../SimpleLayouts';
import WaffleText from '../../WaffleText';
import { WaffleOrange, WindowWidth } from '../../../Constants/Style';
import { PlainView } from '../../SimpleComponents';
import { isFullWeb } from '../../../Helper/DeviceHelper';
import { Platform, ViewStyle } from 'react-native';

type Props = {
  Cards: React.ReactElement[];
};

type ChildrenProps = {
  containerStyle?: ViewStyle;
  children: React.ReactNode;
};

export function Footer({ children }: ChildrenProps) {
  // @ts-ignore
  // @ts-ignore
  return (
    <VerticalCenter
      style={{
        width: isFullWeb() ? 500 : 275,
        minHeight: 60,
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#E5E5E5',
        padding: 12,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
      }}>
      {Platform.OS === 'web' ? (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <HorizontalEvenly style={{ width: '100%', flexWrap: 'wrap', gap: 8 }}>
          {children}
        </HorizontalEvenly>
      ) : (
        <HorizontalEvenly style={{ width: '100%', flexWrap: 'wrap' }}>
          {children}
        </HorizontalEvenly>
      )}
    </VerticalCenter>
  );
}

export function DidYouKnowBox({ children, containerStyle }: ChildrenProps) {
  return (
    <PlainView
      style={{
        paddingTop: 100,
        backgroundColor: 'white',
      }}>
      <VerticalCenter
        style={{
          width: isFullWeb() ? 500 : 275,
          height: isFullWeb() ? 320 : 335,
          borderRadius: 20,
          backgroundColor: '#58C4ED',
          marginBottom: 30,
          marginLeft: 10,
          marginRight: 10,
          boxShadow: '0px 4px 7px -2px rgba(0, 0, 0, 0.33)',
          ...containerStyle,
        }}>
        {children}
      </VerticalCenter>
    </PlainView>
  );
}

export default function DidYouKnowItems({ Cards }: Props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [ref, setRef] = useState<Carousel<any>>(null);

  function renderItem({
    index,
  }: {
    item: any;
    index: number;
  }): React.ReactElement {
    return Cards[index];
  }

  return (
    <VerticalCenter style={{ height: Platform.OS === 'ios' ? 500 : undefined }}>
      <WaffleText
        style={{
          fontSize: 24,
          fontWeight: '700',
        }}>
        Did you know...?
      </WaffleText>

      <Carousel
        sliderWidth={isFullWeb() ? 600 : WindowWidth()} // kind of hacky
        itemWidth={isFullWeb() ? 500 : 275}
        data={Cards}
        ref={(r) => setRef(r)}
        inactiveSlideOpacity={0.3}
        inactiveSlideScale={0.9}
        useScrollView={true}
        activeAnimationType={'spring'}
        autoplay={true}
        autoplayDelay={3000}
        autoplayInterval={10000}
        lockScrollWhileSnapping={true}
        renderItem={renderItem}
        onSnapToItem={(idx) => setCurrentIndex(idx)}
      />

      {isFullWeb() && (
        <Pagination
          dotsLength={Cards.length}
          activeDotIndex={currentIndex}
          inactiveDotColor={'#E3E5E5'}
          carouselRef={ref}
          tappableDots={!!ref}
          dotColor={WaffleOrange}
          dotStyle={{
            width: 12,
            height: 12,
            borderRadius: 6,
          }}
          inactiveDotStyle={{
            width: 12,
            height: 12,
            borderRadius: 6,
          }}
          inactiveDotScale={1.0}
        />
      )}
    </VerticalCenter>
  );
}
