import { StackNavigationProp } from '@react-navigation/stack';
import { RootStackParamList } from '../../../screen-config';
import { useLayoutEffect } from 'react';
import { DefaultHeaderStyle } from '../../../header-style';
import NavHeaderTitle from './NavHeaderTitle';
import NavHeaderRight from './NavHeaderRight';

export function useWaffleNavigationHeader(
  navigation: StackNavigationProp<RootStackParamList>
) {
  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Waffle',
      headerTitle: NavHeaderTitle,
      headerRight: NavHeaderRight,
      headerLeft: null,
      headerStyle: {
        ...DefaultHeaderStyle,
        backgroundColor: 'white',
      },
      gestureEnabled: false,
    });
  }, [navigation]);
}
