import React from 'react';
import { WaffleLightGrey } from '../../Constants/Style';
import styled from 'styled-components';
import { CoverageCardProps } from './types';

const grayBackgroundDropShadow = '0px 4px 7px -2px rgba(0, 0, 0, 0.25)';
const whiteBackgroundDropShadow = '0px 4px 28px -9px rgba(0, 0, 0, 0.25)';

const CardContainer = styled.div<CoverageCardProps>`
  ${(p) => (p.isFocused ? 'border: 4px solid rgba(250, 142, 70, 0.23)' : '')};
  background-color: ${(p) =>
    p.bgColor === 'gray' ? WaffleLightGrey : 'white'};
  min-width: 332px;
  max-width: 355px;
  min-height: 348px;
  border-radius: 28px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: ${(p) =>
    p.bgColor === 'gray'
      ? grayBackgroundDropShadow
      : whiteBackgroundDropShadow};
`;

export default CardContainer;
