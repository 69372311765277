import React, { useState } from 'react';

import { ActionButtonImpl } from './ActionButtonImpl';
import { LinkToArchPopup } from '../../Partners/LinkToArch';
import { LargeText } from '../WaffleText';
import { ClaimsComplete } from './ClaimsComplete';

type Props = {
  doComplete: () => Promise<void>;
  disabled: boolean;
};

export const ContactArch = ({ doComplete, disabled }: Props) => {
  const [popup, setPopup] = useState(false);

  return (
    <>
      <ActionButtonImpl
        name={'claims portal'}
        onPress={async () => await setPopup(true)}
        disabled={disabled}
      />

      <ClaimsComplete doComplete={doComplete} disabled={disabled} />

      <LinkToArchPopup
        popup={popup}
        setPopup={setPopup}
        doComplete={doComplete}>
        <LargeText style={{ marginVertical: 15, width: 275 }}>
          You will now be taken to our partner, Arch RoamRight, to submit your
          claim.
        </LargeText>
        <LargeText style={{ marginBottom: 15, width: 275 }}>
          If there are any issues, please use the Waffle chat and we will
          assist.
        </LargeText>
      </LinkToArchPopup>
    </>
  );
};
