import { isFullWeb } from '../../../../Helper/DeviceHelper';
import { Spacer } from '../../../SimpleComponents';
import React, { Fragment } from 'react';
import { Props } from '../../Shared/types';
import { Image, ImageSourcePropType, View } from 'react-native';
import WaffleText from '../../../WaffleText';
import { PetImages } from '../pet-images';
import styled from 'styled-components/native';
import { HorizontalPacked, VerticalPacked } from '../../../SimpleLayouts';
import { AspcaPetHealthInsurance } from '../AspcaPetHealthInsurance';
import { WindowWidth } from '../../../../Constants/Style';
import LearnMoreButton from '../../Shared/LearnMoreButton';
import BackToHomeButton from '../../Shared/BackToHomeButton';
import { WaffleLogo } from '../WaffleLogo';

const Container = ({ children }: { children: React.ReactNode }) => {
  return (
    <View
      style={{
        flexDirection: 'column',
        background: 'linear-gradient(274.42deg, #FFFB95 0%, #FADD46 121.85%)',
        flex: 1,
        display: 'flex',
        width: isFullWeb() ? 500 : WindowWidth(),
      }}>
      {children}
    </View>
  );
};

const ContentContainer = styled.View`
  border-top-left-radius: 60px;
  border-top-right-radius: 60px;
  width: 100%;
  background-color: white;
`;

const PetPersonImage = ({ imageSrc }: { imageSrc: ImageSourcePropType }) => (
  <Image
    source={imageSrc}
    resizeMode={'contain'}
    style={{
      bottom: -20,
      width: isFullWeb() ? 500 : '100%',
      height: isFullWeb() ? 445 : 270,
    }}
  />
);

export const PromoMobilePet = ({ onPress, onPromo }: Props) => {
  return (
    <Container>
      <Spacer y={2} />
      <View
        style={{
          justifyContent: 'center',
          flexDirection: 'row',
        }}>
        <WaffleLogo
          style={{
            width: 150,
            height: 35,
          }}
        />
      </View>
      <Spacer y={4} />
      <WaffleText style={{ fontSize: 30, textAlign: 'center' }}>
        Insure Your Best Friend!
      </WaffleText>

      <PetPersonImage imageSrc={PetImages.guyInOrangeSweater} />
      <ContentContainer>
        <VerticalPacked style={{ width: '100%' }}>
          <Spacer y={1} />
          <AspcaPetHealthInsurance />
          <Spacer y={1} />
          <WaffleText
            style={{ fontSize: 16, textAlign: 'center', fontWeight: 'bold' }}>
            Starting at
          </WaffleText>
          <Spacer y={1} />
          <WaffleText
            style={{ fontSize: 48, textAlign: 'center', fontWeight: 'bold' }}>
            $10/Month
          </WaffleText>
          <Spacer y={1} />
          <WaffleText
            style={{
              fontSize: 14,
              width: 360,
              textAlign: 'center',
            }}>
            Accidents, Illnesses, Dental Disease, Behavioral Issues, Alternative
            Therapies, and more! Insure your pet for things like:
          </WaffleText>
          <Spacer y={4} />
          <View
            style={{
              flexDirection: 'column',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}>
            {[
              'Stomach Issues',
              'Skin Conditions',
              'Ear Infections',
              'And More!',
            ].map((text) => (
              <Fragment key={text}>
                <HorizontalPacked>
                  <Image
                    source={PetImages.checkmark}
                    style={{
                      width: 28,
                      height: 28,
                    }}
                  />
                  <Spacer x={1} />
                  <WaffleText
                    style={{
                      fontSize: 16,
                      textAlign: 'center',
                      fontWeight: 'bold',
                    }}>
                    {text}
                  </WaffleText>
                </HorizontalPacked>
                <Spacer y={2} />
              </Fragment>
            ))}
          </View>
          <Spacer y={2} />
          <HorizontalPacked style={{ maxWidth: WindowWidth() }}>
            <BackToHomeButton
              onPress={onPress}
              label="No Thanks"
              style={{
                width: 150,
              }}
            />
            <Spacer x={2} />
            <LearnMoreButton
              onPromo={onPromo}
              style={{
                width: 150,
              }}
            />
          </HorizontalPacked>
          <Spacer y={2} />
        </VerticalPacked>
      </ContentContainer>
    </Container>
  );
};
