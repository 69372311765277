import { useQuery } from '@apollo/client';
import React, { useState } from 'react';

import {
  HasBillablePolicy,
  HasNonBillablePolicy,
} from '../../Helper/PolicyHelper';
import { SettingsButton } from './SettingsButton';
import { ShowInvoices } from '../../Helper/SettingsHelper';
import Popup from '../Popup';
import { PopupTitle } from '../WaffleText';
import { UserWarning } from '../DesignPolicy/UserWarning';
import { Spacer } from '../SimpleComponents';
import { WaffleButtonAsync } from '../WaffleButtonAsync';
import { GetTokenFromAsync } from '../../Helper/UserHelpers';
import { USER_POLICIES } from '../../GraphQL/Waffle/Queries';
import { GetFinalizedUserPolicies } from '../../../../operation-result-types';
import { useNavigation } from '@react-navigation/native';

const InvoiceWarning =
  'Invoices for Pet and Cyber can only be viewed through our ' +
  "Partners' portals. If there are any issues, please contact us through " +
  'the app’s chat function.';

export const InvoicesButton = ({
  label = 'Invoices',
  prompt = true,
  warning = InvoiceWarning,
  always = false,
}: {
  label?: string;
  prompt?: boolean;
  warning?: string;
  always?: boolean;
}) => {
  const [popup, setPopup] = useState(false);
  const { data } = useQuery<GetFinalizedUserPolicies>(USER_POLICIES, {
    fetchPolicy: 'cache-and-network',
  });
  const policies = data?.finalizedUserPolicies ?? [];
  const hasNonBillable = HasNonBillablePolicy(policies);
  const hasBillable = HasBillablePolicy(policies);
  const showPopup = hasNonBillable && prompt;
  const navigateThrough = hasBillable;
  const navigation = useNavigation();

  return (
    <>
      <SettingsButton
        name={label}
        style={{ backgroundColor: '#D1E9FF' }} // value details
        textStyle={{ color: '#000000' }}
        disabled={policies?.length === 0 && !always}
        onPress={async () => {
          if (showPopup) {
            setPopup(true);
          } else if (navigateThrough || always) {
            const token = await GetTokenFromAsync();
            await ShowInvoices(policies, token, navigation);
          }
        }}
      />

      <Popup visible={popup} onDismiss={() => setPopup(false)}>
        <PopupTitle>{label}</PopupTitle>

        <UserWarning style={{ textAlign: 'center' }}>{warning}</UserWarning>

        <Spacer y={3} />

        <WaffleButtonAsync
          name={'Ok'}
          onPress={async () => {
            setPopup(false);
            if (hasBillable) {
              const token = await GetTokenFromAsync();
              await ShowInvoices(policies, token, navigation);
            }
          }}
        />
      </Popup>
    </>
  );
};
