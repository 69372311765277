import React from 'react';
import styled from 'styled-components/native';

import WaffleText from '../WaffleText';
import styles from '../../Constants/Style';
import { TextStyle } from 'react-native';

const ProtectText = styled(WaffleText)`
  height: 26px;
  width: 228px;
  color: #4297e7;
  font-size: 18px;
  font-weight: 300;
  line-height: 26px;
  text-align: center;
`;

type Props = {
  selected: number;
  style?: TextStyle;
};

export const ProtectMessage = ({ selected, style }: Props) => {
  let msg = 'Select what you want to protect';

  if (selected === 1) {
    msg = `You selected ${selected} category`;
  }
  if (selected > 1) {
    msg = `You selected ${selected} categories`;
  }

  return <ProtectText style={[styles.fullWidth, style]}>{msg}</ProtectText>;
};
