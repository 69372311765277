import { useQuery } from '@apollo/client';
import React from 'react';
import styled from 'styled-components/native';

import WaffleText, { WaffleTextFamily } from '../WaffleText';
import { GET_LOCAL_USER } from '../../GraphQL/Waffle/Queries';
import {
  LargerHeaderNavHeight,
  WaffleDarkBlue,
  WaffleLightBlue,
  WaffleOrange,
  White,
} from '../../Constants/Style';
import { UserName } from '../../Helper/UserHelpers';
import { ListItem } from 'react-native-elements';
import { LocalUser } from '../../../../operation-result-types';

const HeaderView = styled.View`
  height: ${LargerHeaderNavHeight}px;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  padding-left: 20px;
  padding-bottom: 12px;
  background-color: ${WaffleOrange};
`;

const TitleName = styled(WaffleText)`
  font-size: 32px;
  font-weight: 400;
  line-height: 34px;
  color: #fff;
  margin-left: 9px;
`;

const HelloHeader = () => {
  const { data } = useQuery<LocalUser>(GET_LOCAL_USER);

  return (
    <HeaderView style={{ height: LargerHeaderNavHeight }}>
      <TitleName>{UserName(data?.user)}</TitleName>
    </HeaderView>
  );
};

const HomeButton = ({
  text,
  subtitle = undefined,
  onPress,
  height = 100,
  width = 355,
  containerStyle = {},
  color = WaffleLightBlue,
  subTitleColor = White,
  chevronColor = White,
}) => (
  <ListItem
    onPress={onPress}
    activeOpacity={0.5}
    underlayColor={White}
    disabledStyle={{ opacity: 0.25 }}
    containerStyle={{
      width,
      height,
      borderRadius: 10,
      backgroundColor: color,
      ...containerStyle,
    }}>
    <ListItem.Content
      style={{
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
      }}>
      <ListItem.Title
        style={{
          fontFamily: WaffleTextFamily,
          fontSize: 22,
          lineHeight: 27,
          color: WaffleDarkBlue,
        }}>
        {text}
      </ListItem.Title>
      {subtitle && (
        <ListItem.Subtitle
          style={{
            fontFamily: WaffleTextFamily,
            fontSize: 16,
            lineHeight: 20,
            color: subTitleColor,
          }}>
          {subtitle}
        </ListItem.Subtitle>
      )}
    </ListItem.Content>
    <ListItem.Chevron color={chevronColor} tvParallaxProperties={{}} />
  </ListItem>
);

export { HeaderView, HelloHeader, HomeButton };
