import { WaffleButtonAsync } from '../../WaffleButtonAsync';
import { White } from '../../../Constants/Style';
import React from 'react';
import { ViewStyle } from 'react-native';

type Props = {
  label?: string;
  onPress: () => Promise<any>;
  style?: ViewStyle;
};

export default function BackToHomeButton({
  onPress,
  label = 'Back to Home',
  style,
}: Props) {
  return (
    <WaffleButtonAsync
      testID="back-home-button"
      onPress={onPress}
      name={label}
      style={{ backgroundColor: '#919191', width: 249, ...style }}
      textStyle={{ color: White, textTransform: 'none', fontSize: 18 }}
    />
  );
}
