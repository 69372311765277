import React from 'react';

import { Horizontal } from '../SimpleLayouts';
import { MessageText } from './MessageText';
import { ClaimsCallButton } from './ClaimsCallButton';
import { ClaimsEmailButton } from './ClaimsEmailButton';
import { ClaimsComplete } from './ClaimsComplete';

// For Boost / Renters
export const ContactMarkel = ({ doComplete, disabled }: any) => (
  <>
    <ClaimsCallButton phone={'+1-800-236-3113'} disabled={disabled} />

    <Horizontal>
      <MessageText style={{ textAlign: 'center' }}>or</MessageText>
    </Horizontal>

    <ClaimsEmailButton email={'newclaims@markel.com'} disabled={disabled} />

    <ClaimsComplete doComplete={doComplete} disabled={disabled} />
  </>
);
