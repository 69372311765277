export type PetAge = {
  label: string;
  value: number;
};

export const PET_AGES = [
  { label: '8 weeks to 11 months', value: 0 },
  { label: '1 Year', value: 1 },
  { label: '2 Years', value: 2 },
  { label: '3 Years', value: 3 },
  { label: '4 Years', value: 4 },
  { label: '5 Years', value: 5 },
  { label: '6 Years', value: 6 },
  { label: '7 Years', value: 7 },
  { label: '8 Years', value: 8 },
  { label: '9 Years', value: 9 },
  { label: '10 Years', value: 10 },
  { label: '11 Years', value: 11 },
  { label: '12 Years', value: 12 },
  { label: '13 Years', value: 13 },
  { label: '14 Years', value: 14 },
  { label: '15 Years', value: 15 },
  { label: '16 Years', value: 16 },
  { label: '17 Years', value: 17 },
  { label: '18 Years', value: 18 },
];
