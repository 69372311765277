import { useApolloClient } from '@apollo/client';
import React, { useContext } from 'react';
import {
  Description,
  EditorAddOnProps,
  ItemLabelOrange,
  NormalizeAddOnName,
  toggleAddOn,
} from './SuggestedCoverageShared';
import { HorizontalSpread, VerticalCenter } from '../SimpleLayouts';
import { WaffleSwitch } from '../WaffleSwitch';
import { Image, Spacer } from '../SimpleComponents';
import WaffleText from '../WaffleText';
import { SuggestedCoverageContext } from '../../Screens/SuggestedCoverageScreen';
import { View } from 'react-native';

const Images = {
  Renters: {
    'Earthquake Protection': require('../../../assets/images/products/design-coverage/renters_earthquakes.webp'),
    'Replacement Cost': require('../../../assets/images/products/design-coverage/renters_replacement.webp'),
    'Theft Protection': require('../../../assets/images/products/design-coverage/renters_theft_protection.webp'),
  },
  Travel: {
    'Platinum Upgrade': require('../../../assets/images/products/design-coverage/travel_platinum.webp'),
    'Rental Car Damage Upgrade': require('../../../assets/images/products/design-coverage/travel_rental.webp'),
    'Sports & Hazardous Sports Upgrade': require('../../../assets/images/products/design-coverage/travel_sports.webp'),
    'Baggage, Sports & Business Equipment Upgrades': require('../../../assets/images/products/design-coverage/travel_baggage_sports.webp'),
    'AD&D Flight Accident': require('../../../assets/images/products/design-coverage/travel_add.webp'),
  },
};

type ImageMaybeProps = {
  productId: string;
  title: string;
};

const ImageMaybe = ({ productId, title }: ImageMaybeProps) => {
  const source = Images[productId]?.[title];

  if (source) {
    return <Image source={source} style={{ width: 90, height: 90 }} />;
  } else {
    return <WaffleText>img</WaffleText>;
  }
};

export const AddOnEditorWeb = ({
  productId,
  policyId,
  addOn,
}: EditorAddOnProps) => {
  const client = useApolloClient();
  const { state, setState } = useContext(SuggestedCoverageContext);

  return (
    <View
      style={[
        {
          flexDirection: 'row',
          flexWrap: 'wrap',
          width: '100%',
          justifyContent: 'center',
        },
      ]}>
      <View
        style={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 20,
          rowGap: 16,
          width: 440,
        }}>
        <ImageMaybe productId={productId} title={addOn.name} />
        <ItemLabelOrange>
          {NormalizeAddOnName[addOn.name] ?? addOn.name}
        </ItemLabelOrange>

        <Description>{addOn.explanation}</Description>
      </View>

      <View
        style={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 20,
          width: 440,
        }}>
        <WaffleSwitch
          disabled={state.updating}
          value={addOn.selected}
          setValue={async (v) =>
            await toggleAddOn(client, policyId, addOn.id, setState)
          }
        />
      </View>
    </View>
  );
};

export const AddOnEditor = ({
  productId,
  policyId,
  addOn,
}: EditorAddOnProps) => {
  const client = useApolloClient();
  const { state, setState } = useContext(SuggestedCoverageContext);
  const width = 335;

  return (
    <VerticalCenter>
      <Spacer y={4.25} />

      <ImageMaybe productId={productId} title={addOn.name} />

      <Spacer y={3} />

      <HorizontalSpread style={{ width }}>
        <ItemLabelOrange>
          {NormalizeAddOnName[addOn.name] ?? addOn.name}
        </ItemLabelOrange>

        <WaffleSwitch
          disabled={state.updating}
          value={addOn.selected}
          setValue={async (v) =>
            await toggleAddOn(client, policyId, addOn.id, setState)
          }
        />
      </HorizontalSpread>

      <Spacer y={3} />

      <Description>{addOn.explanation}</Description>

      <Spacer y={4.25} />
    </VerticalCenter>
  );
};
