import React from 'react';
import WaffleTextInput from '../WaffleTextInput';
import * as yup from 'yup';
import { TextIconInput } from '../TextIconInput';

const phoneRegexp = /[^0-9-]/g;
const leadingOneRegexp = /^1+/;

export const PhoneValidator = yup.string().min(10).required();

const ensureFormat = (text) => {
  if (text) {
    // squelch non-digits
    text = text.replace(phoneRegexp, '');
    text = text.replace(leadingOneRegexp, '');

    // insert first dash if necessary
    if (text.length > 3 && text.charAt(3) !== '-') {
      text = text.substring(0, 3) + '-' + text.substring(3);
    }

    // insert second dash if necessary
    if (text.length > 7 && text.charAt(7) !== '-') {
      text = text.substring(0, 7) + '-' + text.substring(7);
    }

    // truncate at 12 characters (including the dashes)
    if (text.length > 11) {
      text = text.substring(0, 12);
    }
  }

  return text;
};

const PhoneInput = ({
  value,
  onChangeText,
  errorFooterText,
  onValidate,
  containerStyle,
  containerProps = {},
}) => (
  <WaffleTextInput
    label={'Phone Number'}
    placeholder={'Phone Number'}
    onChangeText={(text) => onChangeText(ensureFormat(text))}
    containerStyle={containerStyle}
    containerProps={containerProps}
    otherProps={{
      autoCompleteType: 'tel',
      keyboardType: 'number-pad',
      textContentType: 'telephoneNumber',
    }}
    value={value}
    errorFooterText={errorFooterText}
    onValidate={onValidate}
  />
);

export const PhoneIconInput = ({
  value,
  onChangeText,
  errorFooterText,
  onValidate,
  containerStyle,
  leftIcon,
  containerProps = {},
}) => (
  <TextIconInput
    placeholder={'Phone Number'}
    onChangeText={(text) => onChangeText(ensureFormat(text))}
    containerStyle={containerStyle}
    containerProps={containerProps}
    otherProps={{
      autoCompleteType: 'tel',
      keyboardType: 'number-pad',
      textContentType: 'telephoneNumber',
    }}
    leftIcon={leftIcon}
    value={value}
    errorFooterText={errorFooterText}
    onValidate={onValidate}
  />
);

export default PhoneInput;
