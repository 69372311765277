import React from 'react';
import { Pressable, PressableProps, View } from 'react-native';
import styled from 'styled-components';
import { WaffleOrange } from '../Constants/Style';
import { Spacer } from './SimpleComponents';
type Props = {
  selected: boolean;
};

const Container = styled.div<{ isSelected: boolean }>`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 48px;
  border: ${(props) => (props.isSelected ? '3px' : '1px')} solid
    ${(props) => (props.isSelected ? WaffleOrange : '#e3e5e6')};
  background: #ffffff;
  border-radius: 8px;
`;

/**
 * Has flex-shrink: 0 to prevent from getting squished by siblings
 */
const RadioCircle = styled.div<{ isSelected: boolean }>`
  height: 19px;
  width: 19px;
  flex-shrink: 0;
  background: ${(props) => (props.isSelected ? WaffleOrange : '#ffffff')};
  border-radius: 50%;
  border-style: solid;
  border-width: 1px;
  border-color: #787878;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const RadioContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
`;
const RadioChildrenContent = styled.div`
  display: flex;
  align-items: inherit;
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

type withPressableProps = Props & PressableProps & React.RefAttributes<View>;
export const PressableRadio = (
  props: withPressableProps
): React.ReactElement => {
  const { selected, children, accessibilityRole, onPress } = props;

  return (
    <Container isSelected={selected}>
      <Pressable
        accessibilityRole={accessibilityRole ? accessibilityRole : 'radio'}
        onPress={onPress}
        style={{
          width: '100%',
          height: '100%',
        }}>
        <RadioContent>
          <Spacer x={2} />
          <RadioCircle isSelected={selected} />
          <Spacer x={1.5} />
          <RadioChildrenContent>{children}</RadioChildrenContent>
        </RadioContent>
      </Pressable>
    </Container>
  );
};
