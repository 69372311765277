import * as React from 'react';
import { AppRegistry } from 'react-native';
import App from './src/App';

AppRegistry.registerComponent('client', () => App);

AppRegistry.runApplication('client', {
  initialProps: {},
  // Mount the react-native app in the "root" div of index.html
  rootTag: document.getElementById('app-root'),
});
