import React from 'react';
import { Platform } from 'react-native';
import {
  Avatar,
  Bubble,
  Composer,
  ComposerProps,
  IMessage,
  Message,
  Send,
  SendProps,
} from 'react-native-gifted-chat';
import styled from 'styled-components/native';

import { FadeMaybeView } from '../AnimatedComponents';
import { ActionButton } from './ActionButton';
import { PlainView } from '../SimpleComponents';
import { WaffleDarkBlue, WaffleDarkGrey } from '../../Constants/Style';
import { WaffleTextFamily } from '../WaffleText';
import { IsSmallScreen } from '../../Helper/DeviceHelper';
import { cloneDeep } from 'lodash';

export const ChatBubbleWidth = IsSmallScreen() ? 250 : 275;

const WaffleBotBackground = '#D8D8D8';
const WaffleBotText = 'black';

const CustomerResponseBackground = '#F98D4B';
const CustomerResponseText = 'white';

const SendButton = styled.Image`
  width: 45px;
  height: 45px;
  margin-right: 6px;
`;

const messageStyle = {
  left: {
    marginTop: 10,
    marginLeft: 28,
  },
  right: {
    marginTop: 10,
    marginRight: 20,
  },
};

const SmallCurveLeftView = styled.View`
  bottom: 0px;
  left: 0px;
  position: absolute;
  background-color: ${WaffleBotBackground};
  width: 8px;
  height: 8px;
`;

const SmallCurveLeft = styled.View`
  width: 8px;
  height: 8px;
  border-bottom-right-radius: 8px;
  background-color: #fff;
  overflow: hidden;
`;

const SmallCurveRightView = styled.View`
  bottom: 0px;
  right: 0px;
  position: absolute;
  background-color: ${CustomerResponseBackground};
  width: 8px;
  height: 8px;
`;

const SmallCurveRight = styled.View`
  width: 8px;
  height: 8px;
  border-bottom-left-radius: 8px;
  background-color: #fff;
  overflow: hidden;
`;

export const processMessages = (messages: any) => {
  return (
    messages
      // filter(m => m.text || m.action).
      .map((m: any) => {
        const message = cloneDeep(m);
        if (m.user._id === 'waffle') {
          message.user.avatar = require('../../../assets/images/WaffleChatAvatar.png');
          if (
            typeof m.choices !== 'undefined' &&
            m.choices !== null &&
            m.choices !== '' &&
            m.choices.length > 0
          ) {
            message.quickReplies = {
              type: 'radio',
              keepIt: false,
              values: m.choices.split(',').map((t: any) => ({
                title: t.trim(),
                value: t.trim(),
              })),
            };
          }
        }

        return message;
      })
      .reverse()
  );
};

export const renderMessage = (props: any) => {
  const { doComplete, nextMessage, position, onPicker } = props;
  const lastMessage = !(nextMessage && nextMessage._id) && position === 'left';

  // Logger(`renderMessage: ${JSON.stringify(nextMessage)}`);

  return (
    <FadeMaybeView style={[messageStyle[position]]} shouldFade={lastMessage}>
      <Message {...props} />
      <ActionButton
        action={props.currentMessage.action}
        doComplete={doComplete}
        onPicker={onPicker}
        disabled={!lastMessage}
      />
    </FadeMaybeView>
  );
};

export const renderSend = (props: any) => (
  <Send {...props}>
    <PlainView>
      <SendButton
        testID="chat-screen-submit-button"
        source={require('../../../assets/elements/send_button.png')}
      />
    </PlainView>
  </Send>
);

export const renderAvatar = (props: any) => (
  <Avatar
    {...props}
    imageStyle={{
      left: {
        width: 32,
        height: 32,
        borderRadius: 0,
      },
    }}
  />
);

// export const renderMessageText = (props: any) => <MessageText
//   {...props}
//   />;

// Renders chat bubble, including non-quick-reply part of both
// sides of the conversation.
export const renderBubble = (props: any) => {
  const { position } = props;
  const rightTextStyle = {
    fontFamily: WaffleTextFamily,
    fontSize: 16,
    color: CustomerResponseText,
  };
  const rightWrapperStyle = {
    backgroundColor: CustomerResponseBackground,
    borderBottomRightRadius: 0,
    marginRight: 8,
  };

  /*
   * Note: care must be taken with the text style vs wrapper style.  Text style is
   *       an inline style on web, so padding and margin and problematic when there
   *       are multiple text lines.
   */
  if (props.currentMessage.quickReplies) {
    // For quick replies, we can render this a different color.  We don't, but
    // we used to.
    return (
      <Bubble
        {...props}
        textStyle={{
          left: {
            fontFamily: WaffleTextFamily,
            fontSize: 16,
            color: WaffleBotText,
            marginLeft: 0,
            marginRight: 0,
          },
          right: rightTextStyle,
        }}
        wrapperStyle={{
          left: {
            backgroundColor: WaffleBotBackground,
            marginLeft: 8,
            marginTop: 8,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            width: ChatBubbleWidth,
            padding: 16,
          },
          right: rightWrapperStyle,
        }}
      />
    );
  } else {
    // not quick replies, so normal rendering
    // Alert.alert(JSON.stringify(props));
    return (
      <PlainView style={{ flexShrink: 1 }}>
        {position === 'left' ? (
          <SmallCurveLeftView>
            <SmallCurveLeft />
          </SmallCurveLeftView>
        ) : null}
        <Bubble
          {...props}
          textStyle={{
            left: {
              fontFamily: WaffleTextFamily,
              fontSize: 16,
              color: WaffleBotText,
              padding: 0,
              marginLeft: 0,
              marginRight: 0,
            },
            right: rightTextStyle,
          }}
          linkStyle={{
            right: {
              color: CustomerResponseText,
            },
          }}
          wrapperStyle={{
            left: {
              backgroundColor: WaffleBotBackground,
              marginLeft: 8,
              marginTop: 8,
              padding: 16,
              borderBottomLeftRadius: 0,
              width: ChatBubbleWidth,
            },
            right: rightWrapperStyle,
          }}
        />
        {position === 'right' ? (
          <SmallCurveRightView>
            <SmallCurveRight />
          </SmallCurveRightView>
        ) : null}
      </PlainView>
    );
  }
};

export const renderBubbleSimple = (props: any) => {
  const { position } = props;
  const rightTextStyle = {
    fontFamily: WaffleTextFamily,
    fontSize: 16,
    color: '#000',
  };
  const rightWrapperStyle = {
    backgroundColor: '#EAF3FA',
  };

  if (props.currentMessage.quickReplies) {
    // In the case of quick replies, we render the question in white, and
    //   choices are orange.  Otherwise, question is on orange.
    return (
      <Bubble
        {...props}
        // containerStyle={{
        //   left: {
        //     width: 150,
        //   }
        // }}
        textStyle={{
          left: {
            fontFamily: WaffleTextFamily,
            fontSize: 16,
            color: '#4A4A4A',
            padding: 8,
          },
          right: rightTextStyle,
        }}
        wrapperStyle={{
          left: {
            backgroundColor: '#fff',
            marginLeft: 8,
            borderBottomLeftRadius: 0,
          },
          right: rightWrapperStyle,
        }}
      />
    );
  } else {
    // not quick replies, so normal rendering
    return (
      <PlainView>
        <Bubble
          {...props}
          textStyle={{
            left: {
              fontFamily: WaffleTextFamily,
              fontSize: 16,
              color: '#fff',
              // padding: 8,
            },
            right: rightTextStyle,
          }}
          timeTextStyle={{
            left: {
              fontFamily: WaffleTextFamily,
              color: '#fff',
            },
            right: {
              fontFamily: WaffleTextFamily,
              color: '#000',
            },
          }}
          tickStyle={{
            color: '#000',
          }}
          wrapperStyle={{
            left: {
              backgroundColor: WaffleDarkBlue,
              shadowColor: 'rgba(216,216,216,0.42)',
              shadowOffset: { width: 0, height: 7 },
              shadowOpacity: 0.75,
              marginLeft: 8,
              // borderBottomLeftRadius: 0,
            },
            right: rightWrapperStyle,
          }}
        />
      </PlainView>
    );
  }
};

// to submit when hitting return on web:
//   https://github.com/FaridSafi/react-native-gifted-chat/issues/1755
export const renderComposer = (
  props: ComposerProps & {
    // GiftedChat passes its props to all of its `render*()`
    onSend: SendProps<IMessage>['onSend'];
    text: SendProps<IMessage>['text'];
  }
) => (
  <Composer
    {...props}
    textInputStyle={{
      // Giftedchat appears to try to turn off outline, but it appears it doesn't work with Safari, so we just use this blunt instrument to shut it off.
      // We should figure out a way to fake this by changing primaryStyle.borderColor based on focus....
      ...Platform.select({
        web: {
          outline: 'none',
        },
      }),
    }}
    textInputProps={{
      ...props.textInputProps,
      // for enabling the Return key to send a message only on web
      blurOnSubmit: false,
      onSubmitEditing:
        Platform.OS === 'web'
          ? () => {
              if (props.text && props.onSend) {
                props.onSend({ text: props.text.trim() }, true);
              }
            }
          : undefined,
    }}
  />
);

export const myMessages = (messages: any, userId: any) =>
  messages?.filter((msg: any) => msg.partyId === userId) ?? [];

export const WaffleDefaultsChatStyle = {
  color: '#FFF',
  // InputToolbar.  Contains Actions, Composer, and Send button
  containerStyle: {
    borderTopColor: '#fff',
  },
  // view wrapper for input at bottom of chat (the InputToolbar)
  primaryStyle: {
    marginHorizontal: 22,
    paddingLeft: 15,
    paddingVertical: 6,
    borderRadius: 100,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: WaffleDarkGrey,
  },
  // TextInput / Composer properties
  placeholder: '',
  placeholderTextColor: '#A9A9A9',
  multiline: false,
  textInputStyle: {
    fontFamily: WaffleTextFamily,
    fontSize: 15,
    lineHeight: 21,
    backgroundColor: '#FFF',
  },
  // textInputProps to feed to TextInput
  // composerHeight: the height
  messageTextStyle: {
    fontFamily: WaffleTextFamily,
    fontSize: 15,
  },
  minInputToolbarHeight: 54,
  alignTop: Platform.OS !== 'web', // this seems to mess up things with web?
  inverted: false,
  alwaysShowSend: true,
  listViewProps: {
    showsVerticalScrollIndicator: false,
    showsHorizontalScrollIndicator: false,
  },
};
