import { StackHeaderLeftButtonProps } from '@react-navigation/stack';
import { ChatHeaderButton } from '../ChatHeaderButton';
import React from 'react';
import { ResetToStart } from '../ResetToStart';

export default function NavHeaderRight(props: StackHeaderLeftButtonProps) {
  return (
    <>
      <ChatHeaderButton {...props} />
      <ResetToStart />
    </>
  );
}
