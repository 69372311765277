import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import SegmentedControl from '@react-native-community/segmented-control';
import styled from 'styled-components/native';

import { GET_BREEDS } from '../../GraphQL/Waffle/Queries';
import WaffleTextInput from '../WaffleTextInput';
import { ScrollView } from '../SimpleComponents';
import WaffleText from '../WaffleText';
import { IsSmallScreen } from '../../Helper/DeviceHelper';
import {
  GetBreeds,
  GetBreeds_petBreeds,
} from '../../../../operation-result-types';
import { PetBreeds } from '../../../component-config';

const SPECIES = ['Cat', 'Dog'];

const BreedView = styled.TouchableOpacity`
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #dedede;
  padding: 10px;
`;

type BreedItemProps = {
  breed: GetBreeds_petBreeds;
  onChoose: (any) => void;
};

const BreedItem = ({ breed, onChoose }: BreedItemProps) => (
  <BreedView style={{ marginTop: 5 }} onPress={() => onChoose(breed)}>
    <WaffleText key={'name'}>{breed.name}</WaffleText>
  </BreedView>
);

const codeToSpeciesIndex = (code: string) => {
  if (code) {
    const [species] = code.split('~');
    const idx = SPECIES.findIndex((c) => c === species);

    if (idx >= 0) {
      return idx;
    }
  }

  return 0;
};

type BreedSearchProps = {
  onChoose: (PetBreed) => void;
  breed?: string;
  code?: string;
};

export const BreedSearch = ({
  onChoose,
  breed = '',
  code = '',
}: BreedSearchProps) => {
  const [speciesIndex, setSpeciesIndex] = useState(codeToSpeciesIndex(code));
  const [filter, setFilter] = useState(breed);
  const species = SPECIES[speciesIndex];
  const { data } = useQuery<GetBreeds>(GET_BREEDS, {
    fetchPolicy: 'cache-and-network',
    variables: { species },
  });
  const [results, setResults] = useState<PetBreeds>([]);
  const allBreeds = data?.petBreeds ?? [];

  const updateResults = (text = filter) => {
    // We default the filter to use 'filter' variable, otherwise we can get
    // it passed in if it just changed.

    setResults(
      text.length === 0
        ? allBreeds
        : allBreeds.filter((breed) =>
            breed?.name.toLowerCase().includes(text.toLowerCase())
          )
    );
  };

  useEffect(() => updateResults(), [data]);

  return (
    <>
      <WaffleTextInput
        placeholder={'Start typing a breed'}
        placeholderTextColor={'#555555'}
        value={filter}
        onChangeText={async (text: string) => {
          setFilter(text);
          updateResults(text);
        }}
        otherProps={{
          blurOnSubmit: false,
          autoCapitalize: 'none',
          autoCompleteType: 'off',
          autoCorrect: false,
        }}
      />

      <SegmentedControl
        values={SPECIES}
        selectedIndex={speciesIndex}
        appearance={'light'}
        onChange={(event) =>
          setSpeciesIndex(event.nativeEvent.selectedSegmentIndex)
        }
        style={{ marginVertical: 10 }}
      />
      <ScrollView
        keyboardShouldPersistTaps={'handled'}
        style={{ height: IsSmallScreen() ? 150 : 200 }}>
        {results.length > 0 &&
          results.map((r, i) => (
            <BreedItem key={i} breed={r} onChoose={onChoose} />
          ))}
      </ScrollView>
    </>
  );
};

/*


 */
