import 'react-datepicker/dist/react-datepicker.css';
import Popup from './Popup';
import React, { useEffect, useState } from 'react';
import format from 'date-fns/format';
import { HorizontalEvenly, VerticalCenter } from './SimpleLayouts';
import { PopupTitle } from './WaffleText';
import { Spacer } from './SimpleComponents';
import { WaffleButtonAsync } from './WaffleButtonAsync';
import { default as InternalDatePicker } from 'react-datepicker';
import { DatePickerProps } from './DatePicker';

const DatePicker = ({
  visible,
  onPick,
  onCancel,
  minimumDate,
  maximumDate,
}: DatePickerProps) => {
  const [date, setDate] = useState<Date>(null);

  // useEffect(() => {
  //   if (!date && maximumDate) {
  //     setDate(maximumDate);
  //   }
  // }, [date, minimumDate, maximumDate]);

  return (
    <Popup visible={visible} onDismiss={onCancel} viewStyle={{ minWidth: 400 }}>
      <VerticalCenter>
        <Spacer y={3} />
        <PopupTitle>Select Date</PopupTitle>
        <Spacer y={3} />

        <InternalDatePicker
          selected={date}
          onChange={setDate}
          dateFormat={'yyyy-MM-dd'}
          dateFormatCalendar="MMMM" // Only show month in header
          // includeDates={[minimumDate, maximumDate]}
          minDate={minimumDate}
          maxDate={maximumDate}
          inline
          showYearDropdown
          yearDropdownItemNumber={65}
          scrollableYearDropdown
        />

        <Spacer y={2} />

        <HorizontalEvenly style={{ width: '100%' }}>
          <WaffleButtonAsync
            style={{ width: '45%' }}
            name={'Confirm'}
            disabled={!date}
            onPress={() => onPick(format(date, 'yyyy-LL-dd'))}
          />
          <WaffleButtonAsync
            style={{ width: '45%' }}
            name={'Cancel'}
            onPress={onCancel}
          />
        </HorizontalEvenly>
      </VerticalCenter>
    </Popup>
  );
};

export default DatePicker;
