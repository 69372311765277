import {
  PolicyAddOn,
  PolicyAnswers,
  PolicyCoverage,
  PolicyPetsAnswersPet,
} from '../../../component-config';
import styled, { css } from 'styled-components/native';
import WaffleText, { LargeText, WaffleHeaderText } from '../WaffleText';
import {
  WaffleDarkBlue,
  WaffleMediumGrey,
  WaffleOrange,
} from '../../Constants/Style';
import {
  ImageProps,
  ImageSourcePropType,
  Platform,
  ScrollView,
  TextStyle,
  ViewStyle,
} from 'react-native';
import {
  HorizontalPacked,
  HorizontalSpread,
  VerticalCenter,
} from '../SimpleLayouts';
import { Image, PlainView, Spacer } from '../SimpleComponents';
import React, { MutableRefObject, ReactNode } from 'react';
import Popup from '../Popup';
import { WaffleButtonAsync } from '../WaffleButtonAsync';
import {
  FinalizeUserPolicies2,
  FinalizeUserPolicies2Variables,
  GetDraftUserPolicies,
  LocalUser_user,
  RepriceDraftPolicies_repriceDraftPolicies,
  ResetDraftPolicy,
  ResetDraftPolicyVariables,
  ToggleAddOn,
  ToggleAddOnVariables,
  UpdateCoverageAndReprice,
  UpdateCoverageAndRepriceVariables,
  UpdatePolicyAnswerAndReprice,
  UpdatePolicyAnswerAndRepriceVariables,
  UpdatePolicyTypeAndReprice,
} from '../../../../operation-result-types';
import {
  DRAFT_POLICIES,
  FINALIZE_DRAFT_USER_POLICIES,
  RESET_DRAFT_POLICY,
  TOGGLE_ADD_ON_AND_REPRICE,
  UPDATE_COVERAGE_AND_REPRICE,
  UPDATE_POLICY_ANSWERS_AND_REPRICE,
  UPDATE_POLICY_TYPE_AND_REPRICE,
} from '../../GraphQL/Waffle/Queries';
import { ApolloClient } from '@apollo/client';
import { SuggestedSetState } from '../../Screens/SuggestedCoverageScreen';
import { UpdateUserStateOnServer } from '../../Helper/UserHelpers';
import { USER_STATE_COMPLETE } from '../../Helper/NavigationService';
import {
  ConnectToCFScreenName,
  ConnectToHippoScreeName,
  RootStackParamList,
  YourPolicyScreenName,
} from '../../../screen-config';
import { StackNavigationProp } from '@react-navigation/stack';
import { Logger } from '../../Helper/Logger';
import {
  ContainsExternalExitProduct,
  ProductCyber,
  ProductHome,
  ProductLife,
  ProductPet,
  ProductRenters,
  ProductTravel,
} from '../../Constants/Products';

export const SummarySectionWidth = 335;
export const CustomizeSectionWidth = 335;

export type EditorCoverageProps = {
  coverage: PolicyCoverage;
};

export type EditorAddOnProps = {
  productId: string;
  policyId: string;
  addOn: PolicyAddOn;
};

export const Title = styled(WaffleText)`
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  color: ${WaffleDarkBlue};
`;

export const ItemLabel = styled(WaffleText)`
  font-size: 15px;
  color: ${WaffleDarkBlue};
`;

export const ItemSubLabel = styled(WaffleText)`
  font-size: 12px;
  color: ${WaffleDarkBlue};
`;

export const Description = styled(WaffleText)`
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  color: ${WaffleDarkBlue};
`;

export const ItemLabelOrange = styled(ItemLabel)`
  color: ${WaffleOrange};
`;

export const ItemLabelOrangeLink = styled(ItemLabelOrange)`
  text-decoration-line: underline;
  ${Platform.OS === 'web' &&
  css`
    cursor: pointer;
  `};
`;

const BoxView = styled.View`
  align-items: center;
  border-bottom-width: 1px;
  border-color: ${WaffleMediumGrey};
  width: 100%;
`;

export const Box = ({ children }) => (
  <BoxView>
    <Spacer y={2.5} />

    {children}

    <Spacer y={2.5} />
  </BoxView>
);

export const HeaderSpread = ({
  children,
  style,
}: {
  children: ReactNode;
  style?: ViewStyle;
}) => (
  <HorizontalSpread style={[{ width: SummarySectionWidth }, style]}>
    {children}
  </HorizontalSpread>
);

export const NormalizeAddOnName: Record<string, string> = {
  // Renters
  'Earthquake Protection': 'Earthquakes',
  // Travel
  'Rental Car Damage Upgrade': 'Rental Car',
  'Spots & Hazardous Sports Upgrade': 'Sports & Hazardous Sports',
  'Baggage, Sports & Business Equipment Upgrades':
    'Baggage, Sports & Business Equipment',
  'AD&D Flight Accident': 'Flight Accident',
};

export type RowImageProps = {
  label: string;
  source: ImageSourcePropType;
  inactiveSource?: ImageSourcePropType;
};

export type RowImagesProps = {
  style?: ViewStyle;
  images: RowImageProps[];
  inactive?: boolean;
  inactiveFilter?: (r: RowImageProps) => boolean;
};

const Label = styled(WaffleText)`
  width: 80px;
  font-size: 12px;
  text-align: center;
  color: ${WaffleDarkBlue};
`;

// perhaps pack if 3, spread if 4?
export const RowImages = ({
  style,
  images,
  inactive,
  inactiveFilter = () => false,
}: RowImagesProps) => (
  <HorizontalPacked style={[{ alignItems: 'flex-start' }, style]}>
    {images?.map((c, idx) => (
      <React.Fragment key={idx}>
        <VerticalCenter key={c.label}>
          <Image
            source={
              inactive || inactiveFilter(c)
                ? c.inactiveSource || c.source
                : c.source
            }
            style={{ width: 70, height: 70 }}
          />

          <Spacer y={1.25} />

          <Label>{c.label}</Label>
        </VerticalCenter>
        <Spacer x={0.25} />
      </React.Fragment>
    ))}
  </HorizontalPacked>
);

type CoveragePopupProps = {
  popup: boolean;
  setPopup: (boolean) => void;
  onContinue: () => Promise<void>;
  title: string;
  verbiage: string;
  buttonText: string;
};

export const CoveragePopup = ({
  popup,
  setPopup,
  onContinue,
  title,
  verbiage,
  buttonText,
}: CoveragePopupProps) => {
  return (
    popup && (
      <Popup key={'popup'} visible={popup} onDismiss={() => setPopup(false)}>
        <PlainView style={{ alignItems: 'center', marginTop: 16 }}>
          <WaffleHeaderText>{title}</WaffleHeaderText>

          <LargeText style={{ marginTop: 10, textAlign: 'center' }}>
            {verbiage}
          </LargeText>

          <WaffleButtonAsync
            style={{ marginTop: 18 }}
            name={buttonText}
            onPress={onContinue}
          />
        </PlainView>
      </Popup>
    )
  );
};

export const continueTitle = (hasPet: boolean, isIncompleteUser: boolean) => {
  if (hasPet || isIncompleteUser) {
    return 'Awesome!';
  }
  return "You're all done!";
};

export const continueVerbiage = (
  hasPet: boolean,
  hasCfar: boolean,
  hasHome: boolean,
  isIncompleteUser: boolean
) => {
  if (isIncompleteUser) {
    return `We'll get you set up in less than 2 minutes`;
  }

  if (hasCfar) {
    return 'The Cancel for Any Reason benefit will reimburse up to 60% of your prepaid non-refundable trip costs when you cancel your trip for other than a covered reason.  Benefits apply only for trip payment made with cash, credit card or check.  Limitations apply including benefits are not payable if your travel supplier cancels your trip for any reason. Please review Description of Coverage for full details.';
  }

  if (hasHome) {
    return 'Continue to your Home insurance application'; // todo : change verbiage?
  }

  return 'Ready to review your selection?';
};

export const PartnerExitNavigation = async (
  navigation: StackNavigationProp<RootStackParamList>,
  client: ApolloClient<object>,
  products: string[]
) => {
  // Login has been created, or skipped, so finalize policy, then continue to the appropriate screen.
  await client.mutate<FinalizeUserPolicies2, FinalizeUserPolicies2Variables>({
    mutation: FINALIZE_DRAFT_USER_POLICIES,
    variables: { paymentMethodId: '' }, // We don't collect payment, but partner does
  });

  if (products.includes(ProductHome)) {
    navigation.navigate(ConnectToHippoScreeName);
  } else if (products.includes(ProductPet)) {
    navigation.navigate(ConnectToCFScreenName);
  }
  Logger(`PartnerExitNavigation products=${JSON.stringify(products)}`);
};

export const SuggestedCoverageNextNavigation = async (
  navigation: StackNavigationProp<RootStackParamList>,
  client: ApolloClient<object>,
  isGuest: boolean,
  products: string[]
) => {
  if (ContainsExternalExitProduct(products)) {
    // We're done, so save policy and continue to Pet.  We want to flag
    // the policy as PENDING_EXTERNAL.   We also flag the user as complete,
    // so if app reloads we'll end up at Home screen.   (We could prompt user to create
    // account here, but we just throw them to the partner, as they chose a single
    // product and the rest has to be done on partner's side.)

    await UpdateUserStateOnServer(client, USER_STATE_COMPLETE);

    await PartnerExitNavigation(navigation, client, products);

    return;
  }

  // save policy here too?
  navigation.navigate(YourPolicyScreenName);
};

export const onReFetch = async (client) =>
  await client.query({
    query: DRAFT_POLICIES,
    fetchPolicy: 'network-only',
  });

export const fetchDraftPoliciesAsync = (client: ApolloClient<unknown>) =>
  client.query<GetDraftUserPolicies>({
    query: DRAFT_POLICIES,
    fetchPolicy: 'network-only',
  });

export const extractPolicyProductMessages = (
  policies: RepriceDraftPolicies_repriceDraftPolicies[]
): Record<string, string> => {
  return Object.fromEntries(
    policies.map((pol) => [pol.product_id, pol?.update_status?.message])
  );
};

const doUpdate = async (
  setState: SuggestedSetState,
  fn: () => Promise<void>
) => {
  await setState((existing) => ({ ...existing, updating: true }));

  try {
    await fn();
  } finally {
    await setState((existing) => ({ ...existing, updating: false }));
  }
};

export const toggleAddOn = async (
  client: ApolloClient<object>,
  policyId: string,
  addOnId: string,
  setState: SuggestedSetState
) =>
  await doUpdate(setState, async () => {
    const { data } = await client.mutate<ToggleAddOn, ToggleAddOnVariables>({
      mutation: TOGGLE_ADD_ON_AND_REPRICE,
      variables: { input: { id: policyId, addon_id: addOnId } },
    });

    if (data?.togglePolicyAddonAndReprice) {
      const { product_id, update_status } = data.togglePolicyAddonAndReprice;

      await setState((existing) => ({
        ...existing,
        updateMessages: {
          ...existing.updateMessages,
          [product_id]: update_status?.message,
        },
      }));
    }

    await onReFetch(client);
  });

export const updateCoverageValue = async (
  client: ApolloClient<object>,
  coverageId: string,
  coverageLimit: string,
  setState: SuggestedSetState
) =>
  await doUpdate(setState, async () => {
    await client.mutate<
      UpdateCoverageAndReprice,
      UpdateCoverageAndRepriceVariables
    >({
      mutation: UPDATE_COVERAGE_AND_REPRICE,
      variables: { input: { id: coverageId, coverage_limit: coverageLimit } },
    });

    await onReFetch(client);
  });

export const updateCyberPolicyFrequency = async (
  client: ApolloClient<object>,
  policyId: string,
  coverageId: string,
  policyType: string,
  setState: SuggestedSetState
) =>
  await doUpdate(setState, async () => {
    await client.mutate<
      UpdatePolicyTypeAndReprice,
      UpdatePolicyAnswerAndRepriceVariables
    >({
      mutation: UPDATE_POLICY_TYPE_AND_REPRICE,
      variables: {
        input: {
          id: policyId,
          coverage_id: coverageId,
          policy_type: policyType,
        },
      },
    });
    await onReFetch(client);
  });

export const resetDraftPolicy = async (
  client: ApolloClient<object>,
  policyId: string,
  setState: SuggestedSetState
) =>
  await doUpdate(setState, async () => {
    await client.mutate<ResetDraftPolicy, ResetDraftPolicyVariables>({
      mutation: RESET_DRAFT_POLICY,
      variables: {
        policy_id: policyId,
      },
    });
    await onReFetch(client);
  });

export const updateAnswerInputs = async (
  client: ApolloClient<object>,
  policyId: string,
  answerInputs: PolicyAnswers | PolicyPetsAnswersPet[],
  setState: SuggestedSetState
) =>
  await doUpdate(setState, async () => {
    const answer_inputs = JSON.stringify(answerInputs);

    await client.mutate<
      UpdatePolicyAnswerAndReprice,
      UpdatePolicyAnswerAndRepriceVariables
    >({
      mutation: UPDATE_POLICY_ANSWERS_AND_REPRICE,
      variables: {
        input: {
          id: policyId,
          answer_inputs,
        },
      },
    });

    await onReFetch(client);
  });

export const coverageItem = (
  coverages: PolicyCoverage[],
  productCoverageId: string
) => coverages.filter((c) => c.productCoverageId === productCoverageId)?.[0];

export const coverageValue = (
  coverages: PolicyCoverage[],
  productCoverageId: string
) =>
  coverages.filter((c) => c.productCoverageId === productCoverageId)?.[0]
    ?.coverageLimit;

export const addScrollTabToTopHook = (
  navigation,
  ref: MutableRefObject<ScrollView>
) => {
  navigation.setParams({
    scrollToTop: () => ref?.current?.scrollTo({ x: 0, y: 0, animated: false }),
  });
};

const PoweredBy = styled(WaffleText)`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: ${WaffleDarkBlue};
`;

const PoweredByImage: Record<string, ImageProps> = {
  [ProductCyber]: {
    source: require('../../../assets/images/products/design-coverage/Cyber_poweredby.webp'),
    style: { width: 90, height: 34 },
  },
  [ProductHome]: {
    source: require('../../../assets/images/products/design-coverage/home_logo.webp'),
    style: { width: 82, height: 31 },
  },
  [ProductLife]: {
    source: require('../../../assets/images/products/design-coverage/life_logo.webp'),
    style: { width: 91, height: 31 },
  },
  [ProductPet]: {
    source: require('../../../assets/images/products/design-coverage/pet_logo.webp'),
    style: { width: 100, height: 12 },
  },
  [ProductTravel]: {
    source: require('../../../assets/images/products/design-coverage/travel_logo.webp'),
    style: { width: 114, height: 21 },
  },
  [ProductRenters]: {
    source: require('../../../assets/images/products/design-coverage/renters_logo.webp'),
    style: { width: 110, height: 39 },
  },
};

export const PoweredByBlurb = ({
  productId,
  style,
}: {
  productId: string;
  style?: ViewStyle & TextStyle;
}) => {
  const poweredBy = productId === 'Pet' ? 'Partnered with' : 'Powered by';

  return (
    <PoweredBy style={[{ width: 240 }, style]}>
      {poweredBy}{' '}
      <Image resizeMode={'contain'} {...PoweredByImage[productId]} />
    </PoweredBy>
  );
};

const fullPolicyUrl = {
  Cyber: 'https://www.trywaffle.com/sample-policies',
  Pet: 'https://www.trywaffle.com/sample-policies',
  Renters: 'https://www.trywaffle.com/sample-policies',
};

export const GetFullPolicy = (user: LocalUser_user, productId: string) => {
  if (productId === 'Travel') {
    return `https://trywaffle.com/travel-insurance-policy/${user.state}.pdf`;
  }

  return fullPolicyUrl[productId];
};

export const GetFullPolicyPath = (state: string, productId: string) => {
  if (productId === 'Travel') {
    return `https://trywaffle.com/travel-insurance-policy/${state}.pdf`;
  }

  return fullPolicyUrl[productId];
};
