import React from 'react';
import { Horizontal } from '../SimpleLayouts';
import { WaffleButton } from '../WaffleButton';
import { ViewStyle } from 'react-native';

type Props = {
  name: string;
  onPress: () => Promise<void>;
  style?: ViewStyle;
  disabled: boolean;
};

export const ActionButtonImpl = ({ name, onPress, style, disabled }: Props) => (
  <Horizontal
    style={{
      padding: 15,
    }}>
    <WaffleButton
      name={name}
      onPress={onPress}
      style={{
        ...{
          backgroundColor: '#217cec',
        },
        ...style,
      }}
      disabled={disabled}
    />
  </Horizontal>
);
