import React, { useState } from 'react';
import { DataRow } from '../SimpleComponents';
import { VerticalCenter } from '../SimpleLayouts';
import { WhatIsImportant } from './ProductChooserShared';
import { ProtectMessage } from './ProtectMessage';
import { Platform } from 'react-native';
import { ProductToggleButton } from '../ProductToggleButton';
import { GetProducts_products } from '../../../../operation-result-types';

export const ProductChooserHeader = ({ selectedCount }) => (
  <DataRow>
    <VerticalCenter>
      <DataRow style={{ marginTop: 20 }}>
        <WhatIsImportant>What do you love?</WhatIsImportant>
      </DataRow>

      <DataRow style={{ marginVertical: 20 }}>
        <ProtectMessage selected={selectedCount} />
      </DataRow>
    </VerticalCenter>
  </DataRow>
);

export const ProductsContainer = ({ children }) => (
  <VerticalCenter>
    <DataRow
      testID="productChooserDataRow"
      style={{
        // height: Platform.OS === 'web' ? 550 : 400,
        maxWidth: Platform.OS === 'web' ? 900 : 350,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}>
      {children}
    </DataRow>
  </VerticalCenter>
);

type ProductSelectorProps = {
  products: GetProducts_products[];
  isPressed: (p: GetProducts_products) => boolean;
  toggleProduct: (p: GetProducts_products) => void;
  toggles: Record<string, boolean>;
  productFilter: (p: GetProducts_products) => boolean;
};

export const ProductSelector = ({
  products,
  isPressed,
  toggleProduct,
  toggles,
  productFilter,
}: ProductSelectorProps) => (
  <ProductsContainer>
    {products.filter(productFilter).map((item) => (
      <ProductToggleButton
        key={item.id}
        pressed={isPressed(item)}
        setPressed={() => toggleProduct(item)}
        product={item}
        toggles={toggles}
      />
    ))}
  </ProductsContainer>
);
