import WaffleText from '../WaffleText';
import { Spacer } from '../SimpleComponents';
import { Counter } from '../DesignPolicy/Counter';
import { WaffleLightGrey, WaffleMediumGrey } from '../../Constants/Style';
import React from 'react';

type Props = {
  label?: string;
  value: number;
  description?: string;
  isMonetary?: boolean;
  onIncrease: () => Promise<void>;
  onDecrease: () => Promise<void>;
};

export default function CoverageCardCounter({
  label,
  value,
  description,
  onIncrease,
  onDecrease,
  isMonetary = true,
}: Props) {
  const localizedValue = isMonetary
    ? `$${value.toLocaleString()}`
    : value.toLocaleString();

  return (
    <>
      {label ? (
        <>
          <WaffleText
            style={{
              fontSize: 14,
              fontWeight: '700',
            }}>
            {label}
          </WaffleText>
          <Spacer x={2} y={2} />
        </>
      ) : null}
      <Counter
        description={description}
        label={localizedValue}
        updating={false}
        color={WaffleMediumGrey}
        updatingColor={WaffleLightGrey}
        invert={true}
        onPrev={onDecrease}
        onNext={onIncrease}
        containerStyle={
          {
            // shadowRadius: 100,
            // borderRadius: 100,
            // shadowOffset: { width: 0, height: 4 },
            // shadowColor: 'rgba(108,62,12,0.5)',
            // shadowOpacity: 1,
            // elevation: 5,
          }
        }
      />
    </>
  );
}
