import React from 'react';
import { Image } from 'react-native';
import { HorizontalPacked } from '../../SimpleLayouts';
import { isFullWeb } from '../../../Helper/DeviceHelper';
import { Spacer } from '../../SimpleComponents';
import WaffleText from '../../WaffleText';

function AspcaPetHealthInsurance() {
  return (
    <HorizontalPacked>
      <Image
        source={require('../../../../assets/images/products/pet/aspca_logo.webp')}
        resizeMode={'contain'}
        style={{ width: isFullWeb() ? 115 : 78, height: 42 }}
      />
      <Spacer x={1} />
      <WaffleText style={{ fontSize: 24, fontWeight: '600' }}>
        Pet Health Insurance
      </WaffleText>
    </HorizontalPacked>
  );
}

export { AspcaPetHealthInsurance };
