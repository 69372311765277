import styled from 'styled-components/native';
import WaffleText, { WaffleTextFamily } from '../WaffleText';
import { PlainView, Spacer, TouchableOpacity } from '../SimpleComponents';
import { HorizontalPacked } from '../SimpleLayouts';
import { WaffleOrange, White } from '../../Constants/Style';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import React, { useEffect } from 'react';
import { Platform } from 'react-native';
import { ListItem } from 'react-native-elements';
import { USER_POLICIES } from '../../GraphQL/Waffle/Queries';
import { GetFinalizedUserPolicies } from '../../../../operation-result-types';
import { useNavigation } from '@react-navigation/native';
import { ApolloClient, useQuery, useApolloClient } from '@apollo/client';
import {
  ManagePlanScreenName,
  ProductChooserScreenName,
} from '../../../screen-config';
import { BuildVersionAndPlatform } from '../../Helper/UserHelpers';

const Title = styled(WaffleText)`
  color: white;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 700;
`;

const ProtectSomethingNew = () => {
  const navigation = useNavigation();
  return (
    <TouchableOpacity
      onPress={() => navigation.navigate(ProductChooserScreenName)}>
      <HorizontalPacked>
        <WaffleText
          style={{
            color: WaffleOrange,
            fontSize: 18,
            lineHeight: 21,
            fontWeight: '500',
          }}>
          Protect something new
        </WaffleText>

        <Spacer x={1} />

        <Icon
          name={'plus-circle'}
          size={28}
          color={WaffleOrange}
          onPress={() => navigation.navigate(ProductChooserScreenName)}
        />
      </HorizontalPacked>
    </TouchableOpacity>
  );
};

const flexMaybe = Platform.OS !== 'web' ? { flex: 0, flexGrow: 1 } : {};

interface Props {
  text: string;
  disabled?: boolean;
  onPress?: () => void;
}

const PolicyItem = ({ text, onPress, disabled = false }: Props) => (
  <ListItem
    disabled={disabled}
    onPress={onPress}
    disabledStyle={{ opacity: 0.25 }}
    underlayColor={'transparent'}
    containerStyle={{
      height: 40,
      backgroundColor: '#5AC3ED',
      borderRadius: 100,
      marginTop: 5,
      marginBottom: 5,
      marginRight: 10,
    }}>
    <ListItem.Content
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        ...flexMaybe,
        // Resize content to be precisely the right size
        height: 30,
      }}>
      <ListItem.Title
        style={{
          fontFamily: WaffleTextFamily,
          fontSize: 24,
          lineHeight: 30,
          color: White,
        }}>
        {text}
      </ListItem.Title>
    </ListItem.Content>
    <ListItem.Chevron tvParallaxProperties={{}} />
  </ListItem>
);

const reFetch = (client: ApolloClient<object>) => {
  client.query({
    query: USER_POLICIES,
    fetchPolicy: 'network-only',
    variables: { input: BuildVersionAndPlatform() },
  });
};

const ProductButtons = ({ maxWidth }) => {
  const navigation = useNavigation();
  const client = useApolloClient();
  const { data, loading, error } = useQuery<GetFinalizedUserPolicies>(
    USER_POLICIES,
    {
      fetchPolicy: 'cache-and-network',
      variables: { input: BuildVersionAndPlatform() },
    }
  );

  useEffect(() => {
    const unsubscribe = navigation?.addListener('focus', () => {
      reFetch(client);
    });

    return unsubscribe;
  }, [navigation]);

  if (loading || error) {
    // todo : it would be great to ues React Suspense to put a spinner if things take too long
    return null;
  }

  const policies = data?.finalizedUserPolicies ?? [];
  const uniqueProducts: string[] = Array.from(
    new Set(policies.map((_) => _.productId))
  );
  // todo : grey out cancelled ones?

  return (
    <PlainView
      style={{
        maxWidth,
        flexDirection: 'row',
        flexWrap: 'wrap',
      }}>
      {uniqueProducts.map((p) => (
        <PolicyItem
          key={p}
          text={p}
          onPress={() => navigation.navigate(ManagePlanScreenName)}
        />
      ))}
    </PlainView>
  );
};

export { ProtectSomethingNew, Title, ProductButtons };
