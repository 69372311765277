import React, { useState } from 'react';

import { ActionButtonImpl } from './ActionButtonImpl';
import { Horizontal } from '../SimpleLayouts';
import { MessageText } from './MessageText';
import { LinkToCFPetPopup } from '../../Partners/LinkToCFPet';
import { LargeText } from '../WaffleText';
import { ClaimsComplete } from './ClaimsComplete';
import { ClaimsCallButton } from './ClaimsCallButton';

export const ContactCrumForster = ({ doComplete, disabled }: any) => {
  const [popup, setPopup] = useState(false);

  return (
    <>
      <ClaimsCallButton phone={'+1-866-800-8315'} disabled={disabled} />

      <Horizontal>
        <MessageText style={{ textAlign: 'center' }}>or</MessageText>
      </Horizontal>

      <ActionButtonImpl
        name={'member center'}
        onPress={async () => {
          // await NavigationService.navigate('CFMemberCenter');
          // doComplete && await doComplete();
          await setPopup(true);
        }}
        disabled={disabled}
      />

      <ClaimsComplete doComplete={doComplete} disabled={disabled} />

      <LinkToCFPetPopup
        popup={popup}
        setPopup={setPopup}
        doComplete={doComplete}>
        <LargeText style={{ marginVertical: 15 }}>
          You will now be taken to our partner, ASPCA® Pet Health Insurance, to
          submit your claim. Please log into the site using your Waffle
          credentials and navigate to the claims section.
        </LargeText>
        <LargeText style={{ marginBottom: 15 }}>
          If there are any issues, please use the Waffle chat and we will
          assist.
        </LargeText>
      </LinkToCFPetPopup>
    </>
  );
};
