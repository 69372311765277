import React, { ReactNode } from 'react';
import styled from 'styled-components/native';

import WaffleText from '../WaffleText';

const WeatherView = styled.View`
  height: 54.2px;
  width: 54px;
  background-color: #4297e7;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const WeatherLabel = styled(WaffleText)`
  width: 49.6px;
  color: #1f3c88;
  font-size: 11.2px;
  font-weight: 500;
  letter-spacing: 0.45px;
  text-align: center;
  text-transform: uppercase;
`;

const WeatherValue = styled(WaffleText)`
  color: #fff;
  font-size: 19.2px;
  font-weight: 700;
  letter-spacing: -0.19px;
  text-align: center;
`;

type Props = {
  title: string;
  value: ReactNode;
};

const WeatherBox = ({ title, value }: Props) => (
  <WeatherView>
    <WeatherLabel>{title}</WeatherLabel>
    <WeatherValue>{value}</WeatherValue>
  </WeatherView>
);

export default WeatherBox;
