import styled from 'styled-components/native';
import WaffleText from './WaffleText';

export const ErrorText = styled(WaffleText)`
  height: 40px;
  color: #ff0c01;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: center;
`;
