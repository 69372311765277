import React from 'react';

import { WaffleButtonAsync } from '../WaffleButtonAsync';
import { AdaptiveButtonWidth } from '../../Helper/DeviceHelper';

const SettingsButton = (props: any) => (
  <WaffleButtonAsync
    onPress={props.onPress}
    disabled={props.disabled}
    style={{
      ...props.style,
      width: AdaptiveButtonWidth(),
      //backgroundColor: '#070D59',  // summary value blue
      // backgroundColor: '#4297e7', // waffle blue
      // backgroundColor: '#D1E9FF', // value details
      borderRadius: 9,
    }}
    textStyle={props.textStyle}
    name={props.name}
    pressedName={props.pressedName}
  />
);

export { SettingsButton };
