import React, { useState } from 'react';
import { ActionButtonImpl } from './ActionButtonImpl';
import { DateRangePicker } from '../DateRangePicker';

export const PickDateRange = ({
  onChange,
  disabled,
  title,
  ...otherProps
}: any) => {
  const [picker, setPicker] = useState(false);

  return (
    <>
      <ActionButtonImpl
        name={'Pick date range'}
        onPress={async () => setPicker(true)}
        disabled={disabled}
      />
      <DateRangePicker
        picker={picker}
        setPicker={setPicker}
        title={title}
        onChange={onChange}
        {...otherProps}
      />
    </>
  );
};

/*
      <Popup visible={picker} onDismiss={() => setPicker(false)} viewStyle={{
        maxWidth: '100%',
        marginLeft: 0,
        marginRight: 0,
      }}>
        <VerticalCenter>
          <Spacer y={3}/>

          <PopupTitle>{title}</PopupTitle>

          <Spacer y={3}/>

          <CalendarPicker
            allowRangeSelection={true}
            scrollable={true}
            onDateChange={onDateChange}
            restrictMonthNavigation={true}
            selectedDayColor={WaffleOrange} selectedDayTextColor={'#ffffff'}
            scaleFactor={Platform.OS === 'web' ? 1000 : 375}
            textStyle={{
              // fontFamily: WaffleTextFamily,
              color: '#000000',
            }}
            {...otherProps}/>

          <HorizontalEvenly style={{ width: '100%' }}>
            <WaffleButtonAsync style={{ width: '45%' }} name={'Confirm'}
                               disabled={!(dateRange as any).start || !(dateRange as any).end} onPress={onConfirm}/>
            <WaffleButtonAsync style={{ width: '45%' }} name={'Cancel'} onPress={async () => setPicker(false)}/>
          </HorizontalEvenly>
        </VerticalCenter>
      </Popup>

 */
