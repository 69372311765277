import React from 'react';
import WaffleHalfTextInput from '../WaffleHalfTextInput';
import { WaffleTextInputProps } from '../../../component-config';
import * as yup from 'yup';
import { TextIconInput } from '../TextIconInput';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { WaffleOrange } from '../../Constants/Style';

export const firstNameValidator = yup.string().required();

// deprecated
export const FirstNameInput = (props: WaffleTextInputProps) => (
  <WaffleHalfTextInput
    label={'First Name'}
    placeholder={'First Name'}
    otherProps={{
      autoCompleteType: 'given-name',
      keyboardType: 'name-phone-pad',
      textContentType: 'givenName',
    }}
    {...props}
  />
);

export const FirstNameIconInput = (props: WaffleTextInputProps) => (
  <TextIconInput
    placeholder={'First Name'}
    otherProps={{
      autoCompleteType: 'given-name',
      keyboardType: 'name-phone-pad',
      textContentType: 'givenName',
    }}
    leftIcon={<Icon name={'person-outline'} color={WaffleOrange} size={23} />}
    {...props}
  />
);
