import React, { useState } from 'react';

import { Picker } from '@react-native-picker/picker';
import { Horizontal } from '../SimpleLayouts';
import _ from 'lodash';
import { ModalSearchPopup } from '../ModalSearchPopup';
import { Spacer } from '../SimpleComponents';
import { isFullWeb } from '../../Helper/DeviceHelper';
import { Platform } from 'react-native';

const feet = _.range(9);
const inches = _.range(12);

export const HeightPickerPopup = ({ popup, setPopup, onChoose }: any) => {
  const [height, setHeight] = useState({ f: 5, i: 6 });
  const containerStyle = Platform.OS === 'web' ? { height: 150 } : {};
  const style =
    Platform.OS === 'web'
      ? {}
      : {
          height: 225,
          width: '30%',
        };

  const onDone = async () => {
    onChoose(`${height.f} ft ${height.i} in`, height.f * 12 + height.i);
  };

  return (
    <ModalSearchPopup
      popup={popup}
      setPopup={setPopup}
      onDone={onDone}
      containerStyle={containerStyle}>
      <Horizontal>
        <Picker
          selectedValue={height.f}
          style={style}
          onValueChange={(f) => setHeight({ ...height, f })}>
          {feet.map((h: number) => (
            <Picker.Item key={h} label={`${h} ft`} value={h} />
          ))}
        </Picker>

        <Picker
          selectedValue={height.i}
          style={style}
          onValueChange={(i) => setHeight({ ...height, i })}>
          {inches.map((i: number) => (
            <Picker.Item key={i} label={`${i} in`} value={i} />
          ))}
        </Picker>
      </Horizontal>

      {isFullWeb() && <Spacer y={3} />}
    </ModalSearchPopup>
  );
};
