import { useQuery } from '@apollo/client';
import React, { ForwardedRef, ReactNode } from 'react';
import { Resizable } from 'react-native-web-hooks';

import { PlainScrollView, PlainView, Spacer } from './SimpleComponents';
import styles, {
  FooterHeight,
  WaffleDarkGrey,
  White,
} from '../Constants/Style';
import { HorizontalSpread, VerticalSpread } from './SimpleLayouts';
import { GreenHeaderImage, HeaderNavTitle } from './HeaderNavTitle.web';
import { GET_LOCAL_USER } from '../GraphQL/Waffle/Queries';
import { SideNav } from './SideNav';
import { HeaderNav } from './HeaderNav.web';
import Footer from './Home/Footer';
import { hasBottomSwitcher } from '../Helper/FeatureFlags';
import { isFullWeb } from '../Helper/DeviceHelper';
import { ImageSourcePropType, ScrollView, StyleProp } from 'react-native';

const ContentPlainCenter = ({ children }: { children: React.ReactNode }) => (
  <PlainView style={[styles.fullWidth]}>
    <PlainView
      style={{
        marginLeft: 32,
        marginRight: 32,
      }}>
      {children}
    </PlainView>
  </PlainView>
);

const ContentPackedScroll = ({ children }) => (
  <PlainScrollView
    showsVerticalScrollIndicator={false}
    showsHorizontalScrollIndicator={false}
    style={styles.fullWidth}
    contentContainerStyle={styles.verticalPackedCenter}>
    {children}
  </PlainScrollView>
);

const ContentCenterScroll = ({ children }) => (
  <PlainScrollView
    showsVerticalScrollIndicator={false}
    showsHorizontalScrollIndicator={false}
    style={styles.fullWidth}
    contentContainerStyle={styles.verticalPackedCenter}>
    {children}
  </PlainScrollView>
);

const ContentFragment = ({
  children,
}: {
  children?: ReactNode;
}): JSX.Element => <>{children}</>;

interface Props {
  addingProduct?: boolean;
  children: JSX.Element[] | JSX.Element;
  ChildrenWrapper: JSX.Element;
}

const ContentUnifiedMobile = ({ addingProduct, children, ChildrenWrapper }) => (
  <>
    <ChildrenWrapper>{children}</ChildrenWrapper>

    {!addingProduct && (
      <Footer
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
        }}
      />
    )}
  </>
);

// one of the wrapper blocks?
const OnlyBlock = ({ children }) =>
  isFullWeb() ? (
    <>
      <Spacer y={10} />

      <PlainView
        style={[
          {
            // width: 720,
            width: '90%', // default web width
            maxWidth: 840,
            backgroundColor: White,
            borderWidth: 1,
            borderColor: WaffleDarkGrey,
            flex: 1,
            borderRadius: 20,
            paddingBottom: 8,
            paddingLeft: 16,
            paddingRight: 16,
            marginLeft: 24,
            marginRight: 24,
          },
        ]}>
        {children}
      </PlainView>

      <Spacer y={10} />
    </>
  ) : (
    <>{children}</>
  );

export const OnlyBlockNoWidth = ({ children }) =>
  isFullWeb() ? (
    <>
      <Spacer y={12} />

      <PlainView
        style={[
          {
            width: '90%',
            borderWidth: 1,
            borderColor: WaffleDarkGrey,
            flex: 1,
            borderRadius: 20,
            paddingBottom: 8,
            paddingLeft: 16,
            paddingRight: 16,
          },
        ]}>
        {children}
      </PlainView>

      <Spacer y={2} />
    </>
  ) : (
    <>{children}</>
  );

const MainContentContainer = ({ children }) =>
  isFullWeb() ? (
    <>
      <Spacer y={12} />

      <PlainView
        style={[
          {
            borderColor: WaffleDarkGrey,
            flex: 1,
            width: '80%',
            maxWidth: 880,
          },
        ]}>
        {children}
      </PlainView>
    </>
  ) : (
    <>{children}</>
  );

type ForwardingScrollingBlockProps = {
  ref?: ForwardedRef<ScrollView>;
  children: ReactNode;
};

export const ForwardingScrollingBlock = ({
  ref,
  children,
}: ForwardingScrollingBlockProps) => (
  <>
    {isFullWeb() && <Spacer y={10} />}

    <ScrollView
      ref={ref}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      style={styles.fullWidth}
      contentContainerStyle={styles.verticalPackedCenter}>
      {children}
    </ScrollView>
  </>
);

const ScrollingBlock = ({ children }) => (
  <>
    {isFullWeb() && <Spacer y={10} />}
    <ContentCenterScroll>{children}</ContentCenterScroll>
  </>
);

const BottomSpacer = () =>
  isFullWeb() ? <Spacer y={0.0} /> : <Spacer y={hasBottomSwitcher() ? 5 : 3} />;

const ThinBottomSpacer = () =>
  isFullWeb() ? <Spacer y={0.0} /> : <Spacer y={hasBottomSwitcher() ? 3 : 1} />;

const FooterSpacer = () =>
  isFullWeb() ? <></> : <Spacer y={1 + FooterHeight / 8.0} />;

type ContentUnifiedProps = {
  children?: ReactNode;
  title?: string;
  titleStyle?: StyleProp<any>;
  contentStyle?: StyleProp<any>;
  image?: ImageSourcePropType;
  ChildrenWrapper?: (ReactNode) => JSX.Element;
  addingProduct?: boolean;
  showNav?: boolean;
  showNavHeader?: boolean;
};

const ContentUnifiedWeb = ({
  children,
  title = '',
  titleStyle = {},
  showNav,
  showNavHeader = true,
  image = GreenHeaderImage,
  contentStyle,
  ChildrenWrapper,
}: ContentUnifiedProps) => {
  const {
    data: { user },
  } = useQuery(GET_LOCAL_USER);
  const { firstName } = user;
  const greeting = firstName ? `Hi, ${firstName}` : 'Hi!';

  // Note: The zero height spacer helps balance the 3 components in the VerticalSpread below.
  return (
    <Resizable>
      {(layout) => (
        <>
          {showNav && showNavHeader && (
            <HeaderNavTitle
              title={title || greeting}
              image={image}
              titleStyle={titleStyle}
              layout={layout}
            />
          )}

          <HorizontalSpread
            style={[{ backgroundColor: '#FFF8F3' }, contentStyle]}>
            {showNav && <SideNav layout={layout} />}

            <VerticalSpread
              style={[styles.fullHeight, { flexGrow: 1, flexShrink: 1 }]}>
              {showNav && <HeaderNav layout={layout} />}

              <ChildrenWrapper>{children}</ChildrenWrapper>

              <Spacer y={0} />
            </VerticalSpread>
          </HorizontalSpread>
        </>
      )}
    </Resizable>
  );
};

const ContentUnified = ({
  children,
  title = '',
  titleStyle = {},
  image = GreenHeaderImage,
  ChildrenWrapper = ContentFragment,
  contentStyle,
  showNav = true,
  showNavHeader = true,
  addingProduct = false, // We use this to flag if navigation should be in place
}: ContentUnifiedProps) =>
  isFullWeb() ? (
    <ContentUnifiedWeb
      title={title}
      titleStyle={titleStyle}
      image={image}
      showNav={showNav}
      showNavHeader={showNavHeader}
      contentStyle={contentStyle}
      ChildrenWrapper={ChildrenWrapper}>
      {children}
    </ContentUnifiedWeb>
  ) : (
    <ContentUnifiedMobile
      ChildrenWrapper={ChildrenWrapper}
      addingProduct={addingProduct}>
      {children}
    </ContentUnifiedMobile>
  );

export {
  ContentPlainCenter,
  ContentPackedScroll,
  ContentFragment,
  ContentCenterScroll,
  ContentUnified,
  MainContentContainer,
  OnlyBlock,
  ScrollingBlock,
  BottomSpacer,
  FooterSpacer,
  ThinBottomSpacer,
};
