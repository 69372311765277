import React, { ReactNode } from 'react';
import {
  KeyboardAvoidingView,
  Modal,
  Platform,
  ScaledSize,
  ViewStyle,
} from 'react-native';
import styled from 'styled-components/native';

import { VerticalCenter } from './SimpleLayouts';
import { WaffleButton } from './WaffleButton';

import {
  isFullWeb,
  IsMidScreen,
  isMobileWeb,
  IsSmallScreen,
} from '../Helper/DeviceHelper';
import { Resizable } from 'react-native-web-hooks';
import { MenuWidth } from '../Constants/Style';

type HasWebSpecificLogic = {
  isWeb: boolean;
  layout: ScaledSize;
};

const ModalBackground = styled.View<HasWebSpecificLogic>`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.52);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  flex: 1;
  flex-direction: column;
  justify-content: ${(props) => (props.isWeb ? 'center' : 'flex-start')};
  align-items: ${(props) => (props.isWeb ? 'center' : 'stretch')};
`;

const ModalView = styled.View<HasWebSpecificLogic>`
  position: relative;
  margin-left: ${(props) => (props.isWeb ? `${MenuWidth(props.layout)}px` : 0)};
  padding: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 40px;
  background-color: #fff;
`;

const modalHeight = IsMidScreen() ? (IsSmallScreen() ? 300 : 350) : 430;

type Props = {
  popup: boolean;
  setPopup: (v: boolean) => void;
  children: ReactNode;
  containerStyle?: ViewStyle;
  onDone?: () => void;
};

export const ModalSearchPopup = ({
  popup,
  setPopup,
  children,
  containerStyle = {},
  onDone,
}: Props) => {
  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={popup}
      hardwareAccelerated={false}
      onDismiss={() => setPopup(false)}>
      <Resizable>
        {(layout) => (
          <ModalBackground isWeb={isFullWeb()} layout={layout}>
            <KeyboardAvoidingView
              behavior={Platform.OS === 'android' ? undefined : 'position'}
              keyboardVerticalOffset={0}
              contentContainerStyle={{ flex: 1, justifyContent: 'flex-end' }}>
              <ModalView
                style={[{ height: modalHeight }, containerStyle]}
                isWeb={isFullWeb()}
                layout={layout}>
                {children}
                <VerticalCenter>
                  <WaffleButton
                    name={'Done'}
                    style={{ width: 269, marginBottom: 11, marginTop: 11 }}
                    onPress={async () => {
                      if (onDone) {
                        onDone();
                      } else {
                        setPopup(false);
                      }
                    }}
                  />
                </VerticalCenter>
              </ModalView>
            </KeyboardAvoidingView>
          </ModalBackground>
        )}
      </Resizable>
    </Modal>
  );
};
