import React, { forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import 'react-datepicker/dist/react-datepicker.css';
import { DateInputProps } from './DateInput';
import { Platform, TextInput, TextInputProps } from 'react-native';
import { WaffleOrange } from '../../Constants/Style';
import { Input } from 'react-native-elements';

const toDateOrDefault = (s: string, emptyDisplayValue: string) =>
  s?.length > 0 && s !== emptyDisplayValue ? moment(s).toDate() : null;

const toDateString = (d?: Date) => (d ? moment(d).format('YYYY-MM-DD') : '');

// todo : fix date handling!
const DateInput = ({
  value,
  emptyDisplayValue,
  placeholder,
  onChangeText,
  containerStyle,
  inputContainerStyle,
  errorFooterText,
  initialDate,
  minimumDate,
  maximumDate,
}: DateInputProps) => {
  const [startDate, setStartDate] = useState<Date>(
    toDateOrDefault(value, emptyDisplayValue)
  );

  const CustomInput = forwardRef<TextInput, TextInputProps>((props, ref) => (
    <Input
      ref={ref}
      {...props}
      placeholder={placeholder}
      placeholderTextColor={'#CDCFD0'}
      onChangeText={onChangeText}
      leftIcon={<Icon name={'calendar-check'} color={WaffleOrange} size={23} />}
      inputStyle={{
        // To turn off the border around the text input on web, we need to do this.
        ...Platform.select({
          web: {
            outline: 'none',
          },
        }),
      }}
      inputContainerStyle={[
        {
          borderRadius: 8,
          borderWidth: 1,
          paddingLeft: 8,
          backgroundColor: '#F2F4F5',
          borderColor: '#F2F4F5',
        },
        inputContainerStyle,
      ]}
      containerStyle={[
        containerStyle,
        {
          marginLeft: 0,
          marginRight: 0,
          paddingLeft: 0,
          paddingRight: 0,
        },
      ]}
      errorMessage={errorFooterText}
      value={
        emptyDisplayValue && !startDate
          ? emptyDisplayValue
          : toDateString(startDate)
      }
      editable={false}
    />
  ));

  useEffect(() => {
    // update state is value parameter changes.
    if (value && !startDate) {
      setStartDate(toDateOrDefault(value, emptyDisplayValue));
    }
  }, [value]);

  return (
    <DatePicker
      dateFormat={'yyyy-MM-dd'}
      customInput={<CustomInput />}
      placeholder={placeholder}
      selected={startDate}
      onChange={(date) => {
        setStartDate(date);
        onChangeText(moment(date).format('YYYY-MM-DD'));
      }}
      portalId={'root-portal'}
      minDate={minimumDate}
      maxDate={maximumDate}
      dateFormatCalendar="MMMM" // Only show month in header
      showYearDropdown
      yearDropdownItemNumber={65}
      scrollableYearDropdown
      autoCompleteType={'bday'}
    />
  );
};

export default DateInput;
