import React, { useState } from 'react';

import { ActionButtonImpl } from './ActionButtonImpl';
import DatePicker from '../DatePicker';

export interface PickDateProps {
  onChange: (string) => void;
  disabled: boolean;
  minimumDate?: Date;
  maximumDate?: Date;
}

export const PickDate = ({
  onChange,
  disabled,
  minimumDate,
  maximumDate,
}: PickDateProps) => {
  const [visible, setVisible] = useState(false);

  const onPick = async (v) => {
    setVisible(false);
    await onChange(v);
  };

  return (
    <>
      <ActionButtonImpl
        name={'Pick date'}
        onPress={async () => setVisible(true)}
        disabled={disabled}
      />
      <DatePicker
        visible={visible}
        onPick={onPick}
        onCancel={async () => setVisible(false)}
        minimumDate={minimumDate}
        maximumDate={maximumDate}
      />
    </>
  );
};
