import React from 'react';
import styled from 'styled-components/native';

import { HorizontalPacked } from '../HorizontalPacked';
import WaffleText from '../WaffleText';
import { PlainView, Image, Spacer } from '../SimpleComponents';
import { AdaptiveBiggerContentWidth } from '../../Helper/DeviceHelper';

const SectionContent = styled.View`
  margin-left: 12px;
  margin-right: 12px;
`;

const Heading = styled(WaffleText)`
  height: 22px;
  color: #070d59;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.64px;
  line-height: 22px;
  text-transform: uppercase;
`;

const Section = (props: any) => (
  <PlainView style={{ width: AdaptiveBiggerContentWidth() }}>
    <HorizontalPacked>
      <Image
        style={props.imageStyle}
        resizeMode={'contain'}
        source={props.imageSource}
      />
      <Heading>{props.title}</Heading>
    </HorizontalPacked>

    <Spacer y={1} />

    <SectionContent>{props.children}</SectionContent>
  </PlainView>
);

export default Section;
