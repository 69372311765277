import { useApolloClient, useQuery } from '@apollo/client';
import React, { useState } from 'react';

import Autocomplete from 'react-native-autocomplete-input';
import { AddressItem, onAddressSearch } from './AddresssSearchPopup';
import { CloseButton } from '../CloseButton';
import { GET_LOCAL_POSITION } from '../../GraphQL/Waffle/Queries';
import * as yup from 'yup';
import { StateCodes } from '../../Constants/States';
import { UserState } from '../../Helper/UserHelpers';
import { IconNode } from 'react-native-elements/dist/icons/Icon';
import { TextIconInput } from '../TextIconInput';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { WaffleOrange } from '../../Constants/Style';
import { PlainView } from '../SimpleComponents';
import { ZipValidator } from './PostalInput';

export const addressValidators = {
  address: yup.string().required(),
  address2: yup.string().nullable(),
  city: yup.string().required(),
  state: yup.string().required(),
  zip: ZipValidator,
};

export const addressSchema = yup.object().shape({
  ...addressValidators,
});

export const validateAddressMessage = (state: UserState) =>
  addressSchema.isValidSync(state) && !!StateCodes[state.state]
    ? null
    : 'Please enter a complete address';

type AutoCompleteAddressProps = {
  value: string;
  defaultValue?: string;
  state?: string;
  onChoose: any;
  hideResults: any;
  onChangeText: any;
  onFocus: any;
  onBlur: any;
  leftIcon?: IconNode;
};

export const AutoCompleteAddress = ({
  value,
  defaultValue,
  state,
  onChoose,
  hideResults,
  onChangeText,
  onFocus,
  onBlur,
}: AutoCompleteAddressProps) => {
  const client = useApolloClient();
  const {
    data: { position },
  } = useQuery(GET_LOCAL_POSITION);
  const [results, setResults] = useState([]);

  return (
    <PlainView style={{ width: '100%' }}>
      {!hideResults && results?.length > 0 && (
        <CloseButton
          style={{ right: 10, top: 8, position: 'absolute', zIndex: 99 }}
          onPress={() => setResults([])}
        />
      )}

      <Autocomplete
        containerStyle={{
          marginBottom: 24, //
        }}
        inputContainerStyle={{
          borderWidth: 0,
          height: 44,
        }}
        style={{
          backgroundColor: 'transparent',
        }}
        data={results}
        defaultValue={defaultValue}
        value={value}
        hideResults={hideResults}
        onChangeText={async (text: string) => {
          onChangeText && onChangeText(text);
          await onAddressSearch(
            client,
            text,
            setResults,
            position.latitude,
            position.longitude,
            state
          );
        }}
        renderTextInput={(props) => (
          <TextIconInput
            placeholder={'Start Typing Your Address'}
            otherProps={{
              autoCompleteType: 'address-line1',
              textContentType: 'streetAddressLine1',
            }}
            leftIcon={
              <Icon name={'calendar-check'} color={WaffleOrange} size={23} />
            }
            containerStyle={{
              width: '100%',
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            {...props}
          />
        )}
        flatListProps={{
          style: {
            position: 'relative',
          },
          keyExtractor: (_, idx) => idx.toString(),
          renderItem: ({ item, index }) => (
            <AddressItem
              key={index}
              address={item.address}
              onChoose={(address) => {
                onChoose(address);
                setResults([]);
              }}
              containerStyle={{
                marginTop: 0,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderRadius: 0,
                borderTopWidth: 1,
                borderBottomWidth: 0,
                borderLeftWidth: 0,
                borderRightWidth: 0,
                marginHorizontal: 0,
                paddingLeft: 14,
              }}
            />
          ),
        }}
      />
    </PlainView>
  );
};
