import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/native';

import { Logger } from '../../Helper/Logger';
import WaffleText from '../WaffleText';
import WaffleTextInput from '../WaffleTextInput';
import { ModalSearchPopup } from '../ModalSearchPopup';
import { GET_STATES } from '../../GraphQL/Waffle/Queries';
import { GetStates } from '../../../../operation-result-types';

const StateView = styled.TouchableOpacity`
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #dedede;
  padding: 5px;
`;

const StateItem = ({ item, onChoose }: any) => {
  const { code, name } = item;

  return (
    <StateView style={{ marginTop: 10 }} onPress={() => onChoose(item)}>
      <WaffleText key={'name'}>{name}</WaffleText>
    </StateView>
  );
};

export const StateSearchPopup = ({ popup, setPopup, onChoose }: any) => {
  const { data } = useQuery<GetStates>(GET_STATES);
  const [results, setResults] = useState([]);
  const allData = data?.listStates ?? [];

  useEffect(() => {
    Logger(`useEffect! states length ${JSON.stringify(allData)}`);
    setResults(allData);
  }, [data]);

  return (
    <ModalSearchPopup popup={popup} setPopup={setPopup}>
      <WaffleTextInput
        placeholder={'Start typing the state'}
        placeholderTextColor={'#555555'}
        onChangeText={async (text) =>
          setResults(
            text.length === 0
              ? allData
              : allData.filter((rec) =>
                  rec?.name.toLowerCase().includes(text.toLowerCase())
                )
          )
        }
        otherProps={{
          blurOnSubmit: false,
          autoFocus: true,
        }}
      />

      {results.length > 0 &&
        results.map((r, i) => (
          <StateItem key={i} onChoose={onChoose} item={r} />
        ))}
    </ModalSearchPopup>
  );
};
