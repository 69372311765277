import React, { useState } from 'react';

import { ActionButtonImpl } from './ActionButtonImpl';
import { StateSearchPopup } from '../ProductChat/StateSearchPopup';

type Props = {
  onChange: (v: boolean) => void;
  disabled: boolean;
};

export const StateChooser = ({ onChange, disabled }: Props) => {
  const [chooser, setChooser] = useState(false);

  return (
    <>
      <ActionButtonImpl
        name={'Pick state'}
        onPress={async () => setChooser(true)}
        disabled={disabled}
      />
      <StateSearchPopup
        popup={chooser}
        setPopup={() => setChooser(false)}
        onChoose={(item: any) => {
          setChooser(false);
          onChange(item.code);
        }}
      />
    </>
  );
};
