import styled from 'styled-components/native';
import React, { useEffect, useRef } from 'react';
import { Animated, Easing } from 'react-native';

const LoadingDotContainer = styled.View`
  flex-direction: row;
  justify-content: center;
`;

const AnimatedDots = () => {
  const animation1 = useRef(new Animated.Value(0)).current;
  const animation2 = useRef(new Animated.Value(0)).current;
  const animation3 = useRef(new Animated.Value(0)).current;
  const animation4 = useRef(new Animated.Value(0)).current;

  const animations: Animated.Value[] = [
    animation1,
    animation2,
    animation3,
    animation4,
  ];

  const backgroundColors = animations.map((a) =>
    a.interpolate({
      inputRange: [0, 1],
      outputRange: ['rgba(196, 196, 196, 1)', 'rgba(249, 141, 70, 1)'],
    })
  );

  function loadThings(node) {
    return Animated.loop(
      Animated.sequence([
        Animated.timing(node, {
          useNativeDriver: true,
          easing: Easing.bezier(0.41, -0.15, 0.56, 1.21),
          toValue: 1,
          // duration,
        }),
        Animated.timing(node, {
          useNativeDriver: true,
          easing: Easing.bezier(0.41, -0.15, 0.56, 1.21),
          toValue: 0,
        }),
      ])
    );
  }

  useEffect(() => {
    const allAnimations = animations.map(loadThings);
    Animated.stagger(100, allAnimations).start();
  }, []);

  return (
    <LoadingDotContainer>
      {backgroundColors.map((bg, i) => (
        <Animated.View
          key={i}
          style={{
            height: 15,
            width: 15,
            marginLeft: 8,
            marginRight: 8,
            borderRadius: 7.5,
            backgroundColor: bg,
          }}
        />
      ))}
    </LoadingDotContainer>
  );
};

export default AnimatedDots;
