import React from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { LocalUser } from '../../../operation-result-types';
import { GET_LOCAL_USER } from '../GraphQL/Waffle/Queries';
import { Spacer } from './SimpleComponents';
import { WaffleButtonAsync } from './WaffleButtonAsync';
import { Black, WaffleDarkGrey } from '../Constants/Style';
import { useAuthContext } from '../Screens/AuthProvider';
import { ClearGuestToken } from '../Helper/UserHelpers';
import { CognitoSignOut } from '../Helper/CognitoAuth';
import { useUserTracking } from '../../lib/user-tracking/UserTrackingProvider';

export const ResetToStart = () => {
  const client = useApolloClient();
  const userTracking = useUserTracking();
  const { setIsSignedIn } = useAuthContext();
  const {
    data: { user },
  } = useQuery<LocalUser>(GET_LOCAL_USER);

  const resetToStart = async () => {
    // DO NOT change the order of this! It will cause it to NOT reset anonymousIds for users. We are assuming this
    // is because of a weird transpiling issue and globals. If you want to go down the rabbit hole run this reset
    // tracking function at the bottom of this function and godspeed.
    await userTracking.reset();
    await CognitoSignOut(); // We try to sign out of Cognito.  (It will catch error if it has a problem.)
    setIsSignedIn(null);
    await ClearGuestToken();
    // await AsyncStorage.clear();
    await client.resetStore();
  };

  if (user?.userType === 'from-agent') {
    return (
      <>
        <Spacer x={2} />
        <WaffleButtonAsync
          name={'Reset to Start'}
          style={{
            backgroundColor: WaffleDarkGrey,
            width: 200,
            marginRight: 16,
          }}
          textStyle={{ color: Black }}
          onPress={resetToStart}
        />
      </>
    );
  }

  return <></>;
};
