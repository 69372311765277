import React from 'react';
import { Linking } from 'react-native';

import { ActionButtonImpl } from './ActionButtonImpl';

type Props = {
  phone: string;
  disabled: boolean;
};

export const ClaimsCallButton = ({ phone, disabled }: Props) => (
  <ActionButtonImpl
    name={`Call ${phone.replace('+1-', '')}`}
    onPress={async () =>
      await Linking.openURL(`tel:${phone.replace(/-/g, '')}`)
    }
    disabled={disabled}
  />
);
