import WaffleText from '../WaffleText';
import React from 'react';

type Props = {
  children: string | React.ReactNode;
};

export default function CoverageCardDescription({ children }: Props) {
  return (
    <WaffleText
      style={{
        fontSize: 13,
        fontWeight: '400',
        textAlign: 'center',
        color: 'rgba(55, 55, 55, 1)',
      }}>
      {children}
    </WaffleText>
  );
}
