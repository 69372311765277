import React from 'react';
import styled from 'styled-components/native';

import { Image } from './SimpleComponents';
import { ViewStyle } from 'react-native';

const TouchableClose = styled.TouchableOpacity`
  height: 25px;
  width: 25px;
  position: absolute;
  top: 0px;
  right: 10px;
  z-index: 99;
`;

type Props = {
  onPress: () => void;
  style?: ViewStyle;
};

export const CloseButton = ({ onPress, style }: Props) => (
  <TouchableClose onPress={onPress} style={style}>
    <Image
      source={require('../../assets/elements/close-x.png')}
      resizeMode={'contain'}
      style={{ width: 31, height: 31 }}
    />
  </TouchableClose>
);
