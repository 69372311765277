import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/native';

import WaffleText from '../WaffleText';
import WaffleTextInput from '../WaffleTextInput';
import { ModalSearchPopup } from '../ModalSearchPopup';
import { ScrollView } from '../SimpleComponents';
import { IsMidScreen } from '../../Helper/DeviceHelper';
import { GET_COUNTRIES } from '../../GraphQL/Waffle/Queries';
import { GetCountries } from '../../../../operation-result-types';

const CountryView = styled.TouchableOpacity`
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #dedede;
  padding: 9px;
`;

const CountryItem = ({ item, onChoose }: any) => {
  const { name } = item;

  return (
    <CountryView style={{ marginTop: 10 }} onPress={() => onChoose(item)}>
      <WaffleText key={'name'}>{name}</WaffleText>
    </CountryView>
  );
};

const reOrderCountries = (countries) =>
  !!countries && [
    { code: 'USA', name: 'United States of America', __typename: 'countries' },
    ...countries.filter((c) => c.code !== 'USA'),
  ];

export const CountrySearchPopup = ({ popup, setPopup, onChoose }: any) => {
  const { data } = useQuery<GetCountries>(GET_COUNTRIES);
  const [results, setResults] = useState([]);
  const allData = reOrderCountries(data?.listCountries) ?? [];

  useEffect(() => setResults(allData), [data]);

  return (
    <ModalSearchPopup popup={popup} setPopup={setPopup}>
      <WaffleTextInput
        placeholder={'Start typing the country'}
        placeholderTextColor={'#555555'}
        onChangeText={async (text) =>
          setResults(
            text.length === 0
              ? allData
              : allData.filter((rec) =>
                  rec?.name.toLowerCase().includes(text.toLowerCase())
                )
          )
        }
        otherProps={{
          blurOnSubmit: false,
          autoFocus: true,
        }}
      />

      <ScrollView
        keyboardShouldPersistTaps={'handled'}
        style={{ height: IsMidScreen() ? 150 : 200 }}>
        {results.length > 0 &&
          results.map((r, i) => (
            <CountryItem key={i} onChoose={onChoose} item={r} />
          ))}
      </ScrollView>
    </ModalSearchPopup>
  );
};
