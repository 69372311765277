import React, { useState } from 'react';
import { ActionButtonImpl } from './ActionButtonImpl';
import { AddressSearchPopup } from '../CreateAccount/AddresssSearchPopup';

type Props = {
  onChange: (addressLine: string, json: string) => void;
  disabled: boolean;
  state?: string | undefined;
};

export const AddressChooser = ({ onChange, disabled, state }: Props) => {
  const [chooser, setChooser] = useState(false);

  return (
    <>
      <ActionButtonImpl
        name={'Pick address'}
        onPress={async () => setChooser(true)}
        disabled={disabled}
      />
      <AddressSearchPopup
        popup={chooser}
        setPopup={() => setChooser(false)}
        state={state}
        onChoose={(address) => {
          const AddressLine = `${address.houseNumber} ${address.street}`;
          const result = {
            AddressLine,
            CityName: address.city,
            State: address.state,
            Country: address.country, // probably a verbose country
            Zip: address.postalCode,
          };
          setChooser(false);
          onChange(AddressLine, JSON.stringify(result));
        }}
      />
    </>
  );
};
