import styled, { css } from 'styled-components/native';
import { Platform } from 'react-native';

type Props = {
  // Gap between rows and columns
  gap?: number;

  // Gap Between rows
  rowGap?: number;

  // Gap Between rows
  columnGap?: number;
};

const ResponsiveRow = styled.View<Props>`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.rowGap &&
    Platform.OS === 'web' &&
    css`
      row-gap: ${props.rowGap};
    `};

  ${(props) =>
    props.columnGap &&
    Platform.OS === 'web' &&
    css`
      column-gap: ${props.columnGap};
    `};

  ${(props) =>
    props.gap &&
    Platform.OS === 'web' &&
    css`
      gap: ${props.gap};
    `};
`;

export default ResponsiveRow;
