import React from 'react';
import { Linking } from 'react-native';

import { ActionButtonImpl } from './ActionButtonImpl';

type Props = {
  email: string;
  disabled: boolean;
};

export const ClaimsEmailButton = ({ email, disabled }: Props) => (
  <ActionButtonImpl
    name={`email ${email}`}
    onPress={async () => await Linking.openURL(`mailto:${email}`)}
    disabled={disabled}
  />
);
