import React, { useState } from 'react';
import { ActionButtonImpl } from './ActionButtonImpl';
import { BreedSearch } from '../ProductChat/BreedSearch';
import { ModalSearchPopup } from '../ModalSearchPopup';
import { PetBreed } from '../../../component-config';

type Props = {
  disabled: boolean;
  onChange: (breedName: string, breedCode: string) => void;
};

export const PetBreedChooser = ({ onChange, disabled }: Props) => {
  const [chooser, setChooser] = useState(false);

  return (
    <>
      <ActionButtonImpl
        name={'Pick the breed'}
        onPress={async () => setChooser(true)}
        disabled={disabled}
      />
      <ModalSearchPopup popup={chooser} setPopup={setChooser}>
        <BreedSearch
          onChoose={async (breed: PetBreed) => {
            setChooser(false);
            onChange(breed.name, breed.code);
            // await onSend([{ text: breed.name, value: breed.code }], sendChat,
            //   userId, onDone);
          }}
        />
      </ModalSearchPopup>
    </>
  );
};
