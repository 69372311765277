import styled from 'styled-components/native';
import WaffleText from '../WaffleText';
import {
  GetProducts_products,
  LocalUser_user,
} from '../../../../operation-result-types';

export type ProductType = GetProducts_products;

export interface ProductChooserProps {
  products: GetProducts_products[];
  onNext: (toggles: Record<string, unknown>) => Promise<void>;
  onCancel: () => Promise<void>;
}

export const WhatIsImportant = styled(WaffleText)`
  height: 34px;
  width: 303px;
  font-size: 30px;
  font-weight: 400;
  line-height: 34px;
  text-align: center;
`;
