import React from 'react';
import { ErrorText } from './ErrorText';

// todo: handle error string, object, or array of objects

interface ErrorDisplayType {
  error: string | Error;
}

export const ErrorDisplay = ({ error }: ErrorDisplayType) => {
  if (error) {
    let msg: string;

    if (error instanceof Error) {
      msg = error.message;
    } else {
      msg = error;
    }

    return <ErrorText>{msg}</ErrorText>;
  }

  return null;
};
