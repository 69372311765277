// Native version of the current plan widget.  For now, separate
// versions between web and mobile rather than trying to deal with
// complicated responsive issues.

import React from 'react';
import styled from 'styled-components/native';
import { WaffleDarkBlue, WindowWidth } from '../../Constants/Style';
import { Image, PlainView, Spacer } from '../SimpleComponents';
import { Packed } from '../SimpleLayouts';
import {
  ProductButtons,
  ProtectSomethingNew,
  Title,
} from './CurrentPlanShared';
import { isFullWeb, IsSmallScreen } from '../../Helper/DeviceHelper';
import { Platform } from 'react-native';

const isSmall = IsSmallScreen();
const width = Platform.select({
  ios: isSmall ? 300 : Math.max(WindowWidth() - 50, 335),
  default: 335,
});

const PlanBox = styled.View`
  width: ${isFullWeb() ? 720 : width}px;
  height: ${isFullWeb() ? 320 : 270}px;
  border-radius: ${isFullWeb() ? 20 : 10}px;
  background: ${WaffleDarkBlue};
`;

const CurrentPlan = () => {
  const style = isFullWeb()
    ? { paddingLeft: 20, paddingTop: 20, paddingBottom: 20 }
    : { paddingLeft: 10, paddingTop: 10, paddingBottom: 10 };
  const image = isFullWeb()
    ? require('../../../assets/images/PlanIllustrationLarge.png')
    : require('../../../assets/images/PlanIllustrationSmall.png');

  return (
    <PlanBox style={style}>
      <Image
        source={image}
        resizeMode={'contain'}
        style={{
          bottom: 0,
          right: 0,
          position: 'absolute',
          width: isFullWeb() ? 260 : 115,
          height: isFullWeb() ? 287 : 180,
        }}
      />
      <PlainView
        style={[
          {
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          },
        ]}>
        <Packed>
          <Title>Current Plan</Title>

          <Spacer y={isFullWeb() ? 2.5 : 1} />

          <ProductButtons maxWidth={isFullWeb() ? 350 : 250} />
        </Packed>

        <PlainView style={{ position: 'absolute', bottom: 0 }}>
          <ProtectSomethingNew />
        </PlainView>
      </PlainView>
    </PlanBox>
  );
};

export { CurrentPlan };

/*
web:

const CurrentPlan = () => (
  <PlanBox style={}>
    <PlainView
      style={[
        {
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        },
      ]}>
      <Packed>
        <Title>Current Plan</Title>

        <Spacer y={2.5} />

        <ProductButtons maxWidth={350} />
      </Packed>

      <ProtectSomethingNew />
    </PlainView>
  </PlanBox>
);

export { CurrentPlan };

 */
