import { useQuery } from '@apollo/client';
import { Policy, PolicyProps } from '../../../component-config';
import { VerticalCenter, VerticalLeft } from '../SimpleLayouts';
import { Spacer } from '../SimpleComponents';
import React from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';
import styled from 'styled-components/native';
import WaffleText from '../WaffleText';
import { WaffleDarkBlue } from '../../Constants/Style';
import {
  coverageValue,
  GetFullPolicy,
  ItemLabel,
  ItemLabelOrangeLink,
  RowImageProps,
  RowImages,
  SummarySectionWidth,
  Title,
} from './SuggestedCoverageShared';
import { useTabNavigation } from '../../TabNavigationProvider';
import { isFullWeb } from '../../Helper/DeviceHelper';
import { ShowWebLink } from '../WebViewPopupLink';
import { LocalUser } from '../../../../operation-result-types';
import { GET_LOCAL_USER } from '../../GraphQL/Waffle/Queries';

const Coverages: Record<string, RowImageProps[]> = {
  Cyber: [
    {
      label: 'Cyber Extortion',
      source: require('../../../assets/images/products/design-coverage/cyber_extorion.webp'),
    },
    {
      label: 'Cyber Financial Fraud',
      source: require('../../../assets/images/products/design-coverage/cyber_financial_fraud.webp'),
    },
    {
      label: 'Deceptive Transfer Fraud',
      source: require('../../../assets/images/products/design-coverage/cyber_transfer_fraud.webp'),
    },
    {
      label: 'Cyber Bullying',
      source: require('../../../assets/images/products/design-coverage/cyber_bullying.webp'),
    },
  ],
  Life: [
    {
      label: 'No Medical Exam',
      source: require('../../../assets/images/products/design-coverage/life_no_exam.webp'),
    },
    {
      label: '10-day Free Look',
      source: require('../../../assets/images/products/design-coverage/life_10day.webp'),
    },
    {
      label: 'Term Life Insurance',
      source: require('../../../assets/images/products/design-coverage/life_term.webp'),
    },
  ],
  Pet: [
    {
      label: 'Accidents',
      source: require('../../../assets/images/products/design-coverage/pet_accidents.webp'),
      inactiveSource: require('../../../assets/images/products/design-coverage/pet_accidents_inactive.webp'),
    },
    {
      label: 'Illnesses',
      source: require('../../../assets/images/products/design-coverage/pet_illness.webp'),
      inactiveSource: require('../../../assets/images/products/design-coverage/pet_illness_inactive.webp'),
    },
    {
      label: 'Behavioral Issues',
      source: require('../../../assets/images/products/design-coverage/pet_behavioral.webp'),
      inactiveSource: require('../../../assets/images/products/design-coverage/pet_behavioral_inactive.webp'),
    },
    {
      label: 'Alternative Therapies',
      source: require('../../../assets/images/products/design-coverage/pet_alternate_therapies.webp'),
      inactiveSource: require('../../../assets/images/products/design-coverage/pet_alternate_therapies_inactive.webp'),
    },
  ],
  Renters: [
    {
      label: 'Stolen or Damaged Belongings',
      source: require('../../../assets/images/products/design-coverage/renters_stolen.webp'),
      inactiveSource: require('../../../assets/images/products/design-coverage/renters_stolen_inactive.webp'),
    },
    {
      label: 'Life Expenses',
      source: require('../../../assets/images/products/design-coverage/renters_life.webp'),
      inactiveSource: require('../../../assets/images/products/design-coverage/renters_life_inactive.webp'),
    },
    {
      label: 'Damages Legally Required to Pay For',
      source: require('../../../assets/images/products/design-coverage/renters_damages.webp'),
      inactiveSource: require('../../../assets/images/products/design-coverage/renters_damages_inactive.webp'),
    },
    {
      label: 'Medical Payments',
      source: require('../../../assets/images/products/design-coverage/renters_medical.webp'),
      inactiveSource: require('../../../assets/images/products/design-coverage/renters_medical_inactive.webp'),
    },
  ],
  Travel: [
    {
      label: 'Cancellation for Covered Reason',
      source: require('../../../assets/images/products/design-coverage/travel_cancellation_covered.webp'),
    },
    {
      label: 'Cancellation for Work Reason',
      source: require('../../../assets/images/products/design-coverage/travel_cancellation_work.webp'),
    },
    {
      label: 'Trip Interruption',
      source: require('../../../assets/images/products/design-coverage/travel_trip_interruption.webp'),
    },
    {
      label: 'Missed Connection',
      source: require('../../../assets/images/products/design-coverage/travel_missed_connections.webp'),
    },
  ],
};

const Exclusions: Record<string, RowImageProps[]> = {
  Cyber: [
    {
      label: 'Prior Knowledge',
      source: require('../../../assets/images/products/design-coverage/cyber_prior_knowledge.webp'),
    },
    {
      label: 'Acts of Certain People',
      source: require('../../../assets/images/products/design-coverage/cyber_acts.webp'),
    },
    {
      label: 'Business Activities',
      source: require('../../../assets/images/products/design-coverage/cyber_business_activities.webp'),
    },
    {
      label: 'Confiscation',
      source: require('../../../assets/images/products/design-coverage/cyber_confiscation.webp'),
    },
  ],
  Pet: [
    {
      label: 'Pre-existing Conditions',
      source: require('../../../assets/images/products/design-coverage/pet_preexisting.webp'),
    },
    {
      label: 'Cosmetic Procedures',
      source: require('../../../assets/images/products/design-coverage/pet_cosmetic.webp'),
    },
    {
      label: 'Breeding Costs',
      source: require('../../../assets/images/products/design-coverage/pet_breeding_costs.webp'),
    },
  ],
  Renters: [
    {
      label: 'Contents Coverage > $40K',
      source: require('../../../assets/images/products/design-coverage/renters_over_40k.webp'),
    },
    {
      label: 'Liability Costs > $300K',
      source: require('../../../assets/images/products/design-coverage/renters_over_300k.webp'),
    },
    {
      label: 'Animals or pets',
      source: require('../../../assets/images/products/design-coverage/renters_pets.webp'),
    },
    {
      label: 'Trees, Shrubs, & Lawns',
      source: require('../../../assets/images/products/design-coverage/renters_plants.webp'),
    },
  ],
  Travel: [
    {
      label: 'Pre-Existing Conditions',
      source: require('../../../assets/images/products/design-coverage/travel_pre_existing.webp'),
    },
    {
      label: 'High-Risk Behaviors',
      source: require('../../../assets/images/products/design-coverage/travel_high_risk.webp'),
    },
    {
      label: 'Acting Under the Influence',
      source: require('../../../assets/images/products/design-coverage/travel_under_influence.webp'),
    },
    {
      label: 'Committing a Criminal Act',
      source: require('../../../assets/images/products/design-coverage/travel_criminal_act.webp'),
    },
  ],
};

const SelectedLabel = styled(WaffleText)`
  width: 80px;
  font-size: 12px;
  text-align: center;
  color: ${WaffleDarkBlue};
`;

const inactiveImage = (policy: Policy, r: RowImageProps) => {
  switch (policy.productId) {
    case 'Pet': {
      const level = coverageValue(policy.coverage, 'Level');

      return level === 'Level1' && r.label !== 'Accidents';
    }
    case 'Renters': {
      const productType = coverageValue(policy.coverage, 'Product Type');

      return (
        productType === 'Liability Only' &&
        r.label !== 'Damages Legally Required to Pay For' &&
        r.label !== 'Medical Payments'
      );
    }
    default:
      return false;
  }
};

export const SelectedCoveragesAndExclusions = ({
  policy,
  coveragesTitle = 'Selected Coverage',
}: PolicyProps & { coveragesTitle?: string }) => {
  const coverages = Coverages[policy.productId];
  const exclusions = Exclusions[policy.productId];
  const navigation = useTabNavigation();
  const {
    data: { user },
  } = useQuery<LocalUser>(GET_LOCAL_USER);
  const fullPolicy = GetFullPolicy(user, policy?.productId);

  return (
    <VerticalLeft style={{ width: '100%', alignItems: 'center' }}>
      <View
        display-name="exclusion-container"
        style={{
          flexDirection: 'row',
          width: '100%',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          ...(isFullWeb() && {
            columnGap: 20,
            rowGap: 20,
          }),
        }}>
        <View style={{ width: SummarySectionWidth }}>
          {coverages?.length > 0 && (
            <View>
              <Title>{coveragesTitle}</Title>

              <Spacer y={4} />

              <RowImages
                style={{ width: '100%' }}
                images={coverages}
                inactiveFilter={(r) => inactiveImage(policy, r)}
              />

              <Spacer y={4} />
            </View>
          )}
        </View>

        {exclusions?.length > 0 && (
          <View style={{ width: SummarySectionWidth }}>
            <View>
              <Title>
                {policy.productId !== 'Pet' && 'Selected '}Exclusions
              </Title>

              <Spacer y={4} />

              <RowImages style={{ width: '100%' }} images={exclusions} />

              <Spacer y={4} />
            </View>
          </View>
        )}
      </View>

      <View display-name="coverage">
        <VerticalCenter style={{ width: '100%' }}>
          {fullPolicy && (
            <TouchableWithoutFeedback
              onPress={() => ShowWebLink(navigation, fullPolicy)}>
              <ItemLabel>
                View{' '}
                <ItemLabelOrangeLink>
                  full policy and exclusions
                </ItemLabelOrangeLink>
              </ItemLabel>
            </TouchableWithoutFeedback>
          )}
        </VerticalCenter>
      </View>
    </VerticalLeft>
  );
};
