import React, { useState } from 'react';
import { Badge } from 'react-native-elements';
import styled from 'styled-components/native';

import Products from '../Constants/Products';
import WaffleText from './WaffleText';
import { Image, PlainView } from './SimpleComponents';

import { ProductUnavailablePopup } from './ProductChooser/ProductUnavailablePopup';
import {
  WaffleDarkBlue,
  WaffleGreen,
  WaffleLightGrey,
  WaffleMediumGrey,
  White,
} from '../Constants/Style';

import { isNativeOrMobileWeb, IsSmallScreen } from '../Helper/DeviceHelper';
import { Fade, Placeholder, PlaceholderLine } from 'rn-placeholder';
import { GetProducts_products } from '../../../operation-result-types';
import { HapticSelectionOptions } from '../../component-config';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';

const ProductImage = styled.Image`
  height: 92px;
  width: 92px;
`;

const ProductText = styled(WaffleText)`
  height: 22px;
  width: 92px;
  font-size: 12px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 3px;
`;

const buttonMargin = IsSmallScreen() ? 2 : 9; // Buttons end up wrapping if too much margin on SE gen 1 screen!

const TouchableOpacity = styled.TouchableOpacity`
  align-items: center;
  margin: 6px ${buttonMargin}px;
`;

export const ProductDummyNative = () => (
  <TouchableOpacity>
    <PlainView
      style={{
        width: 92,
        height: 92,
        borderRadius: 30,
        backgroundColor: WaffleLightGrey,
      }}
    />
    <Placeholder
      Animation={Fade}
      style={{
        width: 92,
        height: 19,
        marginTop: 6,
      }}>
      <PlaceholderLine
        width={92}
        height={12}
        style={{ backgroundColor: White }}
      />
    </Placeholder>
  </TouchableOpacity>
);

const ProductTouchableNative = ({
  product,
  policyCount,
  onPress,
  opacity,
  selected,
}) => (
  <TouchableOpacity
    activeOpacity={1}
    onPress={() => {
      ReactNativeHapticFeedback.trigger('selection', HapticSelectionOptions);
      onPress();
    }}>
    <PlainView style={{ opacity }}>
      <ProductImage
        resizeMode={'contain'}
        source={
          selected
            ? Products[product.id].selectedImg
            : Products[product.id].unselectedImg
        }
      />
      <ProductText>{product.name}</ProductText>
      {policyCount > 0 && product.multiple && (
        <Badge
          status="success"
          value={policyCount}
          containerStyle={{ position: 'absolute', bottom: 25, right: 9 }}
        />
      )}
    </PlainView>
  </TouchableOpacity>
);

const Checked = () => (
  <Image
    source={require('../../assets/images/productWebChecked.png')}
    style={{ width: 20, height: 20, left: 163, top: 150, position: 'absolute' }}
  />
);

const UnChecked = () => (
  <Image
    source={require('../../assets/images/productWebUnchecked.png')}
    style={{ width: 20, height: 20, left: 163, top: 150, position: 'absolute' }}
  />
);

const ProductTouchableWeb = ({ product, onPress, opacity, selected }) => (
  <TouchableOpacity activeOpacity={1} onPress={onPress}>
    <PlainView
      style={{
        opacity,
        width: 200,
        height: 268,
      }}>
      <Image
        resizeMode={'cover'}
        source={Products[product.id].webImg}
        style={{
          width: 125,
          height: 150,
          position: 'absolute',
          zIndex: 20,
          top: 0,
          left: 37,
        }}
      />
      <PlainView
        style={{
          width: 200,
          height: 132,
          top: 18,
          backgroundColor: selected ? WaffleGreen : WaffleMediumGrey,
          position: 'absolute',
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          flexDirection: 'row',
          justifyContent: 'center',
          zIndex: 10,
        }}
      />
      <PlainView
        style={{
          width: 200,
          height: 250,
          top: 18,
          borderRadius: 10,
          position: 'absolute',
          backgroundColor: WaffleDarkBlue,
        }}>
        <WaffleText
          style={{
            fontSize: 18,
            lineHeight: 22,
            color: White,
            left: 14,
            top: 150,
            position: 'absolute',
          }}>
          {product.name}
        </WaffleText>
        <WaffleText
          style={{
            fontSize: 12,
            lineHeight: 15,
            width: 169,
            color: White,
            left: 14,
            top: 182,
            position: 'absolute',
          }}>
          {product.description}
        </WaffleText>

        {selected ? <Checked /> : <UnChecked />}
      </PlainView>
    </PlainView>
  </TouchableOpacity>
);

export const ProductTouchableDummyWeb = () => (
  <TouchableOpacity activeOpacity={1}>
    <PlainView
      style={{
        opacity: 0.5,
        width: 200,
        height: 268,
      }}>
      <PlainView
        style={{
          width: 200,
          height: 132,
          top: 18,
          backgroundColor: WaffleMediumGrey,
          position: 'absolute',
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          flexDirection: 'row',
          justifyContent: 'center',
          zIndex: 10,
        }}
      />
      <PlainView
        style={{
          width: 200,
          height: 250,
          top: 18,
          borderRadius: 10,
          position: 'absolute',
          backgroundColor: WaffleDarkBlue,
        }}>
        <Placeholder
          Animation={Fade}
          style={{
            width: 150,
            height: 100,
            position: 'absolute',
            left: 14,
            top: 150,
          }}>
          <PlaceholderLine
            width={50}
            height={18}
            style={{ backgroundColor: White }}
          />
          <PlaceholderLine
            width={100}
            height={12}
            style={{ backgroundColor: White }}
          />
          <PlaceholderLine
            width={100}
            height={12}
            style={{ backgroundColor: White }}
          />
        </Placeholder>
      </PlainView>
    </PlainView>
  </TouchableOpacity>
);

const ProductTouchable = ({
  policyCount,
  product,
  onPress,
  opacity,
  selected,
}) => {
  if (!isNativeOrMobileWeb()) {
    return (
      <ProductTouchableWeb
        product={product}
        onPress={onPress}
        opacity={opacity}
        selected={selected}
      />
    );
  } else {
    return (
      <ProductTouchableNative
        product={product}
        policyCount={policyCount}
        onPress={onPress}
        opacity={opacity}
        selected={selected}
      />
    );
  }
};

type ProductToggleButtonType = {
  pressed: boolean;
  setPressed: (boolean) => void;
  toggles: Record<string, boolean>;
  product: GetProducts_products;
};

export const ProductToggleButton = ({
  pressed,
  setPressed,
  toggles,
  product,
}: ProductToggleButtonType) => {
  const [popup, setPopup] = useState(false);

  const { eligibility, multiple } = product;
  const { policyCount } = eligibility;
  const hasSingleProductPolicy = policyCount > 0 && !multiple;

  const exclusiveSelectedProduct = Object.entries(toggles).find(
    (toggle) => toggle[1]
  )?.[0];
  const nonExclusiveDisabled =
    exclusiveSelectedProduct && product.id !== exclusiveSelectedProduct;

  const disabled = !eligibility.isEligible || nonExclusiveDisabled;
  const opacity = disabled ? 0.35 : 1.0;

  const onPress = async () => {
    if (disabled || (!pressed && eligibility?.warning)) {
      // We show the user a message saying product is not available yet.
      setPopup(true);
    } else {
      setPressed(!pressed);
    }
  };

  const onSelect = async () => {
    await setPressed(!pressed);
    await setPopup(false);
  };

  return (
    <>
      <ProductTouchable
        product={product}
        onPress={onPress}
        opacity={opacity}
        policyCount={policyCount}
        selected={pressed || hasSingleProductPolicy}
      />

      <ProductUnavailablePopup
        eligibility={eligibility}
        disabled={disabled}
        visible={popup}
        onContinue={onSelect}
        onDismiss={async () => setPopup(false)}
        exclusiveSelectedProduct={exclusiveSelectedProduct}
        product={product}
      />
    </>
  );
};
