import React, { ReactNode, useRef } from 'react';
import { Animated, View, ViewStyle } from 'react-native';

type FadeInViewProps = {
  children: ReactNode;
  style?: ViewStyle | ViewStyle[];
};

type FadeMaybeViewProps = {
  children: ReactNode;
  style?: ViewStyle | ViewStyle[];
  shouldFade: boolean;
};

export const FadeInView = ({ children, style }: FadeInViewProps) => {
  const fadeAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0

  React.useEffect(() => {
    Animated.timing(fadeAnim, {
      delay: 100,
      toValue: 1,
      duration: 1000,
      useNativeDriver: true,
    }).start();
  }, [fadeAnim]);

  return (
    <Animated.View // Special animatable View
      style={{
        ...style,
        opacity: fadeAnim, // Bind opacity to animated value
      }}>
      {children}
    </Animated.View>
  );
};

export const FadeMaybeView = ({
  style,
  shouldFade,
  children,
}: FadeMaybeViewProps) => {
  if (shouldFade) {
    return <FadeInView>{children}</FadeInView>;
  } else {
    return <View>{children}</View>;
  }
};
