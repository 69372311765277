import React, { useState } from 'react';
import CalendarPicker from 'react-native-calendar-picker';
import Popup from './Popup';
import { HorizontalEvenly, VerticalCenter } from './SimpleLayouts';
import { PopupTitle, WaffleTextFamily } from './WaffleText';
import { PlainView, Spacer } from './SimpleComponents';
import { WaffleButtonAsync } from './WaffleButtonAsync';
import { WaffleOrange } from '../Constants/Style';
import { Logger } from '../Helper/Logger';
import { DateRangePickerProps } from './DateRangePicker';
import { isFullWeb } from '../Helper/DeviceHelper';

const DateRangePicker = ({
  picker,
  setPicker,
  title,
  onChange,
  ...otherProps
}: DateRangePickerProps) => {
  const [start, setStart] = useState(otherProps?.selectedStartDate);
  const [end, setEnd] = useState(otherProps?.selectedEndDate);

  const onDateChange = (date, type) => {
    if (type === 'START_DATE') {
      setStart(date?.format('YYYY-MM-DD') ?? null);
    } else {
      setEnd(date?.format('YYYY-MM-DD') ?? null);
    }

    Logger(`date=${date?.format('YYYY-MM-DD') ?? null} type=${type}`);
  };

  // const onDateChange = useCallback((date, type) => {
  //   // const targetType = type === 'START_DATE' ? 'start' : 'end';
  //   // const newRange = { ...dateRange, [targetType]: date?.format('YYYY-MM-DD') ?? null };
  //
  //   // setDateRange(newRange);
  //
  //   // if (type === 'END_DATE') {
  //   // } else {
  //   //   setDateRange({ ...dateRange, start: date?.format('YYYY-MM-DD') });
  //   // }
  // }, [dateRange, start, end]);

  const onConfirm = async () => {
    setPicker(false);
    await onChange(`${start} - ${end}`, JSON.stringify({ start, end }));
    // await onChange(`${(dateRange as any).start} - ${(dateRange as any).end}`, JSON.stringify(dateRange));
  };

  return (
    <Popup
      visible={picker}
      onDismiss={() => setPicker(false)}
      viewStyle={{
        maxWidth: '100%',
        marginLeft: 0,
        marginRight: 0,
        // marginTop: 30,
        // height: 450,
        maxHeight: '100%',
      }}>
      <VerticalCenter>
        <Spacer y={3} />

        <PopupTitle>{title}</PopupTitle>

        <Spacer y={3} />

        <PlainView>
          <CalendarPicker
            allowRangeSelection={true}
            scrollable={true}
            onDateChange={onDateChange}
            restrictMonthNavigation={true}
            selectedDayColor={WaffleOrange}
            selectedDayTextColor={'#ffffff'}
            scaleFactor={isFullWeb() ? 1000 : 375}
            textStyle={{
              fontFamily: WaffleTextFamily,
              color: '#000000',
            }}
            {...otherProps}
          />
        </PlainView>

        <HorizontalEvenly style={{ width: '100%' }}>
          <WaffleButtonAsync
            style={{ width: '45%' }}
            name={'Confirm'}
            disabled={!start || !end}
            onPress={onConfirm}
          />
          <WaffleButtonAsync
            style={{ width: '45%' }}
            name={'Cancel'}
            onPress={async () => setPicker(false)}
          />
        </HorizontalEvenly>
      </VerticalCenter>
    </Popup>
  );
};

export { DateRangePicker };
