import WaffleText from '../../WaffleText';
import { Spacer } from '../../SimpleComponents';
import { isFullWeb } from '../../../Helper/DeviceHelper';
import React from 'react';

export function Disclosures() {
  return (
    <>
      <WaffleText
        style={{ textAlign: 'center', fontSize: 14, fontWeight: '400' }}>
        Cyber Disclosure
      </WaffleText>

      <Spacer y={2.5} />

      <WaffleText
        style={{
          textAlign: 'center',
          fontSize: 11,
          lineHeight: 24,
          fontWeight: '400',
          marginHorizontal: 25,
          maxWidth: isFullWeb() ? 600 : 335,
        }}>
        Cyber insurance is provided by BlinkSM by Chubb®. and is marketed by
        Waffle Labs, Inc. as an insurance producer. The insurance is
        underwritten and provided by ACE American Insurance Company or one of
        its U.S. based Chubb underwriting company affiliates. BlinkSM by Chubb®
        is the marketing name used to refer to subsidiaries of Chubb Limited
        providing the cyber insurance. For a list of these subsidiaries, please
        visit Chubb’s website at www.chubb.com. Coverage may not be available in
        all states. Cyber coverage is subject to the language of the policies as
        actually issued.
      </WaffleText>
    </>
  );
}
