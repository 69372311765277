import { TextIconInput } from '../TextIconInput';
import MaterialIcon from 'react-native-vector-icons/MaterialIcons';
import { WaffleOrange } from '../../Constants/Style';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { PlainView } from '../SimpleComponents';
import { AutoCompleteAddress } from '../CreateAccount/AutoCompleteAddress';
import { Platform } from 'react-native';
import { Address } from '../../Helper/UserHelpers';
import AwesomeIcon from 'react-native-vector-icons/FontAwesome';
import { CityIconInput } from '../CreateAccount/CityInput';
import { StateIconInput } from '../CreateAccount/StateInput';
import { PostalIconInput } from '../CreateAccount/PostalInput';
import React, { useState } from 'react';
import { HorizontalSpread } from '../SimpleLayouts';
import { PolicyRentersAdditionalInterest } from '../../../component-config';
import ResponsiveRow from '../ResponsiveRow';

type Props = {
  width?: number;
  answerInputs: PolicyRentersAdditionalInterest | undefined;
  setAnswerInputs: (value: PolicyRentersAdditionalInterest) => void;
};

function RentalAdditionalInterest({
  width,
  answerInputs,
  setAnswerInputs,
}: Props) {
  const [hideResults, setHideResults] = useState(true);

  return (
    <>
      <HorizontalSpread testID="addtlInterestBiz">
        <TextIconInput
          placeholder={'Business Name'}
          value={answerInputs?.BusinessName}
          onChangeText={(BusinessName: string) =>
            setAnswerInputs({ ...answerInputs, BusinessName })
          }
          leftIcon={
            <MaterialIcon
              name={'person-outline'}
              color={WaffleOrange}
              size={23}
            />
          }
          containerStyle={{ width }}
        />
      </HorizontalSpread>

      <HorizontalSpread testID="addtlInterestEmail">
        <TextIconInput
          placeholder={'Email'}
          otherProps={{
            keyboardType: 'email-address',
            autoCompleteType: 'off',
            textContentType: 'none',
            editable: true,
            autoCapitalize: 'none',
          }}
          value={answerInputs?.Email}
          onChangeText={(Email: string) =>
            setAnswerInputs({ ...answerInputs, Email })
          }
          leftIcon={<Icon name={'email'} color={WaffleOrange} size={23} />}
          containerStyle={{ width }}
        />
      </HorizontalSpread>

      <PlainView testID="addtlInterestAddr" style={{ width }}>
        <AutoCompleteAddress
          hideResults={Platform.OS !== 'ios' && hideResults}
          value={answerInputs?.Address1}
          onChoose={(address: Address) =>
            setAnswerInputs({
              ...answerInputs,
              Address1: `${address.houseNumber} ${address.street}`,
              City: address.city,
              State: address.state,
              Zip: address.postalCode,
            })
          }
          onFocus={() => setHideResults(false)}
          onBlur={
            () => setTimeout(() => setHideResults(true), 500) // delay on hiding results when we lose focus
          }
          onChangeText={(rawAddress: string) =>
            setAnswerInputs({ ...answerInputs, Address1: rawAddress })
          }
        />

        <HorizontalSpread>
          <TextIconInput
            placeholder={'Suite, apartment, etc (opt)'}
            leftIcon={
              <AwesomeIcon name={'building'} color={WaffleOrange} size={23} />
            }
            value={answerInputs?.Address2}
            otherProps={{
              autoCompleteType: 'off',
              textContentType: 'none',
              editable: true,
            }}
            // this isn't a standard address change, as it doesn't taint
            // the address.
            onChangeText={(Address2: string) =>
              setAnswerInputs({ ...answerInputs, Address2 })
            }
          />
        </HorizontalSpread>

        <ResponsiveRow
          columnGap={8}
          rowGap={0}
          style={{ flexWrap: 'wrap', width }}>
          <CityIconInput
            containerStyle={{
              minWidth: 200,
            }}
            value={answerInputs?.City}
            onChangeText={(City: string) =>
              setAnswerInputs({ ...answerInputs, City })
            }
            otherProps={{
              editable: true,
              autoCompleteType: 'off',
              textContentType: 'none',
              keyboardType: 'name-phone-pad',
            }}
          />

          <StateIconInput
            containerStyle={{
              minWidth: 200,
            }}
            value={answerInputs?.State}
            onChangeText={(State: string) =>
              setAnswerInputs({ ...answerInputs, State })
            }
            otherProps={{
              editable: true,
              autoCompleteType: 'off',
              textContentType: 'none',
            }}
          />
        </ResponsiveRow>

        <HorizontalSpread>
          <PostalIconInput
            value={answerInputs?.Zip}
            onChangeText={(Zip: string) =>
              setAnswerInputs({ ...answerInputs, Zip })
            }
            otherProps={{
              editable: true,
              autoCompleteType: 'off',
              textContentType: 'none',
            }}
          />
        </HorizontalSpread>
      </PlainView>
    </>
  );
}

export { RentalAdditionalInterest };
