import React, { useState } from 'react';
import { ActionButtonImpl } from './ActionButtonImpl';
import { ChooserPopup } from '../ChooserPopup';
import { PET_AGES } from './PetAges';

export const PetAgeChooser = ({ onChange, disabled }: any) => {
  const [chooser, setChooser] = useState(false);

  return (
    <>
      <ActionButtonImpl
        name={'Choose Age'}
        onPress={async () => setChooser(true)}
        disabled={disabled}
      />
      <ChooserPopup
        popup={chooser}
        setPopup={() => setChooser(false)}
        items={PET_AGES}
        onChoose={(item: any) => {
          // Logger(`item: ${JSON.stringify(item)}`);
          setChooser(false);
          onChange(item.value);
        }}
      />
    </>
  );
};
