import React from 'react';
import { QuickReplyItem, QuickReviewView } from './QuickReplyItem';
import { PlainView, TouchableOpacity } from '../SimpleComponents';

export const RenderQuickReplies = (props: any) => {
  const {
    containerStyle,
    wrapperStyle,
    currentMessage,
    nextMessage,
    onQuickReply,
    ...quickReplyProps
  } = props;
  const lastMessage = !(nextMessage && nextMessage._id);
  const hidePreviousQuickReplies = false; // nextMessage && nextMessage._id;

  if (hidePreviousQuickReplies) {
    return <PlainView />;
  } else {
    // Alert.alert(JSON.stringify(currentMessage.quickReplies));
    // Alert.alert(JSON.stringify(nextMessage));

    const numQuickReplies = currentMessage.quickReplies.values.length;
    return (
      <QuickReviewView>
        {currentMessage.quickReplies.values.map((qr: any, i: any) => {
          return (
            <TouchableOpacity
              key={'qr' + i}
              onPress={async () => lastMessage && (await onQuickReply([qr]))}>
              <QuickReplyItem qr={qr} cnt={i} maxCnt={numQuickReplies} />
            </TouchableOpacity>
          );
        })}
      </QuickReviewView>
    );
  }
};
