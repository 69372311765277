import React from 'react';
import { VerticalLeft } from '../SimpleLayouts';
import { Image } from '../SimpleComponents';

export default function NavHeaderTitle() {
  return (
    <VerticalLeft style={{ width: '100%' }}>
      <Image
        source={require('../../../assets/images/waffleLogoTitle.png')}
        style={{ width: 118, height: 30 }}
      />
    </VerticalLeft>
  );
}
