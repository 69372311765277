import React from 'react';
import { Animated, StyleProp, TextStyle, ViewStyle } from 'react-native';
import { PolicyFrequency } from '../../Helper/PolicyHelper';
import PriceButtonExperiment from './PriceButton.experiment';
import PriceButton from './PriceButton';

type Props = {
  animatedTextStyle?: StyleProp<TextStyle>;
  isUpdating: boolean;
  onPress: () => Promise<void>;
  policyFrequency: PolicyFrequency;
  positionStyle?: Animated.AnimatedProps<StyleProp<ViewStyle>>;
  prefixPrice?: string;
  basePrice?: string;
  percentOff?: number;
  price: number;
  size: 'small' | 'large';
  testID?: string;
  isExperiment?: boolean;
};

const PriceButtonWrapper = ({ isExperiment = false, ...props }: Props) => {
  if (isExperiment) {
    return <PriceButtonExperiment {...props} />;
  }

  return <PriceButton {...props} />;
};

export default PriceButtonWrapper;
