import React from 'react';
import styled from 'styled-components/native';
import WaffleText from '../WaffleText';
import { WaffleDarkBlue } from '../../Constants/Style';

const CardValue = styled(WaffleText)`
  font-size: 24px;
  font-weight: 500;
  color: ${WaffleDarkBlue};
`;

type Props = {
  value: number;
};

export default function CoverageCardMonetaryValue({ value }: Props) {
  const localizedValue = `$${value.toLocaleString()}`;
  return <CardValue>{localizedValue}</CardValue>;
}
