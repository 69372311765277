import React from 'react';
import WaffleHalfTextInput from '../WaffleHalfTextInput';
import {
  WaffleTextIconInputProps,
  WaffleTextInputProps,
} from '../../../component-config';
import { TextIconInput } from '../TextIconInput';
import EvilIcon from 'react-native-vector-icons/EvilIcons';
import { WaffleOrange } from '../../Constants/Style';

export const StateInput = ({ otherProps, ...rest }: WaffleTextInputProps) => (
  <WaffleHalfTextInput
    placeholder={'State (2 letters)'}
    containerProps={{ style: { marginLeft: 8 } }}
    otherProps={{
      ...{
        autoCompleteType: 'address-level1',
        keyboardType: 'name-phone-pad',
        textContentType: 'addressState',
      },
      ...otherProps,
    }}
    {...rest}
  />
);

// ensure it's a valid state
export const StateIconInput = ({
  otherProps,
  ...rest
}: WaffleTextInputProps & WaffleTextIconInputProps) => (
  <TextIconInput
    placeholder={'State (2 letters)'}
    containerProps={{ style: { marginLeft: 8 } }}
    leftIcon={<EvilIcon name={'location'} color={WaffleOrange} size={23} />}
    otherProps={{
      ...{
        autoCompleteType: 'address-level1',
        keyboardType: 'name-phone-pad',
        textContentType: 'addressState',
      },
      ...otherProps,
    }}
    {...rest}
  />
);
