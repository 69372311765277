import React from 'react';
import { ViewStyle } from 'react-native';
import { WaffleButtonAsync } from '../../WaffleButtonAsync';

type Props = {
  onPromo: () => Promise<any>;
  style?: ViewStyle;
};

export default function LearnMoreButton({
  onPromo,
  style,
}: Props): React.ReactElement {
  return (
    <WaffleButtonAsync
      onPress={onPromo}
      name={'Learn More'}
      style={{ width: 249, ...style }}
      textStyle={{ textTransform: 'none', fontSize: 18 }}
    />
  );
}
