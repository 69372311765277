import React from 'react';
import { ImageSourcePropType } from 'react-native';
import styled from 'styled-components/native';
import WaffleText from '../WaffleText';

type Props = {
  source?: ImageSourcePropType;
};

const StyledImage = styled.Image`
  width: 80px;
  height: 80px;
`;

export default function CoverageTitleIcon({ source }: Props) {
  if (!source) return <WaffleText>img</WaffleText>;

  return <StyledImage source={source} />;
}
