import React, { useState } from 'react';
import styled from 'styled-components/native';

import { ActionButtonImpl } from './ActionButtonImpl';
import { ChooserPopup } from '../ChooserPopup';
import WaffleText, { BoldText } from '../WaffleText';
import { IsSmallScreen } from '../../Helper/DeviceHelper';

const HEALTH_CATEGORIES = [
  {
    text: 'Average',
    description:
      'You are in pretty good health although you may have somewhat high blood pressure or cholesterol and could stand to lose some weight. You may have some history of medical conditions.',
    value: 'fair',
  },
  {
    text: 'Good',
    description:
      'You generally eat right, are not overweight and have good cholesterol and blood pressure levels, either with or without medication. You are definitely in the top half of the population in terms of overall health.',
    value: 'very good',
  },
  {
    text: 'Excellent',
    description:
      'Your cholesterol and blood pressure are normal, you are not overweight, and do not have any medical conditions. A picture of perfect health. Congrats, you are part of the healthiest 20%. ',
    value: 'excellent',
  },
];

const ItemView = styled.TouchableOpacity`
  background-color: #fff;
  border: 1px solid #dedede;
  padding: 7px;
  margin-bottom: 7px;
  border-radius: 7px;
  justify-content: center;
`;

const HealthItem = ({ item, onChoose }: any) => {
  const { text, description } = item;

  return (
    <ItemView onPress={() => onChoose(item)}>
      <BoldText>{text}</BoldText>
      <WaffleText>{description}</WaffleText>
    </ItemView>
  );
};

export const HealthCategoryChooser = ({ onChange, disabled }: any) => {
  const [chooser, setChooser] = useState(false);

  return (
    <>
      <ActionButtonImpl
        name={'Pick health category'}
        onPress={async () => setChooser(true)}
        disabled={disabled}
      />
      <ChooserPopup
        popup={chooser}
        setPopup={setChooser}
        items={HEALTH_CATEGORIES}
        Item={HealthItem}
        style={{ height: IsSmallScreen() ? 415 : 375, maxWidth: 350 }}
        onChoose={(item: any) => {
          setChooser(false);
          onChange(item.text, item.value);
        }}
      />
    </>
  );
};
