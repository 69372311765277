import styled from 'styled-components';

export const ErrorContainer = styled.div`
  background-color: indianred;
  width: auto;
  padding: 12px;
  border-radius: 20px;
  white-space: pre-line;
  overflow-wrap: anywhere;
`;
