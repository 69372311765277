import { useApolloClient } from '@apollo/client';
import Popup from './Popup';
import { LargeText, PopupTitle } from './WaffleText';
import { HorizontalSpread } from './SimpleLayouts';
import { WaffleButtonAsync } from './WaffleButtonAsync';
import React from 'react';
import { USER_STATE_PRODUCT_CHOOSER } from '../Helper/NavigationService';
import { LocalUser_user } from '../../../operation-result-types';
import { Logger } from '../Helper/Logger';
import { UPDATE_USER_STATE } from '../GraphQL/Waffle/Queries';
import {
  HomeScreenName,
  ProductChooserScreenName,
  RootStackParamList,
} from '../../screen-config';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

type Props = {
  user: LocalUser_user;
  popup: boolean;
  setPopup: (v: boolean) => void;
};

export const CancelAddProductPopup = ({ user, popup, setPopup }: Props) => {
  const client = useApolloClient();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();

  const doCancelAddProduct = async () => {
    await setPopup(false);
    const isComplete = user?.userState === 'COMPLETE';
    if (!isComplete) {
      Logger(
        `user state not complete.  reverting user state to USER_STATE_PRODUCT_CHOOSER`
      );
      await client.mutate({
        mutation: UPDATE_USER_STATE,
        variables: {
          state: USER_STATE_PRODUCT_CHOOSER,
        },
      });

      navigation.navigate(ProductChooserScreenName);
    } else {
      navigation.navigate(HomeScreenName);
    }
  };

  return (
    <Popup visible={popup} onDismiss={() => setPopup(false)}>
      <PopupTitle>Cancel add products</PopupTitle>

      <LargeText style={{ marginVertical: 20, textAlign: 'center' }}>
        Are you sure you want to cancel adding products?
      </LargeText>

      <HorizontalSpread>
        <WaffleButtonAsync
          name={'Yes'}
          style={{ width: '48%' }}
          onPress={doCancelAddProduct}
        />
        <WaffleButtonAsync
          name={'No'}
          style={{ width: '48%' }}
          onPress={async () => setPopup(false)}
        />
      </HorizontalSpread>
    </Popup>
  );
};
