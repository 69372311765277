import React, { useState } from 'react';
import { ActionButtonImpl } from './ActionButtonImpl';
import { HeightPickerPopup } from './HeightPickerPopup';

export const HeightPicker = ({ onChange, disabled }: any) => {
  const [chooser, setChooser] = useState(false);

  return (
    <>
      <ActionButtonImpl
        name={'Specify your height'}
        onPress={async () => setChooser(true)}
        disabled={disabled}
      />
      <HeightPickerPopup
        popup={chooser}
        setPopup={() => setChooser(false)}
        onChoose={(text: any, value: any) => {
          setChooser(false);
          onChange(text, value);
        }}
      />
    </>
  );
};
