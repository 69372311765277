import React, { useState } from 'react';
import { ActionButtonImpl } from './ActionButtonImpl';
import { CountrySearchPopup } from '../ProductChat/CountrySearchPopup';

export const CountryChooser = ({ onChange, disabled }: any) => {
  const [chooser, setChooser] = useState(false);

  return (
    <>
      <ActionButtonImpl
        name={'Pick country'}
        onPress={async () => setChooser(true)}
        disabled={disabled}
      />
      <CountrySearchPopup
        popup={chooser}
        setPopup={() => setChooser(false)}
        onChoose={(item: any) => {
          setChooser(false);
          onChange(item.code);
        }}
      />
    </>
  );
};
