import styled from 'styled-components/native';
import WaffleText from '../WaffleText';

export const UserWarning = styled(WaffleText)`
  width: 296px;
  font-size: 16px;
  line-height: 19px;
  margin-top: 15px;
  padding-right: 10px;
`;
