import { useApolloClient } from '@apollo/client';
import React, { useContext, useEffect } from 'react';
import { SuggestedCoverageContext } from '../../Screens/SuggestedCoverageScreen';
import { PlainView, TouchableOpacity } from '../SimpleComponents';
import styled from 'styled-components/native';
import {
  WaffleLightGrey,
  WaffleMediumGrey,
  WaffleOrange,
} from '../../Constants/Style';
import WaffleText from '../WaffleText';
import { VerticalCenter } from '../SimpleLayouts';
import { Markup } from '../Markup';
import {
  addScrollTabToTopHook,
  EditorAddOnProps,
  EditorCoverageProps,
  resetDraftPolicy,
} from './SuggestedCoverageShared';
import { useScrollToTop } from '@react-navigation/native';
import { ScrollView } from 'react-native';
import { AddOnEditor, AddOnEditorWeb } from './AddOnEditor';
import { PolicyProps } from '../../../component-config';
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs';
import { TabNavigationProvider } from '../../TabNavigationProvider';
import { TripCancelForAnyReasonEditor } from './TripCancelForAnyReasonEditor';
import { isFullWeb } from '../../Helper/DeviceHelper';

type AddOnComponent = (props: EditorAddOnProps) => JSX.Element;

const CustomAddOnEditors: Record<string, Record<string, AddOnComponent>> = {
  Travel: {
    'Cancel for Any Reason': isFullWeb() ? AddOnEditorWeb : AddOnEditor,
    'Platinum Upgrade': isFullWeb() ? AddOnEditorWeb : AddOnEditor,
    'Rental Car Damage Upgrade': isFullWeb() ? AddOnEditorWeb : AddOnEditor,
    'Sports & Hazardous Sports Upgrade': isFullWeb()
      ? AddOnEditorWeb
      : AddOnEditor,
    'Baggage, Sports & Business Equipment Upgrades': isFullWeb()
      ? AddOnEditorWeb
      : AddOnEditor,
    'AD&D Flight Accident': isFullWeb() ? AddOnEditorWeb : AddOnEditor,
  },
};

const ResetView = styled(VerticalCenter)`
  height: 35px;
  width: 100%;
  background-color: ${WaffleLightGrey};
`;

const ResetText = styled(WaffleText)`
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  color: ${WaffleOrange};
`;

const ResetToDefault = ({ policy }: PolicyProps) => {
  const client = useApolloClient();
  const { setState } = useContext(SuggestedCoverageContext);

  return (
    <ResetView>
      <TouchableOpacity
        onPress={async () =>
          await resetDraftPolicy(client, policy.id, setState)
        }>
        <ResetText>Reset to Default</ResetText>
      </TouchableOpacity>
    </ResetView>
  );
};

const CoverageCustomize = ({ coverage }: EditorCoverageProps) => {
  return (
    <>
      <WaffleText>{coverage.coverage.name}</WaffleText>
      <Markup>{coverage.coverage.explanation.text}</Markup>
    </>
  );
};

const AddOnCustomize = ({ productId, policyId, addOn }: EditorAddOnProps) => {
  const Editor = CustomAddOnEditors[productId]?.[addOn.name];

  if (Editor) {
    return (
      <PlainView
        style={{
          paddingHorizontal: isFullWeb() ? 0 : 20,
          borderBottomWidth: 1,
          borderColor: WaffleMediumGrey,
        }}>
        <Editor productId={productId} policyId={policyId} addOn={addOn} />
      </PlainView>
    );
  }

  return (
    <>
      <WaffleText>{addOn.name}</WaffleText>
    </>
  );
};

const TravelEditors = ({ policy }: PolicyProps) => {
  return (
    <>
      <TripCancelForAnyReasonEditor policy={policy} />

      {policy?.addOns?.map((addOn) => (
        <AddOnCustomize
          key={addOn.id}
          productId={policy.productId}
          policyId={policy.id}
          addOn={addOn}
        />
      ))}
    </>
  );
};

const Editors = ({ policy }: PolicyProps) => {
  if (policy.productId === 'Travel') {
    return <TravelEditors policy={policy} />;
  }

  return (
    <>
      {policy.coverage.map((c) => (
        <CoverageCustomize key={c.id} coverage={c} />
      ))}

      {policy.addOns.map((addOn) => (
        <AddOnCustomize
          key={addOn.id}
          productId={policy.productId}
          policyId={policy.id}
          addOn={addOn}
        />
      ))}
    </>
  );
};

const SuggestedCoverageCustomizeTab = ({
  navigation,
}: MaterialTopTabBarProps) => {
  const ref = React.useRef<ScrollView>(null);
  const { state } = useContext(SuggestedCoverageContext);
  const policy = state.currentPolicy;

  useScrollToTop(ref);

  useEffect(
    () => ref?.current?.scrollTo({ x: 0, y: 0, animated: false }),
    [state.tab]
  );
  useEffect(() => {
    if (ref?.current) {
      addScrollTabToTopHook(navigation, ref);
    }
  }, []);

  if (!policy) {
    return null;
  }

  return (
    <TabNavigationProvider navigation={navigation}>
      <ScrollView
        ref={ref}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}>
        <ResetToDefault policy={policy} />

        <Editors policy={policy} />
      </ScrollView>
    </TabNavigationProvider>
  );
};

export default SuggestedCoverageCustomizeTab;
