import React, { useEffect, useState } from 'react';
import styled from 'styled-components/native';
import { PlainView, TextInput } from '../SimpleComponents';
import { HorizontalSpread } from '../SimpleLayouts';
import { WaffleButtonAsync } from '../WaffleButtonAsync';
import { HorizontalEvenly } from '../SimpleLayouts';
import WaffleText from '../WaffleText';
import { Logger } from '../../Helper/Logger';
import { AnswerTableButton } from './AnswerTableButton';
import Popup from '../Popup';
import { PopupTitle } from '../WaffleText';
import { Tomorrow } from '../../Helper/DateHelper';
import { TextInfo } from './CoverageInfo';
import { DateRangePicker } from '../DateRangePicker.native';
import { PolicyAnswers, PolicyTripAnswers } from '../../../component-config';
import { ScrollView } from 'react-native';
import add from 'date-fns/add';

const WIDTH = 200;

const ItemLabel = styled(WaffleText)`
  font-size: 16px;
  font-weight: bold;
`;

const ItemRow = styled(HorizontalSpread)`
  margin-bottom: 11px;
`;

const DatePicker = ({
  selectedStartDate,
  selectedEndDate,
  minDate,
  dates,
  setDates,
}) => {
  const [popup, setPopup] = useState(false);
  // const [TripDate, setTripDate] = useState();
  // const [ExpireDate, setExpireDate] = useState();

  // const onDateChange = useCallback((date, type) => {
  //   if (type === 'END_DATE') {
  //     setExpireDate(date?.format('YYYY-MM-DD'));
  //   } else {
  //     setTripDate(date?.format('YYYY-MM-DD'));
  //   }
  // }, [TripDate, ExpireDate]);
  //
  // const onConfirm = async () => {
  //   setPopup(false);
  //   setDates({ TripDate, ExpireDate });
  // };

  const onChange = async (description, range) => {
    Logger(`description=${description} range=${range}`);

    const { start: TripDate, end: ExpireDate } = JSON.parse(range);

    setDates({ TripDate, ExpireDate });
  };

  // Logger(`date range: ${JSON.stringify({ TripDate, ExpireDate })}`);

  return (
    <>
      <AnswerTableButton
        name={`${dates.TripDate} - ${dates.ExpireDate}`}
        width={WIDTH}
        onPress={() => setPopup(true)}
      />
      <DateRangePicker
        title={'Travel Dates'}
        picker={popup}
        setPicker={setPopup}
        minDate={minDate}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        onChange={onChange}
        minimumDate={minDate}
      />
    </>
  );
};
/*
    <Popup
      visible={popup}
      onDismiss={() => setPopup(false)}
      viewStyle={{
        width: '100%',
        marginLeft: 0,
        marginRight: 0,
      }}>
      <PopupTitle
        style={{
          marginTop: 25,
          marginBottom: 25,
        }}>
        Travel Dates
      </PopupTitle>
      <PlainView>
      </PlainView>

      <HorizontalEvenly style={{ width: '100%' }}>
        <WaffleButtonAsync style={{ width: '45%' }} name={'Confirm'}
                           disabled={!TripDate || !ExpireDate}
                           onPress={onConfirm}/>
        <WaffleButtonAsync style={{ width: '45%' }} name={'Cancel'} onPress={async () => setPopup(false)}/>
      </HorizontalEvenly>
    </Popup>

 */
const safeParseInt = (text) => {
  return !text ? 0 : parseInt(text, 10);
};

const safeIntToString = (n) => {
  return !n ? '' : n.toString();
};

type TripTableProps = {
  popup: boolean;
  initialState: PolicyTripAnswers;
  onDismiss: () => void;
  onSave: (answers: PolicyAnswers) => Promise<void>;
};

export const TripAnswersEditorPopup = ({
  popup,
  onDismiss,
  onSave,
  initialState,
}: TripTableProps) => {
  // Logger(`state: ${JSON.stringify(state)} initialState=${JSON.stringify(initialState)}`);
  const [state, setState] = useState<PolicyTripAnswers>();
  const cancelForAnyReasonEligible = state?.CancelForAnyReasonEligible ?? false;
  const minDate = add(Tomorrow(), { days: 21 });
  const isValid = state?.TripPrice > 0;

  useEffect(() => setState(initialState), [initialState]);

  const modifyData = (field: keyof PolicyTripAnswers, newValue: any) => {
    return { ...state, [field]: newValue };
  };

  if (!state) {
    return null;
  }

  return (
    <Popup
      visible={popup}
      onDismiss={onDismiss}
      viewStyle={{ minHeight: 300, maxHeight: '70%', minWidth: 335 }}>
      <PopupTitle style={{ marginBottom: 9 }}>Trip</PopupTitle>

      <ScrollView>
        <PlainView style={{ marginBottom: 20 }}>
          <ItemRow>
            <ItemLabel>Dates</ItemLabel>
            <DatePicker
              selectedStartDate={state?.TripDate}
              selectedEndDate={state?.ExpireDate}
              minDate={minDate}
              dates={state}
              setDates={(dates) => setState({ ...state, ...dates })}
            />
          </ItemRow>

          <ItemRow>
            <ItemLabel>
              Cost
              {cancelForAnyReasonEligible && (
                <TextInfo
                  title={'Cost'}
                  text={
                    'CancelFlex is only available for trips with a value less than $15,000.'
                  }
                />
              )}
            </ItemLabel>
            <TextInput
              value={safeIntToString(state?.TripPrice)}
              onChangeText={(text: any) =>
                setState(modifyData('TripPrice', safeParseInt(text)))
              }
              style={{ width: WIDTH, color: '#000000', borderRadius: 5 }}
            />
          </ItemRow>
        </PlainView>
      </ScrollView>

      <HorizontalEvenly>
        <WaffleButtonAsync
          name={'Update'}
          style={{ width: 133, borderRadius: 5, marginRight: 5 }}
          disabled={!isValid}
          onPress={async () => {
            await onSave(state);
            onDismiss();
          }}
        />

        <WaffleButtonAsync
          name={'Cancel'}
          style={{
            width: 133,
            borderRadius: 5,
            backgroundColor: '#ffffff',
            marginLeft: 5,
            borderWidth: 1,
          }}
          textStyle={{ color: '#000000' }}
          onPress={async () => onDismiss()}
        />
      </HorizontalEvenly>
    </Popup>
  );
};
