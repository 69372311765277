import React from 'react';
import styled from 'styled-components/native';

import { Horizontal } from '../Horizontal';
import WaffleText, { WaffleTextFamily } from '../WaffleText';
import {
  WaffleDarkGrey,
  WaffleFadedBlack,
  WaffleLightGrey,
} from '../../Constants/Style';

const InviteEntryTextView = styled.View`
  height: 75px;
  width: 329px;
  border-radius: 28px;
  border-width: 1px;
  border-color: ${WaffleDarkGrey};
`;

/*
  background-color: #fff;
  shadow-color: rgba(83, 69, 69, 0.17);
  shadow-offset: 0px 5px;

placeholder properties:
  height: 20px;
  width: 158px;
  color: #E0E0E0;
  font-family: WaffleTextFamily;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.17px;
  line-height: 20px;
 */

const TextInput = styled.TextInput`
  height: 26px;
  width: 210px;
  color: #1e2368;
  font-family: ${WaffleTextFamily};
  font-size: 18px;
  letter-spacing: 0.17px;
  /* */
  padding-left: 5px;
`;

const InviteText = styled(WaffleText)`
  height: 26px;
  color: #1e2368;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.17px;
  line-height: 26px;
`;

const Image = styled.Image`
  height: 17px;
  width: 9px;
  margin-left: 10px;
`;

const TouchableOpacity = styled.TouchableOpacity``;

const View = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export default ({
  containerProps,
  onChangeText,
  value,
  disabled,
  onPress,
}: any) => (
  <InviteEntryTextView {...containerProps}>
    <Horizontal style={{ marginHorizontal: 21 }}>
      <TextInput
        placeholder={'Enter Email Address'}
        placeholderTextColor={'#E0E0E0'}
        keyboardType={'email-address'}
        autoCapitalize={'none'}
        onChangeText={onChangeText}
        value={value}
      />
      <TouchableOpacity
        activeOpacity={0.5}
        disabled={disabled}
        onPress={onPress}
        style={{ opacity: disabled ? 0.5 : 1.0 }}>
        <View>
          <InviteText>Invite</InviteText>
          <Image source={require('../../../assets/elements/ArrowInvite.png')} />
        </View>
      </TouchableOpacity>
    </Horizontal>
  </InviteEntryTextView>
);
